@import '../../Styles/index';

.root {
	display: flex;
	flex-direction: column;
	background: #d9d9d9c9;
	// height: calc(100vh - 50px);
	padding: 0.5rem;
	position: relative;


	.stores {
		width: 100%;
		max-width: 100%;
		padding: 0 0.5rem;
		position: relative;

		padding-top: 0.5rem;
		@include flex(row, flex-start);
		border-radius: 25px;
		background-color: #FFF5DA;
		// background-color: rgba(221, 255, 255, 0.5);
		gap: 1rem;
		overflow-x: scroll;
		letter-spacing: 1px;

		&::-webkit-scrollbar {
			// width: 0.1rem;
			height: .5rem;

			&:hover {
				height: 1rem;
			}
		}


		&::-webkit-scrollbar-track {
			// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background: rgba(0, 0, 0, 0.527);
			border-radius: 25px;
			// height: 1rem;
		}

		.store {
			transition: all;
			transition-duration: 0.2s;
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.15);
			padding: 0.25rem 1rem;
			min-width: 200px;
			border-radius: 25px;

			&:first-child {
				min-width: auto;
			}

			&:hover {
				transform: scale(1.02);
			}

			&.active {
				font-weight: 500;
				// color: white;
				@include boxShadow;
				background-color: $uy-bg;
				// background-color: rgb(1, 111, 145);
				letter-spacing: 1.2px;
			}
		}
	}

	.tabs {
		@include flex(row, space-between, flex-start);
		width: 100%;

		.date-tabs {
			@include flex(row, flex-start, flex-start);
			gap: 1rem;

			.link-button {
				border-radius: 8px;
				// width: 50%;
				// flex: 1;
				// word-break: keep-all;
				// max-width: 13rem;
				padding: 12px 16px;
				font-size: 0.9rem;
				transition-duration: 100ms;
				font-size: 1rem;
				font-weight: 500;
				@include flex();
				gap: 0.5rem;
				cursor: pointer;

				&:nth-child(1) {
					background: #ced4da;
					color: #000;
				}

				&:nth-child(2) {
					color: #000;
					background: #cbc0d3;
				}

				&:nth-child(3) {
					color: #000;
					background: #f8f4ea;
				}

				&:nth-child(4) {
					color: #000;
					background: #c3e7ff;
				}

				&.selected {
					border-radius: 8px 8px 0 0;
					border-bottom: 15px solid transparent;
					// color: #fff;
				}
			}
		}

		.dateRangeDiv {
			align-items: center;
			top: 0.3rem;
			display: none;
			justify-content: flex-end;

			&.visibleAnimation {
				display: flex;
				transform-origin: right;
				animation: zoom-in-zoom-out 0.3s ease;
				animation-delay: 1s;
				animation-iteration-count: 2;
			}

			.dateButton {
				font-size: 2.5rem;

				&.disableClick {
					transform: scale(0.7, 0.7);
					font-size: 2.5rem;
					color: #a8a8a896;
					opacity: 50;
				}

				&.allowClick {
					transform: scale(1, 1);
					transition: all;
					font-size: 2.5rem;
					color: #52b9fd;

					animation: scale-in-out 0.3s ease;
					animation-delay: 0.3s;
					animation-iteration-count: 2;
				}
			}
		}
	}

	.data-box-container {
		// height: 100%;
		border: 2px solid #e2e2e2;
		background-color: #FFFFFF;
		padding: 1rem .5rem .5rem;
		@include flex(column, flex-start, flex-start);
		gap: .75rem;
		width: 100%;
		transition-duration: 500ms;
		position: relative;
		.disabled-water-mark {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.3);
			bottom: 0px;
			left:0px;
			display: flex;
			align-items: center;
			justify-content: center;
			.rotate{
				letter-spacing: .2rem;
				font-weight: 700;
				color: rgba(33, 33, 33, 0.297);
				background-color: rgba(171, 171, 171, 0.164);
				padding: .5rem 1rem;
				font-size: 2.5rem;
				border-radius: .5rem ;
				transform: rotate(-45deg);
			}
		}

		.default-selected{
			width: 250px;
			background: rgba(0,0,0, 0.5);
			// background: rgba($uy-bg, 0.5);
			font-size: .8rem;
			padding: 0 .5rem;
			position: absolute;
			top: -2px;
			right: 0.5rem;
			// transform: translate(-50%);
			border-radius: 8px;
			@include flex(row, space-between);
			gap: 1rem;
			color: #fff;
			p{
				margin: 0
			}
			.divider{
				width: 2px;
				height: 1rem;
				background: #fff;
			}
		}
		.coming-soon{
			@include flex(column);
			width: 100%;
			height: 100%;
			p{
				font-size: 3rem;
			}
		}
	}
}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.1, 1.1);
	}

	100% {
		transform: scale(1, 1);
	}
}

@keyframes scale-in-out {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.2, 1.2);
	}

	100% {
		transform: scale(1, 1);
	}
}