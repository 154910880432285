@import '../../Styles/index';
.root {

    .version{
        width: 100%;
        @include flex;
        gap: 1rem; 
        span{
            border-radius: .5rem;
            font-weight: 600;
            // background-color: rgba(223, 223, 223, 0.437);
            background: $uy-ex-light-bg;
            padding: .1rem 1rem;
        }
    }

    .header {
        width: 100%;
        // height: 8rem;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;

        .heading {
            width: 40%;
            height: 10rem;
            display: flex;
            font-size: xx-large;
            font-weight: 600;
            align-items: center;
            justify-content: center;
        }

        .filter {
            width: 60%;
            height: 10rem;

        }
    }
    .action-btn {
        font-size: 1rem;
        cursor: pointer;
        transition: color 0.375s;

        &:hover {
            &.view {
                color: $primary;
            }
        }
    }
    .btns{
        min-width: 150px;
        @include flex(column);
        gap: .5rem;
    }

}