@import '../../../Styles/';

.root {
	width: 100%;
	@include flex(row, center, flex-start);
	overflow-x: hidden;
	height: 100%;

	.header {
		margin-bottom: 2rem;
	}

	.left {
		width: 40%;
		transition-duration: 1s;
		// background-color: red;
		border-radius: 3rem;
		padding: 1rem 0;
		@include flex(column);
		form {
			@include flex(column);
			width: 80%;
			gap: 2rem;

			.select,
			.submit-btn {
				width: 100%;
			}
            
			.submit-btn {
				padding: 1rem 0.75rem;
				// &:hover ~ .icon{
				//     animation: 1s ease-in 1s forwards move ;
				// }
			}

			.ai-loader {
				width: 75px;
				height: 75px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.product-container{
				width: 100%;

				.selected-product{
					margin-top: 0.5rem;
					padding: 6px 12px;
					font-size: 0.9rem;
					border-radius: 25px;
					background: #180C7E;
					color: white;
					font-weight: 500;
					@include flex(row, space-between);
					
					.icon{
						cursor: pointer;
					}
				}
			}

			.all-store-selection{
				width: 100%;
				margin: 0;
			}

			.multi-store-select{
				width: 100%;
			}
		}
	}
	.right {
		width: 60%;
		height: 80%;
		overflow: hidden;
		transition-duration: 1s;
		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
			border-radius: 3rem;
		}

		.table-container {
			width: 99%;
			height: 100%;
			background: #f0ebce;
			border-radius: 12px;
			padding: 1rem;
            margin-top: 0.5rem;
			.table-header {
				font-size: 1.5rem;
                text-align: center;
                margin-bottom: 1rem;
            }

			table {
				width: 100%;
				height: 100%;

				thead {
					background: #3b366c;
					color: white;
					border-radius: 12px 12px 0 0;
					width: 100%;
				}

				tbody {
					tr {
						td {
							text-align: center;
						}
					}

					.color {
						background-color: #9588e248;
					}
				}
			}
		}
	}
}
