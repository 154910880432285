@import '../../../../Styles';

.root {
	@include flex(row, space-between);
	border-bottom: 1px solid $uy-bg;
	// height: 40px;

	.product-name {
		width: 40%;
		text-align: left;
	}
	.variants-wrapper {
		width: 60%;
		@include flex(row, flex-end);
		flex-wrap: wrap;
		// flex: 1 1 50%;
		.variant-tile {
			@include flex;
			width: 50px;
			height: 25px;
			background: rgba($dark-grey, 0.4);
			border-radius: 4px;
			padding: 0.25rem;
			font-size: 0.75em;
			margin: 0.5rem;
			cursor: pointer;
			&:hover {
				background: rgba($uy-bg, 0.5);
			}

			&.active {
				background: $uy-bg;
			}
		}
	}
}
