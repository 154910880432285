@import '../../../Styles/';

.root{
    @include flex(column, center, flex-start);
    gap: 1rem;
    width: 80%;
    margin: 2rem;
    // background: red;
    .multi{
        width: 100%;
        @include flex(row, space-between);
        gap: 7.5rem;
    }

    .rejected{
            width: 100%;
            @include flex(row, flex-start);
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            background: rgba(238, 190, 33, 0.25);
            border-radius: 4px;
            font-weight: 200;
            p{
                margin: 0;
            }
            .reason{
                font-weight: 500;
                color: red;
            }
        }

    .label-container{
        @include flex(row, flex-start);
        .mandatory{
            color: rgb(200, 0, 0);
        }
        label{
            width: 200px;
            font-weight: 500;
            color: rgba(95, 95, 95, 0.75);
        }
        .vendor-selector{
            border: 1px solid #a2a2a2;
            border-radius: 4px;
            input{
                padding: 0.5rem;
                border-radius: 4px;
            }
        }

        .deliver-to{
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
        }
    }
    .vendor-details{
        @include flex(column, flex-start, flex-start);
        // margin: 0.5rem 0;
        padding: .5rem;
        border-radius: 4px;
        gap: 0.5rem;
        background: rgb(240,240, 240);
        font-size: 0.9rem;
        .address-container{
            @include flex(row, flex-start, flex-start);
            .address{
                width: 250px;
                .header{
                    font-weight: 500;
                }
            }
        }
    }
}

.editable-input {
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
}

.custom-input {
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    outline: none;
    padding: 0.25rem 0.5rem;
    border: 2px solid $uy-bg;
    min-width: 100px;
    border-left: none;
    box-sizing: content-box;
}


.checkbox {
	background-color: #fff;
	border: solid 1px #d7d7d7;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	height: 20px;
	margin-right: 5px;
	text-align: center;
	width: 20px;
	vertical-align: middle;

	svg {
		stroke: #fff;
		margin-top: 1.5px;
	}
}

.includeIcon {
	color: #4aca65;
	width:40px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	justify-content: right;
	display: flex;	
	align-items: center;
	margin-right: 2px;
}
.excludeIcon {
	color: #c74545;
	width:40px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	justify-content: left;
	display: flex;	
	align-items: center;
	margin-right: 2px;
}

.negative {
	background-color: #dc4e4e;
	border-color: #c74545;
}

.positive {
	background-color: #4aca65;
	border-color: #43b45b;
}

.item-center {
    display: flex;
    width: 100%;
    align-items: center;
}

.yellow {
    display: flex;
    background-color: $uy-bg;
    height: 100%;
    // width: 2%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .25rem .5rem; 
    border: 2px solid $uy-bg ;
}