@import '../../Styles/';

.root{
    @include flex(column, space-between);
    gap: 1rem;
    height: 100%;
    .body_container{
        height: 85%;
        width: 100%;
        @include flex(column, flex-start);
        gap: 2rem;
        margin-top: .25rem;
        .total_steps{
            width: 80%;
            @include flex(column, flex-start);
            position: relative;
            min-height: 30px;
            .line{
                width: 100%;
                height: 3px;
                background: black;
            }
            .steps{
                width: 100%;
                @include flex(row, space-between);
                position: absolute;
                top: -3px;
                left: 0;
                right: 0;
                .step{
                    @include flex(column);
                    &_dot{
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: black;
                    }
                }
            }
        }
        .component_container{
            width: 100%;
            height: 100%;
        }
    }
    .btn_container{
        width: 100%;
        @include flex;
        gap: 1rem;
        button{
            flex: 1;
            padding: .5rem 1rem;
            border-radius: 8px;
            &.next{
                background: $uy-bg;
            }
            &.back{
                background: #f1f1f1;
                border: 1px solid #cccccc;
            }
        }
    }
}