.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.root .header {
  width: 100%;
}
.root .body {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
@media (orientation: landscape) {
  .root .body {
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
}
.root .body .videoFeed {
  flex-basis: 65%;
  height: 100%;
  min-height: calc(100vh - 60px - 80px - 60px - 5rem);
  position: relative;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 5px;
  padding: 1rem;
}
@media (orientation: landscape) {
  .root .body .videoFeed {
    height: auto;
  }
}
.root .body .reference-measurement {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  flex-basis: 35%;
  padding: 1rem;
  height: 45rem;
}
.root .body .reference-measurement .referenceBox {
  height: 100%;
  width: 100%;
}/*# sourceMappingURL=MachineLayout.module.css.map */