.root {
    display: inline-block;
    width: 10rem;
    // height: 1rem;
    background-color: rgb(255, 255, 255);
    
    
    .accordion {
        background-color: #0000;

        .accordionSummary {
            background-color: #0000;
        color: rgb(46, 46, 46);    
        }
    }
}

.childSelected{
    border: 2px solid rgb(255, 255, 255);
    
    &>*{
        color: rgb(0, 0, 0) !important;
        font-weight: 400;
    }
    // background-color: rgba(0, 80, 172, 0.905);
    // background-color: rgb(0, 68, 87);
    border:2px dashed rgb(0,69,147);
    animation: scaleUpDown2 2s alternate infinite;

}
.selected{
    &>*{
        color: rgb(0, 0, 0) !important;
        font-weight: 700;
    }
    // background-color: rgb(0, 69, 147);
    border:4px solid rgb(0,69,147);
    z-index: 1;
    animation: scaleUpDown2 2s alternate infinite;
}

@keyframes scaleUpDown {
    0%{
        transform: scale(1);
    }
    50%{
        
        transform: scale(1.1);
    }
    100%{
        transform: scale(1);

    }

}

@keyframes scaleUpDown2 {
    0%{
        transform: scale(1);
    }
    50%{
        
        transform: scale(1.05);
    }
    100%{
        transform: scale(1);

    }

}