.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}
.root .header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  padding-bottom: 2rem;
}
.root .template-download {
  position: absolute;
  top: 1.25rem;
  right: 1rem;
}
.root .template-download button {
  font-size: 0.8rem;
  border: 2px solid #073b4c;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  border-radius: 10px;
  background: transparent;
  color: #073b4c;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.root .file-upload-container {
  min-width: 250px;
  width: 70%;
  height: 350px;
  margin-top: 2rem;
  background-color: #caf0f8;
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 5px 35px #8ecae6;
  position: relative;
}
.root .file-upload-container .file-uploader {
  border: 4px dashed #073b4c;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.root .file-upload-container .file-uploader p {
  font-weight: 500;
  font-size: 1.5rem;
  color: #073b4c;
}
.root .file-upload-container .file-uploader p.file-name {
  position: relative;
}
.root .file-upload-container .file-uploader p.file-name:hover {
  background-color: #67959e;
  padding: 0.75rem 1rem;
  border-radius: 25px;
  color: #caf0f8;
  cursor: pointer;
}
.root .file-upload-container .file-uploader p.file-name:hover::before {
  content: "Remove Selected file";
  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(50%, 0);
  font-size: 0.75rem;
  color: #edae49;
}
.root .file-upload-container .file-uploader .file-input {
  position: relative;
  width: 200px;
  cursor: pointer;
}
.root .file-upload-container .file-uploader .file-input input {
  width: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
}
.root .file-upload-container .file-uploader .file-input button {
  width: 100%;
  padding: 0.5rem 0;
  border-radius: 10px;
  font-size: 1.25rem;
  border: 2px solid #073b4c;
  color: #073b4c;
  background: transparent;
  cursor: pointer;
  text-transform: uppercase;
}
.root .file-upload-container .message-container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.root .file-upload-container .message-container p {
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.root .file-upload-container .message-container.error {
  background-color: rgba(236, 74, 74, 0.719);
  color: #ffffff;
}
.root .file-upload-container .message-container.success {
  background-color: rgba(65, 206, 65, 0.678);
}
.root .file-upload-container .upload-file-btn {
  position: absolute;
  bottom: 0;
  transform: translate(0, 50%);
  width: 250px;
}
.root .file-upload-container .upload-file-btn.animation {
  animation: topToBottom 1s ease-in-out;
}
.root .file-upload-container .upload-file-btn button {
  width: 100%;
  padding: 0.75rem 0;
  border-radius: 10px;
  font-size: 1.25rem;
  font-weight: 500;
  border: none;
  text-transform: uppercase;
  background: #4a70fb;
  color: #fff;
  cursor: pointer;
}

@keyframes topToBottom {
  0% {
    bottom: 100%;
    width: 0;
  }
  100% {
    bottom: 0;
    width: 250px;
  }
}/*# sourceMappingURL=WorkOrderList.module.css.map */