@import '../../../Styles/';

.root {
    width: 100%;
	@include flex(row, flex-start);
	.lhs {
		width: 25%;
		border-right: 1px solid #d2d2d2;
		background: red;
	}
    .rhs{
        width: 75%;
        background: yellow;
    }
}
