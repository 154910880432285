.header {
  display: inline-flex;
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0px 0px 5px 0px;
}
.header .tooltipIcon {
  position: relative;
  left: 5px;
  font-size: small;
}/*# sourceMappingURL=Heading.module.css.map */