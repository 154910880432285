@import '../../../../Styles/index';
.root {
	@include boxShadow;
	@include flex(row);
	height: 100%;
	width: 100%;
	gap: 1rem;
	background: $white;
	color: $white;

	&.PASS {
		background: $success;
	}

	&.SKIPPED {
		background: $dark-grey;
	}

	&.OVERRIDDEN {
		background: $dark-yellow;
	}

	&.FAIL {
		background: $red;
	}

	.text {
		text-align: center;
		font-size: 1.5rem;
	}
	.button {
		border-color: $red;
		color: $red;
		background: $white;
		position: absolute;
		right: 1rem;

		&:hover {
			background: $white;
		}
	}
}
