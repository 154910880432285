@import '../../Styles/';

.root{
    @include flex(column, center, flex-start);
    gap: 1rem;
    border-top: 1px solid #d2d2d2;
    width: 100%;
    padding-top: 1rem;
    position: relative;

    .disabled {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 4;
        background: rgb(143, 143, 143);
        opacity: .25;
        cursor: not-allowed;
    }

    
    .actions-container{
        width: 100%;
        @include flex(row, flex-end);
        gap: .5rem;
        .downloadData {
            width: 125px;
            text-align: center;
            // margin-left: auto;
            cursor: pointer;
            @include flex;
            gap: 0.25rem;
            z-index: 7;
            background-color: #1d6f42;
            color: white;
            border-radius: 1rem;
            padding: 0.25rem 0.5rem;
            img {
                width: 25px;
            }
        }
    }
    .item-row{
        width: 100%;
        table{
            width: 100%;
            th{
                text-align: left;
                font-weight: 500;  
            }
            th, td{
                padding: 0.25rem;
                border: 1px solid #aaaaaa31;
            }
            th{
                vertical-align: middle;
                // width: 125px;
                .icon{
                    font-size: 0.9rem;
                }
            }
            td{
                vertical-align: top;
            }
            .even{
                background: #ffe62c2f;
            }
            .error{
                border: 2px solid red;
            }
        }
    }
}