@import '../../../../Styles';

.root {
	div {
		div {
			.label {
				margin-left: 5rem;
			} 
			label {
				margin-left: 1rem;
			}
			select {
				width: 65px;
				border-radius: 0px 4px 4px 0px;
				border: 1px solid $mid-grey;
				outline: none;
				font-weight: 100;
				background-color: white;
			}
		}
	}
}
