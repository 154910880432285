@import '../../../../Styles/';

.root{
    // width: 100%;
    @include flex(row, space-between);
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #d2d2d2;
    position: fixed;
    top: 3rem;
    left: 16rem;
    right: 0;
    background: white;
    z-index: 99;
    .header{
        @include flex(row);
        gap: .5rem;
        .heading{
            font-size: 1.25rem;
            font-weight: 500;
        }
        
        .icon{
            font-size: 2rem;
            color: #F8DE22;
        }
    }

    .actions-container{
        @include flex(row);
        gap: .5rem;
    }
}