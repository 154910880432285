@import '../../../../Styles/';

.root {
	@include flex(column, flex-start);
	margin-right: .25rem;
	// padding: 0.25rem;
	border: 1px solid rgba($mid-grey, 0.25);
	border-radius: 14px;
	width: 210px;

	&:hover {
		@include boxShadow;
		border: 1px solid $mid-grey;
	}

	.image-wrapper {
		width: 210px;
		height: 150px;
		border-radius: 14px;
		position: relative;

		
		.image {
			width: 100%;
			height: 100%;
			border-radius: 14px;
			object-fit: cover;
		}

		.delete-icon {
			position: absolute;
			font-size: 1.25rem;
			top: .15rem;
			right: .15rem;
			cursor: pointer;

			&:hover {
				color: $pastel-red;
			}
		}
	}
	.title {
		// @include applyEllipsis;
		width: 100%;
		text-transform: capitalize;
	}
}
