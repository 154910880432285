.root {
	width: 100%;
	position: relative;
	background-color: #fff;
	// z-index: 1000;
	.vlci1 {
		width: 100%;
		// margin-left: 5px;
		padding: 0.25rem;
		display: flex;
		align-items: center;
		border-radius: 8px;
		justify-content: space-between;
		&:hover {
			background-color: #ffb703;
			cursor: pointer;
			z-index: inherit;
		}
	}
	.input {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		position: relative;
		border-radius: 8px;
		border: 1px solid black;
		// border-radius: 10%;
		// z-index: 1000;
		.disabled {
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			// z-index: 1000;
			background: #85858544;
		}
		.search-input {
			width: 100%;
			border: none;
			outline: none;
			padding: 1rem 0.5rem;
			border-radius: 8px;
			// z-index: 1000;
		}
		.icon-conainer {
			display: flex;
			gap: 0.25rem;

			.remove {
				display: flex;
				cursor: pointer;
				padding: 0.15rem;

				&:hover {
					background: #0000005d;
					border-radius: 50%;
				}
			}
		}
	}
	.query-items {
		position: absolute;
		top: 100%;
		background: white;
		border-radius: 4px;
		width: 100%;
		height: 250px;
		overflow: auto;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		z-index: 1000;
		.empty {
			width: 100%;
			height: 100%;
			text-align: center;
			display: flex;
		}
		.vlci {
			padding: 10px;
			width: 200 px;
			height: 70px;
			padding: 0.25rem;
			display: flex;
			align-items: center;
			border-radius: 8px;
			justify-content: space-between;
			// border-top: 1px solid black;
			// border-bottom: 1px solid black;
			margin: 1px;
			box-shadow: 10px 0px 10px 0px lightgrey;
			z-index: 1000;
			&:hover {
				background-color: #ffb703;
				cursor: pointer;
				z-index: inherit;
			}
			&.selected {
				background-color: #ffb703;
			}
		}
		.imgx {
			height: 40px;
			// width: 40px;
			border-radius: 50%;
			margin-left: 10px;
			object-fit: contain;
			// padding-top: 10px;
		}
	}
}
