@import '../../../Styles/';

.btn-container{
	width: 100%;
	@include flex;
	gap: .25rem;

	.proceed {
		flex: 1;
		background-color: $success;
		color: $white;
	}
	.cancel {
		flex: 1;

		background-color: $pastel-red;
		color: $white;
	}
}
