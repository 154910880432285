@import '../../../Styles/index';

.root {
	width: 100%;
    height: 100%;
	@include flex(column, space-between);
	.input-container {
		width: 100%;
		@include flex(column);
		gap: 1rem;
		margin: 1rem 0;
	}
}
