@import '../../../Styles/';

.variant-input{
    @include flex(column);
    p{
        font-size: 0.9rem;
    }
    input{
        width: 75px;
        border-radius: 3px;
        border: 1px solid hsl(0, 0%, 0%);
        padding: .1rem .25rem;
        &:hover, &:active, &:focus, &:visited{
            border-color: $uy-bg !important;
            outline-color: $uy-bg;
        }
    }
}

.root{
    .list-container{
        height: calc(100vh - 8rem);
        overflow: auto;
        .table{
            thead {
                tr {
                    top: 0;
                }
            }
        }
    }
    .header{
        width: 100%;
        padding: 1rem 0.5rem;
        @include flex(row, space-between);
        .filter{
            width: 100%;
            @include flex(row, flex-start);
            gap: 1rem;
            .store-container{
                position: relative;
                p{
                    position: absolute;
                    bottom: -3rem;
                    color: red;
                    font-weight: 500;
                }
                .store-select{
                    width: 200px !important;
                }
            }
            .selector{
                width: 200px !important;
            }
        }
        .button{
            width: 100px !important;
        }
    }
}