@import '../../../../Styles/';

.root {
	width: 100%;
	@include flex(column, flex-start, flex-start);
	gap: 1rem;
	.labelled_input {
		width: 100%;
		@include flex(column, flex-start, flex-start);
		// gap: 0.25rem;
		label{
			width: 100%;
			font-weight: 500;
			background: #408e913b;
			text-align: center;
			margin-bottom: 0.25rem;
			font-size: 1.25rem;
			border-radius: 2px;
		}
		input {
			width: 100%;
			padding: 0.5rem 1rem;
			outline: none;
			border-radius: 12px;
			border: 2px solid #7e7e7e;
		}
	}

	.date_selector {
		width: 100%;
		@include flex;
		gap: 1rem;
		.selector {
			flex: 1;
			padding: 0.5rem 1rem;
			@include flex(row, flex-start);
			gap: 0.5rem;
			background: #f2f2f2;
			border: 2px solid #ececec;
			border-radius: 12px;
			position: relative;
            cursor: pointer;
			&.selected {
				border: 2px solid $uy-bg;
			}
			input {
				width: 20px;
				height: 20px;
			}
			p {
				font-size: 1.25rem;
			}
			.time_selector {
				width: 100%;
				@include flex;
				gap: 1rem;
				position: relative;
				.schedule_later{
					position: absolute;
					background: #f1f1f1;
					padding: 0 4px;
					top: -20px;
					left: 0;
					z-index: 9999;
					border-radius: 25px;
					font-size: .9rem;
					border: 1px solid #d1d1d1;
				}
				.picker {
					flex: 1;
				}
				.time_selector{
					padding: .25rem .5rem;
					border-radius: 8px;
					border: 1px solid #d1d1d181;
					outline: none;
					option{
						height: 100px;
					}
				}
			}
		}
	}
}
