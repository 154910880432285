@import '../../../../../Styles/index';

.root {
    @include flex(row, space-between);
    // height: 200px;
    background: #4a70fb3a;
    border-radius: 10px;
    border: 2px solid $primary;
    width: 100%;
    padding: 1rem;
    position: relative;
    transition-duration: 2s;
    .storeDiv{
        display: flex;
        flex-direction: column;
    }
    .store-select {
        min-width: 150px;
        max-width: 170px;
        background: white;
    }

    .status-select {
        min-width: 150px;

        .select {
            width: 100%;
        }
    }

    .filter {
        @include flex(column, flex-start);

        .filter-heading {
            // width: 100%;
            // text-align: center;
            // padding: .5rem;
            // border-top: 1px solid black;
            // border-bottom: 1px solid black;
            font-size: 1rem;
            text-transform: uppercase;
        }

        .filter-body {
            @include flex;
            flex-direction: column;
            width: 100%;
            gap: 0.5rem;
            padding: 1rem;

            .tags {
                @include flex(row, center);
                gap: 0.5rem;
                flex-wrap: wrap;
            }

            .add-button {
                background: $white;
                border: 1px solid $primary;

                &:hover {
                    background: $white;
                }
            }
        }
    }

    .seperator {
        @include flex(column);
        color: $black;
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: 500;

        svg {
            font-size: 2rem;
        }
    }


    p {

        .selectedVendor{
            font-size: smaller;
            display: flex;
            max-width: 200px;
            column-gap: .5rem;
            row-gap: .2rem;
            flex-wrap: wrap;
            div{
                background-color: rgba(4, 0, 255, 0.164);
                padding: .1rem .3rem;
                border-radius: 5px;
                 
            }
        }

        font-size: 1.2rem;
        font-weight: 500;

        span {
            font-size: 0.75rem;
            border-radius: 10px;
            padding: .25rem .35rem;
            background-color: $primary;
            color: #fff;
            margin-right: .25rem;
        }
    }

    .search-icon {
        width: 50px;
        height: 50px;
        background: $primary;
        border-radius: 50%;
        @include flex;
        cursor: pointer;

        .icon {
            color: #fff;
        }
    }

    &.hide {
        // height: 200px;
    }

    .hide-icon-container {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        @include flex(column);
        cursor: pointer;
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 5px 20px #c2d2c2;

        .icon {
            font-size: 1.25rem;
        }
    }
}