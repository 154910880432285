/* similarProducts.css */
@import "../../Styles/";
.App {
  background-color: gray;
  padding: 20px;
}


  .header{
      padding: 0.5rem;
  }




.group-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: inline-block; 
}


.select-container {
  margin-bottom: 10px;
}

.select-container select {
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  // margin-right: px;
}

.select-container button {
  padding: 5px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.list-container {
  width: 400px;
  height: 500px;
  border: 2px solid white;
  overflow-y: auto;
}

.list-container div {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.list-container div:last-child {
  border-bottom: none;
}


.SubmitButton {
  margin-top: 20px;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid black;
  color: white;
  top: 0px; /* Adjust as needed */
  right: 0px; /* Adjust as needed */
  background-color:green;
  font-size: 16px;
  cursor: pointer;
}

.card{
  background-color: white;
}

.search_product_container{
  @include flex(column);
  width: 100%;
  .search {
    border-radius: 25px;
    outline: none;
    padding: 0.25rem 0.5rem;
    width: 90%;
    // margin: 0 10px;
  }
}