@import '../../../Styles/';

.root {
	width: 100%;
	.enable-disable {
		display: flex;
		align-items: center;
		padding: 0.35rem;
		color: rgba($dark-grey, 0.75);

		.enable-disable-check {
			margin-right: 5px;
		}
	}
	.children {
		padding: 0.5rem;
	}
}
