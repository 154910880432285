@import '../../../../Styles/';

.variant-wise-batch {
	@include flex(row, space-between);
	padding: 0.25rem 0.5rem;
	border: 1px solid #dddddd;
	border-radius: 25px;
	max-width: 175px;
}

.tScheduledAt {
	display: flex;
	align-items: center;
	border-radius: 25px;

	.input-unit {
		@include flex(row, space-between);
		padding: 0.25rem;
		border-radius: 25px;
		gap: 0.25rem;
		width: 70%;

		.input {
			width: 50%;
			border-radius: 5px 0 0 5px;
			border: 1px solid black;
			padding: 0 0.25rem;
		}

	}
}

.variant-wise{
	width: 80%;
	@include flex(row, flex-start);
	flex-wrap: wrap;
}