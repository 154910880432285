.root {
  width: 100%;
  height: 100%;
}
.root .header {
  border-bottom: 1px solid rgba(151, 151, 151, 0.5);
  padding: 1rem;
}
.root .header p {
  font-size: 1.5rem;
}
.root .summary-data-selector {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  margin: 0.5rem 0;
}
.root .summary-data-selector .option {
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(122, 122, 122, 0.4392156863);
  color: rgba(0, 0, 0, 0.5333333333);
  background-color: rgba(107, 107, 107, 0.2470588235);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.root .summary-data-selector .option.selected {
  background: rgba(34, 124, 112, 0.2470588235);
  color: #227c70;
  border-color: #227c70;
}
.root .summary-data {
  width: 100%;
  height: 100%;
}
.root .summary-data .table {
  animation-duration: 1s;
  animation-fill-mode: both;
  background-color: #e6ddc4;
  width: 95%;
  padding: 1.5rem 1rem;
  margin: 0 auto;
  border-radius: 20px;
  animation-name: fadeInBottom;
}
.root .summary-data .table table {
  width: 100%;
  text-align: center;
}
.root .summary-data .table table th:nth-child(1),
.root .summary-data .table table td:nth-child(1) {
  text-align: left;
}
.root .summary-data .table table thead th {
  border-bottom: 1px solid #227c70;
  padding-bottom: 0.5rem;
}
.root .summary-data .table table tbody tr {
  line-height: 30px;
}
.root .summary-data .table table tbody tr td:nth-child(1) {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}
.root .summary-data .table table tbody tr td {
  border-bottom: 1px solid #227c70;
}
.root .summary-data .table table .sku-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #227c70;
  padding: 0.1rem;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}/*# sourceMappingURL=IMSSummaryPage.module.css.map */