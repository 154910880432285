@import '../../../Styles/';
.root{
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgb(255, 161, 161);
	z-index: 9999;
	gap: 0.5rem;
	clip-path: inset(100% 100% 100% 100%); /* Initial clip-path */
	transition: clip-path 1s ease; /* Animate the clip-path property */
	&.show{
		@include flex(column);
		clip-path: inset(0 0 0 0); /* Apply the animation */
		animation: square-in-center 1s ease both;
		transform-origin: -200px 200px; 
	}
	.close{
		position: absolute;
		top: 1rem;
		right: 1rem;
		color: #fff;
	}
	.inner-container{
		position: relative;
		width: 100%;
		height: 100%;
		padding: 0.5rem;

		.edit-body{
			width: 90%;
			padding: 1rem;
			border-radius: 8px;
			background: #fff;
		}
		
		.close{
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}
	}

}

@keyframes square-in-center {
  from {
    clip-path: inset(100% 100% 100% 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
