@import '../../../../Styles';

.root {
	@include flex(column, flex-start, flex-start);

	.checkbox-wrapper {
		@include flex(row, flex-start);
		margin-bottom: 1.25rem;

		input[type='checkbox'] {
			margin-right: 0.5rem;
		}
	}
}