.text2 {
	font-weight: bold;
	font-size: 20px;
}
tr {
	height: 50px;
	border: 1px black;
}
.first {
	width: 100%;
	height: 50%;
	display: flex;
	justify-content: left;
	border-bottom: 1px solid black;
}
.start {
	width: 100%;
	height: calc(100vh - 49.7px);
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.second {
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-right: 1px solid black;
}
.start2 {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	// position: sticky;
	// z-index: -1;
	height: calc(100vh - 113.7px);
	overflow: hidden;
	// border-bottom: 1px solid black;
}
.second2 {
	height: 25%;
	padding: 5px;
}
.second3 {
	height: 100%;
	// border-bottom: 4px blue;
	display: flex;
	flex-direction: column;
}

#vcli {
	padding: 10px;
	width: 200 px;
	border-top: 1px solid black;
	border-bottom: 1px solid black;
	img {
		height: 40px;
		border-radius: 50%;
		margin-left: 10px;
		object-fit: contain;
	}
}
#vcli:hover {
	cursor: pointer;
	background-color: yellow;
}
.imgx {
	height: 40px;
	border-radius: 50%;
	margin-left: 10px;
	object-fit: contain;
}
.vlci {
	padding: 10px;
	width: 200 px;
	border-radius: 5%;
	// border-top: 1px solid black;
	// border-bottom: 1px solid black;
	margin: 1px;
	box-shadow: 10px 0px 10px 0px lightgrey;
	&:hover {
		background-color: #ffb703;
		cursor: pointer;
	}
	&.selected {
		background-color: #ffb703;
	}
}
// .vlci:hover
// {
//     background-color: yellow;
//     cursor: pointer;
// }
.vlci.selected {
	background-color: #ffb703;
}
.list {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
}
.third {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
}
.fixdot {
	height: 100%;
	width: 100%;
	// border: 1px solid black;
}
.fixdot2 {
	height: 100%;
	width: 100%;
	overflow: auto;
	// border: 1px solid black;
}
.fixdot3 {
	height: 100%;
	width: 100%;
	// border: 1px solid black;
}
.panel {
	position: absolute;
	top: 0;
	left: 0;
	width: 5%;
	height: 20px;
	background-color: #fff;
	border: 1px solid black;
}
.dummy-container {
	display: flex;
	justify-content: space-between; /* Aligns Outlet and BaseKitchen at opposite ends */
	width: 100%; /* Ensures the container takes up the full width of the page */
	height: 100vh; /* Sets the container height to 100% of the viewport height */
}
.mainbtn {
	position: absolute;
	width: 30px;
	height: 30px;

	background: #ffb703;
}
.arrangetext {
	width: 100%;
	height: 50px;
}
.text3 {
	font-weight: bold;
	font-size: 26px;
	margin-left: 5px;
	padding-top: 10px;
	// transform: translate(-50%, -50%);
}
.btn-circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #ffb703;
	color: black;
	border: none;
	cursor: pointer;
	font-size: 16px;
	outline: none;
	margin-left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.frame {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.showInfo {
	font-size: 20px;
	padding: 5px;
	padding-left: 20px;
}
.select-wrapper {
	position: relative;
	user-select: none;
	width: 100%;
}
.select {
	position: relative;
	display: flex;
	flex-direction: column;
	border-width: 0 2px 0 2px;
	border-style: solid;
	border-color: #394a6d;
}
.select__trigger {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 22px;
	font-size: 20px;
	font-weight: 300;
	color: #3b3b3b;
	height: 60px;
	line-height: 60px;
	background: #ffffff;
	cursor: pointer;
	border-width: 2px 0 2px 0;
	border-style: solid;
	border-color: #394a6d;
}
.custom-options {
	position: absolute;
	display: block;
	top: 100%;
	left: 0;
	right: 0;
	border: 2px solid #394a6d;
	border-top: 0;
	background: #fff;
	transition: all 0.5s;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	z-index: 2;
}
.select.open .custom-options {
	opacity: 1;
	visibility: visible;
	pointer-events: all;
}
.custom-option {
	position: relative;
	display: block;
	padding: 0 22px 0 22px;
	font-size: 22px;
	font-weight: 300;
	color: #3b3b3b;
	line-height: 60px;
	cursor: pointer;
	transition: all 0.5s;
}
.custom-option:hover {
	cursor: pointer;
	background-color: #b2b2b2;
}
.custom-option.selected {
	color: #ffffff;
	background-color: #305c91;
}
.arrow {
	position: relative;
	height: 15px;
	width: 15px;
}
.arrow::before,
.arrow::after {
	content: '';
	position: absolute;
	bottom: 0px;
	width: 0.15rem;
	height: 100%;
	transition: all 0.5s;
}
.arrow::before {
	left: -5px;
	transform: rotate(45deg);
	background-color: #394a6d;
}
.arrow::after {
	left: 5px;
	transform: rotate(-45deg);
	background-color: #394a6d;
}
.open .arrow::before {
	left: -5px;
	transform: rotate(-45deg);
}
.open .arrow::after {
	left: 5px;
	transform: rotate(45deg);
}
.selectEvent {
	width: 85%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	background: #f1f1f1;
	border-radius: 8px;
	margin-bottom: 5px;
	cursor: pointer;
}
.bt {
	height: 25px;
	width: 49%;
	display: flex;
	border-radius: 6px;
	justify-content: center;
	align-items: center;
	padding: 5px;
	&.selected {
		background-color: #ffb703;
	}
}
