@import '../../Styles/';


.label_generator_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: calc(100vh - 49.7px);
	label{
		font-size: .9rem;
		padding-bottom: 5px;
	}
	.label-generator-header {
		display: flex;
		flex: 1;
		background-color: white;
		position: sticky;
		color: black;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 10%;
		padding: 10px 0px;
		border-radius: 6px;
		// font-family: 'Noto Sans Mono', monospace;
		gap: 1rem;
		#website-name {
			flex: 1;
			font-size: 1.5rem;
		}
		.label-generator-button {
			background-color: white;
			color: black;
			background-color: #ffb703;
			border-radius: 1rem;
			// border-radius: 1rem;
			align-items: center;
			padding: 0.3rem 0.4rem 0.3rem 0.4rem;
			margin-right: 8rem;
			// font-family: 'Mallanna', sans-serif;
			font-size: x-large;
			white-space: nowrap;
			cursor: pointer;
		}
	}

	/* Left and Right boxes */
	.boxes {
		display: flex;
		width: 100%;
		height: 90%;
		margin-top: 0px;
		padding: 0px auto;

		button {
			background-color: #ffb703;
			color: white;
			border-radius: 1rem;
			align-items: center;
			padding: 0.3rem 0.4rem;
			// font-family: 'Mallanna', sans-serif;
			font-size: 1rem;
			white-space: nowrap;
			cursor: pointer;
		}

		input,
		select {
			padding: 0.3rem;
			border-radius: 0.5rem;
			border-width: 1px;
			border-style: inset;
			border-color: -internal-light-dark(
				rgb(118, 118, 118),
				rgb(133, 133, 133)
			);
			border-image: initial;
			color: black;
			width: 100%;
		}

		.left-box {
			flex: 2;
			background-color: white;
			overflow: hidden;
			height: 100%;
			#left-box {
				height: 100%;
				#left-box-div {
					width: 100%;
					height: 100%;

					#tools-box {
						background-color: rgba(222, 207, 65, 0.18);

						.tool-box {
							display: flex;
							justify-content: space-between;
							height: 30%;
							padding: 1rem;

							#tools {
								display: flex;
								flex: 10;
								gap: 1rem;

								.tool-bounding-box {
									display: flex;
									align-items: center;
									gap: 0.5rem;
									flex: 1;

									.tool-img {
										width: 1.6rem;
										height: 1.6rem;
										align-items: center;
										background-color: white;
										padding: 0.25rem;
										border-radius: 6px;
									}
								}
							}

							#my-components {
								white-space: nowrap;
								flex: 2;
							}
						}
					}

					#screen {
						background-color: #f1f1f1;
						height: 100%;
						// overflow: auto;
						width: 100%;
						overflow-x: scroll;

						.saved-component-description {
							display: flex;
							flex: 5;
							// border-bottom: 1px solid black;
							font-size: 1rem;
							box-shadow: 0 0 1px #d1d1d1;
							padding: 0.5rem 2rem;
						}

						.saved-label {
							// display: flex;

							.saved-label-description {
								display: flex;
								flex: 5;
								// border-bottom: 1px solid black;
								font-size: 1rem;
								box-shadow: 0 0 1px black;
								padding: 0.5rem 2rem;

								.saved-labels-buttons {
									display: flex;
									gap: 1rem;
									// margin: 0.5rem;

									#open-label {
										flex: 1;
										height: fit-content;
									}

									#delete-label {
										flex: 1;
										// padding: 0rem;
										height: fit-content;
										background-color: red;
									}
								}
							}
						}

						.preview-label-screen {
							height: auto;
							margin-top: 17%;
							margin-left: 30%;
						}

						#static-text-input {
							font-size: large;
							margin-left: 30%;
							position: relative;
							display: flex;
							margin-top: 5%;
							#static-text-input-box {
								padding: 0 0.5rem;
								margin-left: 10px;
								background-color: rgba(0, 0, 0, 0.5);
								color: white;
								font-weight: bolder;
								font-size: large;
								width: 35%;
							}
						}

						.saved-components {
							display: flex;
							margin: 3rem;
							height: 100%;

							.saved-component {
								flex: 5;

								.saved-component-description {
									display: flex;
								}
							}
							.delete-component {
								width: 100%;
								background-color: red;
							}
						}

						#label {
							background-color: white;
							box-shadow: 0 0 10px #d1d1d1;
							border-radius: 4px;
							width: 22rem;
							height: 22rem;
							margin-top: 8%;
							margin-left: 30%;
							table,tr,th,tbody,thead,td{
								overflow: hidden;
							}
						}
					}
				}
			}
		}

		.right-box {
			height: 100%;
			h2 {
				line-height: normal;
			}
			flex: 1;
			background-color: #d9d9d9;
			overflow: hidden;
		}

		table {
            border-collapse: collapse;
            // width: 50%;
            // margin: 20px auto;
        }
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: center;
        }
        th {
            background-color: #f2f2f2;
        }
        input[type='text']{
            border: none;
        }
	}

	.qr-code {
		margin: 0;
		bottom: 0;
	}

	.qr-code-img {
		width: 100%;
		height: 100%;
		align-items: center;
		margin: 0;
		bottom: 0;
	}

	#right-box {
		// font-family: 'Noto Sans Mono', monospace;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		overflow: auto;
		select {
			option{
				padding: 8px 12px;
			}
			option:hover{
				background: $uy-bg;
			}
		}
		#label-properties-header {
			font-size: x-large;
			font-weight: 400;
		}

		.label-properties {
			color: rgba(0, 0, 0, 0.5);
			margin-top: 1rem;
			margin-bottom: 1rem;
			display: grid;

			.label-size,
			.text-size,
			.qrcode-size,
			.barcode-size,
			.image-size,
			.table-size {
				display: flex;
			}
			.label-size-input-box,
			.qrcode-size-input-box,
			.barcode-size-input-box,
			.image-size-input-box,
			.text-font-size-input-box,
			.static-text-font-size-input-box,
			.placeholder-text-input-box,
			.text-lines-input-box {
				// width: 80%;
				font-size: large;
			}
			.text-size {
				display: flex;
				gap: 0rem;
				// width:82%;

				.text-size-input-box {
					font-size: large;
					// width: 100%;
				}
			}
			.qrcode-size {
				// gap:2rem;

				.qrcode-size-input-box {
					// width: 70%;
				}
			}
			.barcode-size {
				display: flex;
				gap: 0rem;

				.barcode-size-input-box {
					font-size: large;
				}
			}
			.static-text-size {
				display: flex;
				width: 100%;
				gap: 0rem;

				.static-text-size-input-box {
					// width:100%;
					font-size: large;
				}
			}
			.table-size{
                display: flex;
                gap:0rem;

                .table-size-input-box{
                    font-size: large;
                }
                
            }

			.ip {
				display: flex;
				// width: 85%;

				.printer-ip-address {
					// flex:1;
					width: 22%;
					margin: 0;
				}
			}

			#placeholder-name-select {
				height: 100%;
				width: 100%;
				// margin: 0% 10% 0% auto;
				font-size: large;
			}

			#unit-select {
				background-color: rgba(0, 0, 0, 0);
				display: flex;
				border: none;
				font-weight: bolder;
				// font-family: 'Itim', cursive;
				font-size: large;
			}
			
			#label-name-input-box {
				width: 100%;
				font-size: large;
			}
			.parameter-input-box {
				width: 100%;
				font-size: large;
			}

			#back-button {
				// flex:1;
			}
			#back-button,
			#back-button-saved-labels-screeen {
				// flex:0.8;
			}
		}
		.table-modal{
			color: rgba(0, 0, 0, 0.5);
            font-size: large;
            margin-top: 1rem;
            display: grid;
        }

        .table{
            overflow: auto;
			margin: 1rem auto;
        }

		.label-buttons {
			display: flex;
			// width: 85%;
			// margin-bottom: 10%;
			// margin-top: 85%;
			gap: 1rem;

			#save-label {
				height: auto;
				flex: 1;
			}

			#preview-label {
				height: auto;
				flex: 1;
			}
			#print-label {
				flex: 1;
			}
			#refresh-label {
				flex: 1;
			}
		}

		#back-button {
			flex: 1;
		}
	}

	// #font-size-select{
	//     height: 100%;
	//     width:  84%;
	//     margin: 0% 10% 0% auto;
	//     font-size: large;
	// }

	.tools-back-save {
		display: flex;
		gap: 1rem;
		// width:80%;
		// margin-top:20%;
		// margin-bottom:20%
	}

	#static-tools-back-save {
		// margin-top: 80%;
	}

	.back-save-buttons {
		// margin-top: 65%;
		// width: 80%;
	}
	.home_btn{width: 30px;
		height: 30px;
		border-radius: 50%;
		cursor: pointer;
		@include flex;

		&:hover{
			background: $uy-bg;
		}
	}
	#back-component {
		flex: 1;
	}
	#delete-saved-component {
		flex: 1;
		background-color: red;
	}
	#back-component-static-text {
		flex: 1;
		margin-top: 28%;
	}
	// #save-component-static-text{
	//     flex: 1;
	// }
	#save-component {
		flex: 1;
	}

	.handle,
	textarea {
		cursor: grab;
	}

	.tool-img,
	.button,
	select {
		cursor: pointer;
	}

	// .saved-component-delete{
	//     flex: 2;
	//     margin-right: 1rem;
	// }

	#saved-labels {
		display: flex;
	}

	textarea {
		border: 0;
		padding: 0;
		padding-top: 0;
		padding-bottom: 0;
		margin-top: -10px;
	}

	:focus-visible {
		outline: 0;
	}
}
