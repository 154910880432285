@import '../../../../Styles/index';

.root {
    @include flex(row, space-between);
    // height: 200px;
    background: #4a70fb3a;
    border-radius: 10px;
    border: 2px solid $primary;
    width: 100%;
    padding: 1rem;
    position: relative;
    transition-duration: 2s;

    .storeDiv {
        display: flex;
        flex-direction: column;
    }

    .search-icon {
        width: 50px;
        height: 50px;
        background: $primary;
        border-radius: 50%;
        @include flex;
        cursor: pointer;
        
        .icon {
            color: #fff;
        }
    }
    .disabled{
        background: rgb(190,190,190) !important;

    }

}