@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--primary: 222.2 47.4% 11.2%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;

		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;

		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;

		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 222.2 84% 4.9%;

		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;

		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;

		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
	}
}
/* 
@layer base {
} */

html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	font-size: 10px;
	color: black;
	 font-family: "Inter", sans-serif;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@media (min-width: 992px) {
	html,
	body {
		font-size: 13px;
	}
}
@media (min-width: 1440px) {
	html,
	body {
		font-size: 16px;
	}
}

#root {
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none !important;
}
a:hover,
a:after,
a:before {
	text-decoration: none !important;
}

.uy_btn_class {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	padding: 0.25rem 0.5rem;
	background: #ffb703;
	font-weight: 500;
	border-radius: 20px;
	transition: 0.5s;
}

.uy_btn_class:hover {
	background: rgba(#ffb703, 0.4);
}

.rs-picker-default .rs-picker-toggle {
	border-radius: 25px;
	border: 1px solid #1d1d1d;
	padding-right: 0;
}

.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn,
.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
	padding-right: 8px;
	border-radius: 8px;
  border: 1px solid #D9D9D9;
  margin-top: 5px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
	border-color: #ffb703;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
	background-color: #ffb703 !important;
	color: black;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus,
.rs-picker-select-menu-item:focus,
.rs-picker-select-menu-item:hover {
	color: black;
	background-color: rgba(255, 183, 3, 0.5);
}

.rs-picker-popup-date.rs-picker-popup {
	z-index: 10;
}

.rsuite-select-picker-render-value {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.rs-modal-content {
	background: #f9f7f0;
}

.gradient1{
	background: linear-gradient(0deg, rgba(0,0,0,1) 9%, rgba(255,255,255,0) 53%);
}

.primary-btn{
	@apply bg-uy-color hover:!bg-slate-300 text-black;
}
.danger-btn{
	@apply border-2 border-red-600 text-red-600 bg-transparent hover:!bg-slate-200;
}

/*# sourceMappingURL=index.css.map */

  