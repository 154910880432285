.root {
  box-shadow: none !important;
  outline: none !important;
  border-bottom: 2px solid rgb(255, 255, 255);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.root.selected {
  background: #d9d9d9 !important;
}
.root.selected .name {
  font-weight: 500 !important;
}
.root.completed {
  background: rgba(2, 122, 0, 0.537254902) !important;
}
.root.completed .accordion-heading .name {
  color: #fff !important;
  font-weight: 600 !important;
}
.root.progress {
  background: #fff6b8 !important;
}
.root .accordion-heading {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.root .accordion-heading .indicator {
  padding: 0 0 0 1rem;
}
.root .accordion-details {
  padding: 0;
}
.root .accordion-details .expanded-div {
  padding: 0.5rem;
  width: 100%;
  height: 100%;
}/*# sourceMappingURL=ProcessAccordion.module.css.map */