.datePicker{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

}
.datePicker label{
    background: #fff;
    position: absolute;
    top: -0.6rem;
    left: 0.5rem;
    z-index: 6;
    padding: 0 0.5rem;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
}
.datePicker .rs-picker-date{
    width: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.datePicker .rs-picker-date .rs-btn{
    padding: 18px 5px !important;
}
.datePicker .rs-picker-date .rs-btn .rs-stack .rs-stack-item .rs-picker-toggle-caret,
.datePicker .rs-picker-date .rs-btn .rs-stack .rs-stack-item .rs-picker-toggle-clean{
    top: 18px !important;
}

.rs-picker-menu{
    z-index: 1301 !important;
}