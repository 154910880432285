@import '../../../Styles';

.editable-input {
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.custom-input {
    width: 100%;
    border-radius: 0px 8px 8px 0px;
    outline: none;
    padding: 0.25rem 0.5rem;
    min-width: 100px;
    border-left: none;
    box-sizing: content-box;
}

.includeIcon {
	color: #4aca65;
	width:40px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	justify-content: right;
	display: flex;	
	align-items: center;
	margin-right: 2px;
}
.excludeIcon {
	color: #c74545;
	width:40px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	justify-content: left;
	display: flex;	
	align-items: center;
	margin-right: 2px;
}

.value {
    width: 100%;
    align-items: center;
    justify-content: center;
}


.item-center {
    display: flex;
    width: 100%;
    align-items: center;
}

.yellow {
    display: flex;
    background-color: $uy-bg;
    height: 100%;
    // width: 2%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .25rem .5rem; 
    border: 2px solid $uy-bg ;
}

.btnPrint {
    padding: 5px;
    border-radius: 5px;
    background-color: $uy-bg;
    margin-bottom: 5px;
    position: absolute;
    top: 45px;
    right: 20px;
    display: flex;
    gap: 5px;
    cursor: pointer;
}

.contract-container {
    @include flex(column, flex-start, flex-start);
    gap: 10px;
}

.text {
    text-align: start;
    font-weight: bold;
    font-size: 20;
}

.vendor-details {
    background-color: $grey;
}

.detail-row {
    @include flex(row, flex-start, flex-start);
    gap: 5px;
    font-weight: 600;
    padding: 5px;
}

.details-container {
    @include flex(row, flex-start, flex-start);
    gap: 1rem
}