@import '../../../Styles/';

.root {
	width: 95%;
	padding: 1rem;
	background: rgba($primary, 0.2);
	border: 1px solid $primary;
	border-radius: 4px;
	margin-top: 1rem;
	.header-container{
		width: 100%;
		@include flex(row, space-between);
		.header-selector-wrapper{
			@include flex(row);
			gap: 1.5rem;
			.header {
				font-size: 1.25rem;
			}
		}

		.range-container{
			@include flex(row);
			gap: 1.5rem;
		}

		.defect-select, .time-select{
			background: #fff;
		}
	}

	.form {
		@include flex;
		.filters {
			@include flex(row, center, stretch);
			flex-basis: 90%;
			margin-top: 1rem;
			padding: 1rem;

			.brand {
				flex-basis: 20%;
				.brand-select {
					width: 100%;
					background: $white;
				}
			}

			.defect,
			.product {
				flex-basis: 40%;
			}

			.filter {
				@include flex(column, flex-end);
				gap: 1rem;
				min-width: 300px;
				.filter-heading {
					font-size: 1rem;
					text-transform: uppercase;
				}
				.filter-body {
					@include flex;
					width: 100%;
					gap: 0.5rem;
					padding: 1rem;

					.select{
						width: 100%;
					}
				}
			}

			.seperator {
				@include flex(column);
				color: $black;
				padding: 1rem;
				font-size: 1.5rem;
				font-weight: 500;

				svg {
					font-size: 2rem;
				}
			}
		}
		.submit {
			@include flex(column);
			flex-basis: 10%;
			border-left: 1px solid $primary;
			padding: 1rem;
			font-size: 1.25rem;
			text-transform: uppercase;

			.btn {
				&:disabled {
					svg {
						color: rgba($primary, 0.4);
					}
				}

				svg {
					font-size: 2.5rem;
					color: $primary;
				}
			}
		}
	}
}
