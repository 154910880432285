@import '../../../../Styles/index';

.root {
	@include flex(column);
	width: 100%;
	height: 100%;

	.feed-container{
		position: relative;
		width: 100%;
		height: 100%;

		.feed {
			@include boxShadow;
			@include md {
				min-height: 37.5rem;
			}
			width: 100%;
			max-width: 100%;
			max-height: 100%;
			border-radius: 4px;
			background: $white;
			display: flex;
			object-fit: cover;
		}

	}

	.camera-info {
		@include stat-box;
		position: absolute;
		visibility: hidden;
	}

	.footer {
		@include flex(row, space-between, stretch);
		height: 100%;
		max-height: 4.5rem;
		width: 100%;
		position: absolute;
		bottom: 1rem;
		padding: 0.5rem 1.5rem;

		.live {
			@include stat-box;
			.label {
				font-size: 0.75em;
			}
		}
	}

	.measurement {
    @include flex(column, flex-end, flex-end);
    width: 100%;
    position: absolute;
    bottom: 16px;
    padding: 0 16px;
    gap: 5px;

    .live-measurement-container {
      @include flex(column);
      gap: 0.25rem;

      .live-measurement {
        width: 200px;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-weight: 600;
        border-radius: 50px;
        padding: 0.25rem 0.5rem;
      }
    }
  }

}
