@import '../../../Styles/index';

.root{
    .header{
        padding: 0.5rem;
    }

    .modeules_view{
        // @include flex(row, flex-start);
        // gap: 0.5rem;
        cursor: pointer;
    }
    .btn_container{
        width: 120px;
    }
}
.module{
    font-size: 1rem !important;
    line-height: 2;
}