@import '../../../Styles/index';

.root {
    width: 100%;
    position: relative;
    padding: .5rem;
    border-radius: 5px;
    .downloadCompleteData{
        position: absolute;
        cursor: pointer;
        padding: .4rem .2rem .2rem .2rem;
        background: rgba(20,20,20,.8);
        border-radius: 5px;
        
        top: -.5rem;
        right: .5rem;
        color: white;
        transition: all;
        transition-duration: 100ms;
        overflow: hidden;
        transform: scale(.8);

        &:hover{
            transform: scale(1.1);
        }
        .icon{

        }
    }

    .heading{
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1rem;
        cursor: default;
        .line{
            height: 1px;
            width: 100%;
            flex: 1;  
            background: #00000034;
        }
        // border-top: 1px solid rgba(79, 79, 79, 0.783);
        width: 100%;
        text-align: center;
        color:  rgba(0, 0, 0, 0.384);
        letter-spacing: 1.3px;
        font-weight: bolder;
        font-size: larger;
        padding-bottom: 10px;
    }

    .container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;

        .chart {
            height: 275px;
            min-height: 275px;

            @include rwd(1400) {
                height: 325px;
            }

            border-radius: 8px;
            background: #EFF5F5;
            @include boxShadow;
            padding: 0.5rem;
        }

        .sku-highlight {
            @include flex(column);
            gap: 1rem;

            .sku-highlight-header {
                // @include flex(row, space-between);
                position: relative;
                width: 100%;
                @include flex;

                .previous,
                .next {
                    position: absolute;
                    font-size: 1.5rem;
                    font-weight: 500;
                    cursor: pointer;
                }

                .previous {
                    left: 0.5rem;
                }

                .next {
                    right: 0.5rem;
                }

                p {
                    font-size: 1.5rem;
                    font-weight: 500;
                }
            }

            &-chart {
                height: 85% !important;
                width: 100% !important;
            }
        }

        .vendor-highlight {
            @include flex(column);
            gap: 1rem;

            p {
                font-size: 1.5rem;
                font-weight: 500;
            }

            &-chart {
                height: 85% !important;
                width: 100% !important;

            }
        }
    }
}

.height{
    max-height: 290px;
    min-height: 275px;
}