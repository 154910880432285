@import '../../Styles/';

.root {
	@include flex(row, space-between);
	padding: 0.25rem;
	border-radius: 25px;
	border: 1px solid #dddddd;
	gap: 0.25rem;
	width: 70%;
	.input {
		width: 50%;
		border-radius: 25px 0 0 25px;
		outline: none;
		border: none;
		padding: 0 0.25rem;
	}
}
