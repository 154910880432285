@import '../../../../Styles/index';

.root {
    height: 165px;
    padding: 1rem 1rem;
    display: flex;
    // grid-template-columns: 1.5fr 1fr;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: space-between;
    // height: 100%;

    // background-color: rgb(143, 105, 105);
    border-radius: 8px;
    background: #eff5f5;
    @include boxShadow;

    .header {
        // padding-bottom: 5px;
        font-weight: 500;
        font-size: 1.1rem;
        padding: 0px 0px 5px 0px;
    }

    .content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
        // grid-template-columns: 1fr 1fr;
        width: 100%;

        height: 100%;
        // position: relative;
        .icon {
            justify-self: end;
            font-size: 4.5rem;
            font-weight: 500;
            // z-index: 1;
        }

        p {
        //   color: rgba(79, 160, 149, 1);
            word-break: break-all;
            font-weight: 500;
            font-size: 2.0rem;
        }

        .pieChart {
            // position: relative;
            // justify-self: end;
            width: 75px !important;
            height: 75px !important;
            transform: scale(1);
            // top: -5px;
            // right: 10 px;

        }
        .smallPieChart{
            width: 23px !important;
            height: 23px !important;
            transform: scale(1);
        }

        .generic{
            display: flex;
            height: 100%;
            width: 100%;
            padding: 4px 8px;
            border-radius: 5px;
            background: #3b3d3f0c;
            opacity: .8;
            flex-direction: column;
            justify-content: space-around;
            // background-color: aqua;

            .item{
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                // background-color: blue;
                width: 100%;
                justify-content:space-between ;
                .tooltipIcon{
                    font-size: 3rem;
                }
            }
        }
        
    }
}

// .weightIconContainer{
//     border-left: 1px solid black ;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     width: 100%;
//     text-align: center;
//     font-weight: 500;
//     font-size: 1.2rem;
//     .icon{
//         color: rgb(10,10,230);
//         font-size: 5rem;
//     }

// }

// .currencyIconContainer{
//     // border-left: 1px solid black ;
//     display: flex;
//     justify-content: flex-end;
//     flex-direction: row;
//     align-items: baseline;
//     width: 100%;
//     font-weight: 500;
//     font-size: 3rem;
//     // background-color: aquamarine;
//     .icon{
//         // background-color: aqua;
//         color: rgb(40, 40, 44);
//         font-size: 2rem;
//         position: relative;
//         top: 2px;
//     }
//     p{
//         // padding-top:2px ;
//     }

// }

// .icon{
//     // background-color: aqua;

// }