@import '../../Styles/';

.root {
	@include flex;
	width: 100%;
	border-radius: 12px;
	background: #adb5bd;
	.filter {
		@include flex;
		flex: 1 1 auto;
		min-height: 50px;
		transition: 0.25s background ease-in;
		border-radius: 12px;
		cursor: pointer;
		.text {
			font-size: 1.75rem;
			// color: $white;
			cursor: default;
		}
		&:hover {
			background: rgba($uy-bg, .25);
		}

		&.active {
			background: $uy-bg;
		}
	}
}
