@import '../../../Styles/';

.root {
	.body {
		@include flex(column, flex-start);
		gap: .4rem;
		.qcId {
			font-size: x-small
		}

		.image-container {
			@include flex;
			width: 100%;
			position: relative;
			background-color: #EEEEEE;
			border-radius: 8px;
			padding: 0.3rem 0;
			height: 30rem;

			.labels {
				position: absolute;
				top: 0px;
				left: 0px;
				z-index: 10;
				width: 100%;
			}

			img {
				border: 1px solid transparent;
				object-fit: contain;
				width: 100%;
				height: 100%;
				border-radius: 4px;
			}
		}

		.table {
			width: 100%;
			border-collapse: separate;
			border-radius: 0.5rem;
			overflow: hidden;
			border: 1px solid $dark-grey;
			border-spacing: 0px;

			tr {
				td {
					text-align: left;
					padding: 0.5rem;
					border-top: 1px solid $dark-grey;
					border-left: 1px solid $dark-grey;

					&:first-child {
						@include flex(row, flex-start);
						gap: 0.5rem;
						border-left: none;
						text-transform: uppercase;
					}
				}

				&.food {
					td {
						&:last-child {
							color: $success;
						}
					}
				}

				&.defect {
					td {
						&:last-child {
							color: $red;
						}
					}
				}

				&.date {
					td {
						&:last-child {
							color: $primary;
						}
					}
				}

				&:first-child {
					td {
						border-top: none;
					}
				}
			}
		}
	}
}