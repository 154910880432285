@import '../../../Styles';

.root {
	@include flex(row, space-between);
	margin: 1.25rem 0px;

	.fallback {
		width: 100%;
		text-align: center;
	}

	.from,
	.to {
		@include flex(column);
		gap: 1rem;
		flex-basis: 40%;

		.title {
			font-size: 0.75rem;
		}

		.value {
			@include flex;
			width: 100%;
			height: 45px;
			max-width: 120px;
		}
	}
}
