@import '../../../Styles';

.root {
	position: relative;
	background: $white;
	border-radius: 4px;

	// .strikeOff {
	// 	position: absolute;
	// 	width: 100%;
	// 	border-top: 3px dashed black;
	// 	z-index: 1;
	// 	rotate: -15deg;
	// 	top: 50%;
	// }
	.strikeOff {
		position: absolute;
		width: 120%;
		left: -10%;
		border-top: 3px dashed rgba(209, 0, 0, 0.705);
		z-index: 1;
		rotate: -20deg;
		top: 45%;
	}

	.squiggle  {
		position: absolute;
		width: 100%;
		height: .2rem;
		background: linear-gradient(90deg, rgba(2,0,36,0) 38%, rgba(37,35,66,.5) 45%, rgba(0,0,0,.5) 75%, rgba(255,255,255,0) 82%);
		z-index: 1;
		top: 33%;
		right: -50%;
	}
	// .squiggle2  {
	// 	position: absolute;
	// 	width: 100%;
	// 	height: .1rem;
	// 	background: linear-gradient(90deg, rgba(2,0,36,0) 38%, rgba(37,35,66,.5) 45%, rgba(0,0,0,.5) 75%, rgba(255,255,255,0) 82%);
	// 	z-index: 1;
	// 	bottom: 20%;
	// 	right: -50%;
	// }
	.squiggle3  {
		position: absolute;
		width: 100%;
		height: .1rem;
		background: linear-gradient(90deg, rgba(2,0,36,0) 38%, rgba(37,35,66,.5) 45%, rgba(0,0,0,.5) 75%, rgba(255,255,255,0) 82%);
		z-index: 1;
		top: 33%;
		right: -50%;
	}

	.extraValue {
		position: absolute;
		bottom: 5%;
		width: 100%;
		z-index: 1;
		padding-left: 2.6rem;
		text-align: center;
		font-size: .6rem;
	}

	.cross {
		position: absolute;
		z-index: 1;
		top: 5%;
		opacity: .8;
		left: 55%;


	}

	.isEdited {
		position: absolute;
		z-index: 1;
		top: 18%;
		right: -10%;
		font-size: .6rem;
		transform: translate(50%)
	}

	.icon-wrapper {
		@include flex;
		position: absolute;
		left: 0px;
		top: 0px;
		height: 100%;
		background: rgba($dark-grey, 0.4);
		border-radius: 4px 0px 0px 4px;
	}

	.input {
		@include removeNumInputArrows;
		font-size: inherit;
		text-align: center;
		width: 100% !important;
		height: 100%;
		border-radius: 4px;
		border: 1px solid $mid-grey;
		outline: none;
	}

	&.small {
		width: 70px;

		.icon-wrapper {
			width: 20px;
		}

		.input {
			padding-left: 15px !important;
			padding-top: 0.25rem !important;
			padding-bottom: 0.25rem !important;
		}
	}

	&.medium {
		width: 70px;
		height: 27.5px;

		.icon-wrapper {
			width: 30px;
		}

		.input {
			padding-left: 30px !important;
			padding-top: 0.5rem !important;
			padding-bottom: 0.5rem !important;
		}
	}

	&.large {
		width: 90px;
		height: 35px;

		.icon-wrapper {
			width: 30px;
		}

		.input {
			padding-left: 40px !important;
			padding-top: 0.5rem !important;
			padding-bottom: 0.5rem !important;
		}
	}

	&.extra-large {
		width: 133px;
		height: 42.5px;

		.icon-wrapper {
			width: 50px;
		}

		.input {
			padding-left: 50px !important;
			padding-top: 0.5rem !important;
			padding-bottom: 0.5rem !important;
		}
	}
}