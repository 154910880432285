@import '../../../Styles/';

.root {
	.form {
		.form_container {
			padding: 0.5rem;
			@include flex(column, flex-start, flex-start);
			gap: 0.5rem;
			.label-container {
				@include flex(row, flex-start);
				.mandatory {
					color: rgb(200, 0, 0);
				}
				label {
					width: 200px;
					font-weight: 500;
					color: rgba(95, 95, 95, 0.75);
				}
				.vendor-selector {
					border: 1px solid #a2a2a2;
					border-radius: 4px;
					input {
						padding: 0.5rem;
						border-radius: 4px;
					}
				}
			}
		}
	}
}
