@import '../../../../Styles/index';

.result {
	@include flex(column, flex-start, flex-start);
	width: 100%;
	background: #a3b18a55;
	border-radius: 12px;

	.result-header {
		@include flex(row, flex-start);
		background: #588157;
		color: $white;
		padding: 1rem;
		font-size: 1.75rem;
		border-radius: 12px 12px 0 0;
		width: 100%;
		height: 5rem;
		gap: 1rem;

		.count {
			font-size: 0.8em;
		}

		img {
			width: 60px;
			height: 60px;
			border-radius: 8px;
		}
	}

	.image-grid {
		@include grid(7);
		padding: 1rem;
		justify-items: center;
		gap: 1rem;
		width: 100%;

		.load-more {
			@include flex;
			font-size: 1.25rem;
			width: 10rem;
			height: 10rem;
			border: none;
			border-radius: 4px;
			background: rgba(163, 177, 138, 0.5);
			color: #fff;
			cursor: pointer;
			transition: all 0.4s;

			&:hover {
				@include boxShadow;
				background: $dark-grey;
				color: $white;
			}

			&:disabled {
				cursor: not-allowed;
				opacity: 0.5;
			}
		}
	}
}
