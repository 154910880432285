@import '../../Styles/';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.root {
	@include flex(row, center, center);
	gap: 1rem;
    width: 100%;
	position: sticky;
	top: 92%;
	// bottom: 0%;
	margin: 1rem auto;
	height: 2rem;
	
	button {
		padding: 0 1rem;
		height: 100%;
		border: none;
		border-radius: 4px;
		background: #c4c4c4;
		color: #5f5f5f;
		cursor: pointer;
		transition: all 0.4s;
		&:hover {
			@include boxShadow;
			background: #5f5f5f;
			color: #ffffff;
		}
		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}
	.currentPage-totalPage {
		padding: 0 0.5rem;
		height: 100%;
		background-color: #c4c4c4;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #5f5f5f;
		border-radius: 4px;
		font-size: 1.25rem;

		input {
			font-size: 1.25rem;
			width: 2ch;
			height: 80%;
			border: none;
			outline: none;
			background-color: #c4c4c4;
			color: #5f5f5f;
		}
		&:hover {
			@include boxShadow;
			background: #5f5f5f;
			color: #ffffff;

			input {
				background: #5f5f5f;
				color: #ffffff;
			}
		}
	}
	@media (min-width: 1200px) {
		left: 10%;
	}
}
