@import '../../../../Styles/';

.root {
	width: 100%;
	@include flex(column, flex-start, flex-start);
	gap: 0.5rem;
	.item {
		width: 100%;
		padding: 0.5rem;
		border-radius: 20px;
		// font-size: 11px;
		cursor: pointer;
		background: #d2d2d2;
		&.active {
			background: $uy-bg;
		}

        .options-list{
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.25rem;
            .list-item{
                @include flex;
                padding: 0.25rem;
                border-radius: 20px;
                cursor: pointer;
                border: 1px solid #a1a1a1;
                &.active{
                    background: $uy-bg;
                }
                &:hover{
                    background: rgba($uy-bg, 0.5);
                }
            }
        }
	}
}
