@import '../../../Styles/';

.root {
    .header{
        width: 100%;
        padding: 0.5rem 1rem;
        @include flex(row, space-between);
        .btns-container{
            @include flex;
            gap: 0.5rem;
        }
    }
    .listItem{
        height: 3rem !important;
        &.active{
            background-color: rgba(0, 128, 0, 0.425) !important;
        }
        &.expired{
            background-color: rgba(255, 17, 0, 0.5) !important;
        }
    }
    .btns{
        @include flex(column);
        gap: .5rem;
    }
}

.edit-container{
    // width: 125px;
    @include flex(row, flex-start);
    gap: 0.5rem;
}