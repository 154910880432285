@import '../../Styles/';
.link {
	text-decoration: none;
	color: $primary;
	font-size: 1.25rem;
	font-weight: 400;
	&:hover{
		text-decoration: underline;
	}
}
