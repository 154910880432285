@import '../../Styles/';

.root {
	width: 100%;
	// padding: 1rem;
	// background: rgba($uy-ex-light-bg, 0.1);
	background: white;
	border: 2px solid $uy-bg;
	border-radius: 4px;

	.header {
		@include flex(row, space-between);
		padding: 5px;
		border-bottom: 1px solid $uy-bg;

		.page-label {
			font-weight: 500;
			font-size: 1.05rem;
		}

		.add-btn {
			@include flex;
			gap: 0.25rem;
			padding: 0.25rem 0.5rem;
			background: $uy-bg;
			font-weight: 500;
			border-radius: 20px;

			&:hover {
				background: rgba($uy-bg, 0.4);
			}
		}

		.additional-search {
			@include flex;
			gap: 0.5rem;

			.search_container {
				@include flex;
				gap: 0.5rem;
				border: 1px solid #d1d1d1;
				border-radius: 8px;

				input {
					border-radius: 8px;
					padding: 0.5rem;
					border: none;
					outline: none;
				}
			}
		}
	}

	.selected-item {
		width: 180px;
		padding: 0.20rem 0.5rem;
		border-radius: 25px;
		@include flex(column);
		gap: 0.5rem;
		// background: rgba(60, 79, 255, 0.75);
		background: $uy-bg;
		color: rgb(0, 0, 0);
		// color: #fff;
		font-weight: 500;
		@include flex(row, space-between);

		.remove {
			padding: 0;
			color: #000;
		}

	}

	.selected_store {
		font-weight: 500;
		font-size: 1rem;
		color: #000
	}

	.form {
		@include flex;

		.filters {
			width: 90%;
			@include flex(column, center, flex-start);
			gap: 1rem;
			padding: 1rem;

			.conditional_Filters {
				display: flex;
				gap: 5rem;
			}

			&-generic {
				@include flex(row, space-between);
				width: 100%;

				.inner-generic {
					@include flex;
					gap: .75rem;

					.filter {
						@include flex(column, flex-start, flex-start);
						gap: 0.5rem;
					}

					.limit-select {
						width: 5rem !important;
						height: 2rem !important;
					}

					.feature-type-select {
						width: 7.5rem !important;
						height: 2rem !important;
					}

					.imagesType {
						display: flex;
						cursor: pointer;
						gap: .5rem;
						min-width: 10rem;
						border-radius: .5rem;
						padding: .25rem .5rem;

						.dropdown {
							font-size: small;
							height: 2rem !important;
						}
					}

					.confidence {

						width: 7.5rem;
						flex-direction: column;
						gap: 0px;
						justify-content: center;
						align-items: center;

						p {
							font-size: small;
							text-align: center;
							margin-top: .1rem !important;
						}

						.defect-select {
							width: 90%;
							margin-top: .3rem !important;

						}
					}

					.egaFilter {
						&.disabled {
							opacity: .5;
						}

						width: 7.5rem;
						flex-direction: column;
						gap: 0px;
						justify-content: center;
						align-items: center;

						p {
							font-size: small;

							position: relative;
							margin-top: .1rem !important;

							.checkbox {
								position: absolute;
								left: -150%;
								top: -40%;
							}
						}

						.defect-select {
							width: 90%;
							margin-top: .3rem !important;

						}
					}

					.identification {
						.types {
							@include flex;
							gap: 0.5rem;

							&-type {
								cursor: pointer;
								border: 1px solid grey;
								border-radius: 25px;
								padding: 2px 0.5rem;

								&.selected {
									background: $uy-bg;
									border-color: #000;
									color: #000;
									font-weight: 500;
								}
							}
						}
					}
				}

				.range-container {
					@include flex(row);
					gap: 1rem;
					border: 1px solid $uy-bg;
					padding: 0.5rem;
					border-radius: 10px;
					opacity: 0.5;

					.time-select {
						width: 9.5rem;
						height: 2.5rem !important;
					}
				}

				.applied {
					opacity: 1;
				}
			}

			&-exclusive {
				width: 100%;
				@include flex(row, flex-start, flex-start);
				gap: 1rem;

				.left {
					@include flex(column, flex-start, flex-start);
					gap: 0.5rem;
				}

				.right {
					width: 100%;
					@include flex(row, flex-start, flex-start);
					padding-left: 1rem;
					border-left: 2px solid #bebebe75;
					overflow-x: auto;
					gap: 7.5rem;

					.filter {
						width: calc(100% / 4);
						min-width: calc(100% / 4);
						max-width: 250px;
						// width: 300px;
						height: 250px;
						@include flex(column, flex-start, flex-start);
						// background: red;
						border: 1px solid rgba($uy-bg, 0.4);
						border-radius: 4px;
						gap: 0.25rem;
						background: #f5f5f52d;

						&-heading {
							width: 100%;
							font-weight: 500;
							background: #408e913b;
							text-align: center;
							margin-bottom: 0.25rem;
						}

						&-body {
							width: 100%;
							height: 100%;
							overflow-y: auto;

							.tags {
								width: 100%;
								height: 80%;
								overflow-y: auto;
								@include flex(column, flex-start, flex-start);
								gap: 0.2rem;

								.tag {
									width: 100% !important;
								}
							}
						}
					}
				}
			}
		}

		// .filters {
		// 	@include flex(row, center, flex-start);
		// 	flex-basis: 90%;
		// 	margin-top: 1rem;
		// 	padding: 1rem;

		// 	.defect,
		// 	.product,
		// 	.vendor,
		// 	.productCategory {
		// 		flex-basis: 40%;
		// 	}

		// 	.filter {
		// 		@include flex(column, flex-start);
		// 		gap: 1rem;
		// 		.filter-heading {
		// 			font-size: 1rem;
		// 			text-transform: uppercase;
		// 		}
		// 		.filter-body {
		// 			@include flex;
		// 			width: 100%;
		// 			gap: 0.5rem;
		// 			padding: 1rem;

		// 			.tags {
		// 				@include flex(row, center);
		// 				gap: 0.5rem;
		// 				flex-wrap: wrap;
		// 			}

		// 			.add-button {
		// 				background: $white;
		// 				border: 1px solid $primary;

		// 				&:hover {
		// 					background: $white;
		// 				}
		// 			}
		// 		}
		// 	}

		// 	.seperator {
		// 		@include flex(column);
		// 		color: $black;
		// 		padding: 1rem;
		// 		font-size: 1.5rem;
		// 		font-weight: 500;

		// 		svg {
		// 			font-size: 2rem;
		// 		}
		// 	}
		// }
		.submit {
			@include flex(column);
			flex-basis: 10%;
			border-left: 1px solid $uy-bg;
			padding: 1rem;
			font-size: 1.25rem;
			text-transform: uppercase;

			.animate {
				animation: zoom-in-zoom-out 0.3s ease;
				animation-delay: 1s;
				animation-iteration-count: 2;
			}

			@keyframes zoom-in-zoom-out {
				0% {
					transform: scale(1, 1);
				}

				50% {
					transform: scale(1.2, 1.2);
					border: 2px solid rgb(57, 73, 0);
				}

				100% {
					transform: scale(1, 1);
				}
			}

			.btn {
				svg {
					font-size: 2.5rem;
					color: $uy-bg;
				}


			}

			.disabled {
				svg {
					color: rgba($primary, 0.4) !important;
				}
			}
		}
	}

	.footer_elements {
		@include flex(row, flex-end);
		gap: 1rem;
		width: 100%;
		border-top: 2px solid $uy-bg;
		padding: .5rem;
	}
}