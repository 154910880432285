@import '../../../Styles/index';

.root{
    width: 100%;
    overflow-x: auto;
    position: relative;

    .header{
        // width: 100%;
        @include flex(row, space-between);
        padding: 0.5rem;
        border-bottom: 1px solid $grey;
        position: fixed;
        top: 3rem;
        left: 16rem;
        right: 0;
        background: white;
        z-index: 99;
        .page-back{
            @include flex;
            gap: .5rem;
            .page-name{
                font-size: 1.25rem;
                font-weight: 400;
            }
        }
    }
    .body{
        width: 100%;
        @include flex(column, flex-start, flex-start);
        gap: 1rem;
        padding: 0.5rem;
        margin-top: 3.5rem;
        .advance{
            width: 100%;
            padding: 0.5rem 1rem;
            background: rgba(238, 190, 33, 0.25);
            border-radius: 4px;
            font-weight: 200;
        }
        .vendor-container {
            width: 100%;
            .vendor-details{
                @include flex(column, flex-start, flex-start);
                // margin: 0.5rem 0;
                padding: .5rem;
                border-radius: 4px;
                gap: 0.5rem;
                background: rgb(240,240, 240);
                .address-container{
                    @include flex(row, flex-start, flex-start);
                    .address{
                        width: 250px;
                        .address-header{
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .label-container{
            @include flex(row, flex-start);
            .mandatory{
                color: rgb(200, 0, 0);
            }
            label{
                width: 200px;
                font-weight: 500;
                color: rgba(95, 95, 95, 0.75);
            }
            .vendor-selector{
                border: 1px solid #a2a2a2;
                border-radius: 4px;
                input{
                    padding: 0.5rem;
                    border-radius: 4px;
                }
            }
        }
        .tile{
            @include flex(row, flex-start, center);
            .store-selector{
                border: 1px solid #a1a1a1;
                border-radius: 4px;
                input{
                    padding: 0.5rem;
                    border-radius: 4px;
                }
            }
            .mandatory {
                color: rgb(200, 0, 0);
            }
            label{
                width: 200px;
                text-align: left;
                max-width: 200px;
                min-width: 200px;
            }
            .order-id{
                width: 300px;
                border: 1px solid #f1f1f1;
                padding: .5rem .25rem;
                border-radius: 8px;
            }
            .btn-list-container{
                @include flex(column, flex-start, flex-start);
                gap: 0.5rem;
                .tags{
                    width: 100%;
                    flex-wrap: wrap;
                    @include flex(row, flex-start);
                    gap: 0.5rem;
                }
            }
        }
        .multi{
            @include flex(row, flex-start);
            gap: 2rem;
            .grnTile {
                .order-id{
                    border-color: $uy-bg;
                    border-width: 2px;
                }
            }
        }

        .invoice-uplaod{
            width: 250px;
            @include flex(column, center, flex-start);
            gap: 0.5rem;
            .preview{
                position: relative;
                @include flex(row, flex-start);
                gap: .5rem;
                border: 1px solid #a2a2a2;
                padding: 0.25rem 0.5rem;
                border-radius: 12px;
                &-btn{
                    cursor: pointer;
                    font-weight: 500;
                    color: rgb(0, 0, 255);
                    &:hover{
                        color: rgba(0, 0, 255, 0.5);
                    }
                }
                &-container{
                    position: absolute;
                    left: calc(100% + 10px);
                    bottom: -2.5rem;
                    width: 300px;
                    height: 400px;
                    // z-index: -1;
                    @media (max-width: '1440px') {
                        &{
                            width: 250px;
                            height: 400px;
                            bottom: -4rem;
                        }
                        iframe{
                            transform: scaleX(0.8);
                            transform: scaleY(0.7);
                        }
                    }
                    iframe{
                        // transform: scale(.8);
                        transform: scaleX(0.9);
                        transform: scaleY(0.8);
                        transform-origin: 0 100%;
                    }
                }
            }
        }
    }
    .items-details-container{
        width: 95%;
    }

}