@import '../../Styles/index';

.root{
    width: calc(50% - 0.5rem);
    // flex: 1;
    // background: ;
    border-radius: 8px;
    padding: .5rem;
    text-align: left;
    box-shadow: 0px 1px 5px #b8b8b8;
    cursor: pointer;
    .head-container{
        @include flex(row, space-between, center);
        width: 100%;
        border-bottom: 1px solid #bbbbbb77;
        .header{
            font-size: 1.25rem;
            font-weight: 500;
        }
        .date{
            margin: 0;
            font-size: 1rem;
            font-weight: 400;
        }
    }

    .duration{
        font-weight: 500;
    }
}