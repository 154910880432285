@import '../../Styles';

.root {
	color: #94a3b8;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh - 50px);
    padding: 10px;
	.warningsWrapper {
        text-align: center;
        .warningHighlighter{
            color: red;
        }
        .successHighlighter{
            color:#84cc16;
        }
		.warningText {
			font-size: 20px;
			font-weight: 500;
		}
        .successText {
			font-size: 20px;
			font-weight: 500;
            margin-top: 40px;
		}
	}
}
