@import '../../../Styles/';

.root {
	@include flex(column, flex-end, flex-end);
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 9;

	.selector-body {
		@include flex(column, flex-start, flex-start);
		gap: 0.5rem;
		visibility: hidden;
		opacity: 0;
		max-width: 0;
		max-height: 0;
		width: 300px;
		height: 400px;
		background: white;
		border-radius: 4px 4px 0 4px;
		border: 2px solid $uy-bg;
		transition: all 500ms;
		overflow-y: auto;
		padding: 0.5rem;
		&.show {
			visibility: visible;
			opacity: 1;
			max-width: 300px;
			max-height: 400px;
		}

		.level-one {
			width: 100%;
			@include flex(column);
			gap: 0.5rem;
			.filter-box {
				width: 100%;
				@include flex(row, space-between);
				background: #e2e2e2;
				padding: 0.5rem;
				border-radius: 0.25rem;
				cursor: pointer;
				.name{
					@include flex(row, flex-start, flex-start);
					gap: 1px;
					p{
						margin: 0;
					}
				}
				&.selected {
					background: $uy-bg;
				}
			}
		}

		.level-two {
			width: 100%;
			@include flex(column, flex-start, flex-start);
			.header {
				width: 100%;
				@include flex;
				border-bottom: 1px solid #e2e2e2;
				position: relative;
				margin-bottom: 0.25rem;
				.go-back {
					position: absolute;
					left: 0.25rem;
					.icon {
					}
				}
			}
		}
		.filter-container{
			width: 100%;

			.store-filter{

			}

			.list{
				width: 100%;
				@include flex(column, flex-start, flex-start);
				gap: 0.5rem;
				.item {
					width: 100%;
					padding: 0.5rem;
					border-radius: 20px;
					// font-size: 11px;
					cursor: pointer;
					background: #d2d2d2;
					&.active {
						background: $uy-bg;
					}
				}
			}

			.period-list {
				.item{
					min-height: 100px;
					background: red;
				}
			}
		}
	}

	.selector-btn {
		width: 35px;
		height: 35px;
		@include flex;
		border-radius: 50%;
		font-size: 2rem;
		font-weight: 500;
		// padding: 0.5rem;
		background: rgba($uy-bg, .7);
		transition: all 500ms;
		cursor: pointer;
		&:focus{
			background: $uy-bg;
		}
		&.visible {
			border-radius: 0 0 20px 20px;
		}
	}
	.show ~ .selector-btn {
		background: $uy-bg;
	}
}
