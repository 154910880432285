/* .button {
  padding: 5px 25px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: #4CAF50;
  border: none;
  border-radius: 25px;
  box-shadow: 0 9px #999;
}

.button:hover {background-color: #3e8e41}

.button:focus { outline: none; }

.button:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

html, body { height: 100%; padding: 0; margin: 0; }
#div0 { width: 100%; height: 107px; float: left; border-style: outset;}
#div01 { width: 33%; height: 107px; float: left; }
#div02 { width: 33%; height: 107px; float: left; text-align: center; }
#div03 { width: 33%; height: 107px; float: right; }
#div00{ width: 100%; height: 70px; float: left; border-style: outset; text-align: center; }
#div1 { width: 70%; height: 540px; float: left;}
#div11 { width: 1%; height: 0px; text-align :center;}
#div11U { margin-left:15px;width: 100%; height: 360px; }
#div11D { margin-left:15px;width: 100%; height: 180px; }
#div1m { width: 80%; height: 100%; float: right; text-align :center;  vertical-align: top;  opacity: 0.8;background-repeat: no-repeat;background-size: cover;}
#divTempUL { width: 50%; height: 270px; float: left; border-style: outset;}
#divTempUR { width: 50%; height: 270px; float: right; border-style: outset;}
#divTempD { width: 100%; height: 270px; float: right; border-style: outset;}
#div12 { width: 100%; height: 300px; float: left; }
#div2 { width: 30%; height: 270px; float: right; border-style: outset;text-align: center;}
#div22 { width: 100%; height: 1px; text-align :center;}
#div222 { height: 1px;text-align :center;}
#div23 { width: 40%; height: 270px; float: left; }
#div3 { width: 30%; height: 270px; float: left; border-style: outset;}
#div31 { width: 35%; height: 270px; float: left; }
#div32 { width: 100%; height: 270px; text-align :center;}
#div33 { width: 40%; height: 270px; float: left; }
#div321 { width: 31%; height: 110px; margin: 20px;}
#div322 {height: 110px;text-align :center;}
#div4 { width: 100%; height: 69px; float: left; border-style: outset;} 
#div5 { width: 100%; height: 69px; float: left; border-style: outset;} 
.qc-left { width: 25%; height: 40px; float: left; }
.qc-right { width: 25%; height: 40px; float: right; }
.other { font-size:25px; font-weight: bold; margin-top: 10px;}
.detail { font-size:26px; font-weight: bold; }
.measurement { font-size:80px; font-weight: bold;font-family: "Verdana"}
.result { font-size:60px; font-weight: bold;font-family: "Verdana"; }
#pass {vertical-align: top}
#pass2 {vertical-align: top ; float: right;} */

/* background-image: url("./images/meal.png"); */

/* html, body { height: 100%; padding: 0; margin: 0; }
#div0 { width: 100%; height: 87px; float: left; border-style: outset;}
#div00{ width: 100%; height: 50px; float: left; border-style: outset; text-align: center; }
#div01 {width: 33%;height: 107px; float: left; }
#div02 { width: 33%; height: 107px; float: left; text-align: center; }
#div03 { width: 33%; height: 107px; float: right; text-align: right;  }
#div00{ width: 100%; height: 70px; float: left; border-style: outset; text-align: center; }
#div1 { width: 69.6%; height: 545px; float: left; border-style: outset;}
#div11 { width: 18%; height: 545px; float: left; text-align :center; font-weight: bold; font-family: "Verdana";border-style: outset;}
#div1m { position:"absolute";width: 60%; height: 100%; float: left; text-align :center;left:"2%"}
#div12 { width: 100%; height: 300px; float: left; }
#div2 { width: 30%; height: 270px; float: left; border-style: outset;}
#div22 { width: 100%; height: 1px; text-align :center;}
#div222 { height: 1px;text-align :left;}
#div23 { width: 40%; height: 270px; float: left; }
#div3 { width: 30%; height: 275px; float: left; border-style: outset;}
#div31 { width: 35%; height: 270px; float: left; }
#div32 { width: 100%; height: 270px; text-align :center;}
#div33 { width: 40%; height: 270px; float: left; }
#div321 { width: 31%; height: 110px; margin: 20px;}
#div322 {height: 110px;text-align :left;}
#div4 { width: 100%; height: 69px; float: left; border-style: outset;}
#div5 { width: 100%; height: 69px; float: left; border-style: outset;}
.qc-left { width: 25%; height: 40px; float: left; }
.qc-right { width: 25%; height: 40px; float: right; }
.other { font-size:25px; font-weight: bold; }
.detail { font-size:26px; font-weight: bold; }
.measurement {font-size:100px; font-weight: bold;font-family: "Verdana"}
.result { font-size:40px; font-weight: bold;font-family: "Verdana"; }
#pass {vertical-align: top}
#pass2 {vertical-align: top ; float: right;} */

/* .button {
	padding: 5px 25px;
	font-size: 24px;
	text-align: center;
	cursor: pointer;
	outline: none;
	color: #fff;
	background-color: #4caf50;
	border: none;
	border-radius: 25px;
	box-shadow: 0 9px #999;
}

.button:hover {
	background-color: #3e8e41;
}

.button:focus {
	outline: none;
}

.button:active {
	background-color: #3e8e41;
	box-shadow: 0 5px #666;
	transform: translateY(4px);
} */

.table {
	border-collapse: collapse;
	width: 100%;
	border: 1px solid #ddd;
}

#heading {
	width: 100%;
	height: 70px;
	float: left;
	border-style: outset;
	text-align: center;
}

html,
body {
	padding: 0;
	margin: 0;
}

#div0 {
	width: 100%;
	height: 107px;
	float: left;
	border-style: outset;
}

#div01 {
	width: 33%;
	height: 107px;
	float: left;
}

#div02 {
	width: 33%;
	height: 107px;
	float: left;
	text-align: center;
}

#div03 {
	width: 33%;
	height: 107px;
	float: right;
	text-align: right;
}

#div00 {
	width: 100%;
	height: 50px;
	float: left;
	border-style: outset;
	text-align: center;
}

#div00 .div00-child {
	width: 33.33%;
}

#div1 {
	width: 12%;
	height: calc(100vh - 60px - 50px - 52px);
	/* max-height: 560px; */
	float: left;
	border-style: outset;
	text-align: center;
}

#div11 {
	width: 20%;
	height: 5px;
	text-align: center;
}

#div1m,
#div2m {
	width: 44%;
	height: 36vw;
	/* max-height: 440px; */
	float: right;
	text-align: center;
	border-style: outset;
	position: relative;
}

#div2m {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#div2m #product-name {
	position: relative;
	font-size: 18px;
	top: -7px;
	margin-right: 20px;
}

#div2m .is-veg-img {
	width: 30px;
	height: 30px;
}

#div12 {
	width: 100%;
	height: 300px;
	float: left;
}

#div2,
#div3 {
	width: 44%;
	height: calc(100vh - 60px - 50px - 60vh - 52px);
	float: left;
	border-style: outset;
	display: flex;
	flex-direction: column;
	/* gap: 0.75rem; */
}

#div22,
#div32 {
	width: 100%;
	text-align: center;
	position: relative;
}

#div222 {
	height: 1px;
	text-align: center;
	position: relative;
}

#div23 {
	width: 40%;
	height: 280px;
	float: left;
}

#div31 {
	width: 35%;
	height: 280px;
	float: left;
}

#div33 {
	width: 40%;
	height: 280px;
	float: left;
}

#div321 {
	width: 31%;
	height: 110px;
	margin: 20px;
}

#div322 {
	height: 1px;
	text-align: center;
	position: relative;
}

#div4 {
	width: 100%;
	height: 50px;
	float: left;
	border-style: outset;
}

#div5 {
	width: 100%;
	height: 69px;
	float: left;
	border-style: outset;
}

.qc-left {
	width: 25%;
	height: 40px;
	float: left;
	text-align: left;
}

.qc-right {
	width: 25%;
	height: 40px;
	float: right;
	text-align: right;
}

.other {
	font-size: 20px;
	font-weight: bold;
}

.detail {
	font-size: 1.5rem;
	font-weight: bold;
}

.measurement {
	font-size: 3.75rem;
	font-weight: bold;
	font-family: 'Verdana';
	display: block;
}

.measurement #other-temp {
	font-size: 40px;
	margin-left: 20px;
}

.result {
	font-size: 40px;
	font-weight: bold;
	font-family: 'Verdana';
}

.diagnosticsHeading {
	font-size: 1.5rem;
	font-family: inherit;
	color: inherit;
	font-weight: 500;
	line-height: 1.2;
}

.keyboard {
	z-index: 1;
	top: 70%;
	position: fixed;
	width: 100%;
	height: 35%;
}

.pass-img {
	width: 45px;
	height: auto;
	position: absolute;
	top: 18%;
	left: 15px;
}

.qc-center {
	text-align: center;
}

#pass2 {
	vertical-align: top;
	float: right;
}

.div6 {
	padding: 10px;
	width: 88%;
	height: calc(100% - 91px);
	top: 91px;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(226px, 1fr));
	grid-gap: 4px;
	grid-auto-rows: 170px;
}

#div7 {
	width: 12%;
	height: 627px;
	float: left;
	border-style: outset;
	text-align: center;
}

.appendMovingDots:after {
	content: ' .';
	animation: dots 3s steps(1, end) infinite;
}

@keyframes dots {
	0%,
	20% {
		opacity: 0;
	}
	40% {
		opacity: 1;
	}
	60% {
		text-shadow: 0.5em 0;
	}
	80%,
	100% {
		text-shadow: 0.5em 0, 1em 0;
	}
}

#S00 {
	position: fixed;
	width: 20%;
	height: 50%;
	top: 47%;
	float: right;
	right: 2%;
}

#S01 {
	position: absolute;
	width: 100%;
	height: 10%;
	top: 90%;
}

#S02 {
	position: relative;
	width: 70%;
	height: 100%;
	padding: 5px;
	padding-top: 10px;
	float: left;
}

#S03 {
	position: relative;
	width: 30%;
	height: 100%;
	padding: 5px;
	float: right;
}

#homepage {
	width: 75%;
	height: calc(100% - 91px);
	min-height: 627px;
	float: left;
	border-style: outset;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 10px;
	grid-auto-rows: minmax(100px, auto);
}

#liveQC-parent {
	/* max-height: calc(100vh - 70px); */
	width: 100%;
}

.qc-back-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: rgb(43, 101, 236);
	margin-top: 0px;
	margin-left: 10px;
	border-radius: 2em;
	font-size: 18px;
	padding: 6px 8px !important;
}

.go-live-btn {
	background-color: #ff2d2d;
	color: white;
	border: none;
	font-weight: 500;
	width: 75px;
	padding: 5px 0px;
	margin-right: 10px;
}

#div2 .detail,
#div3 .detail {
	flex: 1 1 auto;
	font-size: 1.25rem;
}

#div1 .brand-img {
	max-height: 110px;
	width: 110px;
	height: auto;
	width: 100%;
	margin: 10px auto;
}

#div1 .user-img {
	max-height: 160px;
	width: 100%;
	height: auto;
}

#div1 p {
	margin: 5px auto;
}

#div1m .food-image {
	height: calc(100% + 120px);
	clip-path: inset(120px 0px 0px 0px);
	margin-top: -120px;
	max-width: 100%;
}

#div2m .reference-img {
	width: 100%;
	max-height: 88%;
}

#div00 {
	display: flex;
	align-items: center;
}

#div00 .cf {
	display: flex;
	justify-content: space-around;
	flex: 2 2 auto;
	align-items: center;
}

#div00 .cf .stat-name {
	font-weight: 600;
	font-size: 13px;
}

#div00 .cf .stat-value {
	font-size: 13px;
}

.live-text {
	color: red;
	font-weight: bold;
	text-align: center;
	font-size: 16px;
	margin-top: 5px;
	margin-bottom: 5px;
}

.time-stamp {
	position: absolute;
	top: 0px;
	z-index: 1;
	font-size: 20px;
}

.time-stamp.success {
	color: #21da21;
}

.time-stamp.fail {
	color: #ff2d2d;
}

.time-stamp-left {
	left: 8%;
}

.time-stamp-right {
	right: 8%;
}

.offline-btn {
	position: relative;
	top: 0;
	max-height: 30px;
	max-width: 80px;
	margin: 0 auto;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	border-radius: 8px;
	color: white;
	font-weight: 600;
	padding: 5px !important;
}

#div1 .offline-btn p.offline-txt {
	display: inline-block;
	margin: 5px 0px;
}

.offline-btn svg.offline-icon {
	width: 30px;
	height: 30px;
}

.offline-btn.online {
	background-color: #2ecc71;
}

.offline-btn.offline {
	background-color: #b53f35;
}

.btn {
	padding: 5px 0px;
	font-size: 20px;
}

#div00.notLiveQC .cf > div {
	display: none;
}

#div00 .go-live-btn {
	display: none;
}

#div00.notLiveQC .go-live-btn {
	display: block;
}

/* 
#div00.liveQC */

#div00 #past-orders-heading {
	display: none;
}

#div00 #lastQCtime {
	display: block;
}

#div00.notLiveQC #orderID {
	display: flex;
	justify-content: center;
}

#div00 #lastQCtime,
#div00.notLiveQC #past-orders-heading,
#div00 #orderID #orderID-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

#div00.notLiveQC .cf > div {
	flex: 1 1 auto;
}

#div00 #orderID .nav-btn {
	display: none;
	border: none;
	background: none;
	width: 50px;
}

#div00.notLiveQC #orderID .nav-btn {
	display: block;
	outline: none;
}

#div00.notLiveQC #orderID .nav-btn:hover {
	cursor: pointer;
	fill: turquoise;
}

#div00.notLiveQC ~ #div1 .live-text {
	display: none;
}

#div00.notLiveQC ~ #div1 .offline-btn {
	display: none;
}

.min-max-container {
	height: 30px;
}

.offline-warning .min-max-container .qc-left,
.offline-warning .min-max-container .qc-right {
	display: none;
}

.offline-warning img {
	content: url('../../../Images/alert.png');
	left: 50%;
	transform: translateX(-50%);
}

#div1m.offline-warning img.food-image {
	content: url('../../../Images/alert_camera.png');
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 70%;
	height: auto;
	clip-path: inset(0px);
}

.offline-warning .measurement,
.offline-warning .time-stamp {
	display: none;
}

.filter-container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.filter-container.selected-filter {
	background-color: green;
}

.filter-container .filter-check {
	width: 30px;
	height: 30px;
	border: 1px solid;
}

.filter-container .filter-label {
	width: calc(100% - 60px);
}

.filter-container .filter-expand {
	width: 30px;
	height: 30px;
	border: 1px solid red;
}

.filter-container .filter-accordion {
	width: 100%;
}

tr.sticky-header th {
	top: 0;
	position: sticky;
	background-color: #fff;
	text-align: center;
	padding: 10px 10px 10px 0px;
}

#inventoryList table {
	font-size: 16px;
}

.btn {
	border: none;
	outline: none;
	padding: 10px 15px;
	cursor: pointer;
	font-size: 0.9em;
}

.btn-round {
	border-radius: 50px;
}

.btn-blue {
	color: rgb(255, 255, 255);
	background: rgb(43, 101, 236);
}

.btn-dark-blue {
	color: rgb(255, 255, 255);
	background: #00449e;
}

.divmain0000 {
	/* height: calc(100% - 70px); */
	height: 97%;
	width: 100%;
	/* position: absolute; */
	/* top: 70px; */
	margin-top: -20px;
	border-style: outset;
}

.divmain0100 {
	height: 100%;
	width: 74.5%;
	/* position: absolute; */
	overflow: none;
}

.divmain0110 {
	height: 32%;
	width: 96%;
	opacity: 0.95;
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	justify-content: space-between;
}

.divmain0111 {
	background-color: white;
	height: 43%;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px;
	border: 4px solid rgb(255, 255, 255);
	width: 16%;
}

.divmain0111-header .header-img-container {
	width: 60%;
	height: 100%;
	margin: 0 auto;
	position: relative;
}

.divmain0111-header .header-img-container .header-img {
	opacity: 0.4;
	margin: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: 100%;
}

.divmain0111 .pass-percent {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: bold;
	font-size: 2rem;
}

.divmain0111-header {
	height: 80%;
	background-color: white;
}

.divmain0111-footer {
	height: 20%;
	background-color: #f0f2f4;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.divmain0120 {
	height: 63.6%;
	width: 100%;
	/* position: absolute; */
	top: 37%;
}

.divmain0121 {
	background-color: white;
	height: 98%;
	width: 98%;
	/* position: absolute; */
	top: 1%;
	/* left: 1%; */
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
	border-radius: 0px;
	border: 1% solid rgb(255, 255, 255);
}

.divmain0130 {
	height: 6%;
	width: 99%;
	/* position: absolute; */
	left: 0.5%;
	top: 93.7%;
	opacity: 1;
	border: 1px solid #555;
	box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.divmain0130 a {
	float: left;
	color: white;
	text-decoration: none;
	font-size: 35px;
	width: 33.333%;
	height: 100%;
	text-align: center;
	background-color: #555;
	display: flex;
	justify-content: center;
	align-items: center;
}

.divmain0130 a:hover {
	background-color: #000;
}

.divmain0130 a.active {
	background-color: #4caf50;
}

.divmain0200 {
	height: 100%;
	width: 25%;
	/* position: absolute; */
	left: 74.5%;
}

.divmain0220 {
	background-color: white;
	height: 45%;
	width: 100%;
	position: absolute;
	/* left:1%; */
	top: 26%;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px;
	border: 1px solid rgb(255, 255, 255);
}

.divmain0230 {
	position: absolute;
	height: 27%;
	width: 100%;
	box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
	border-radius: 5px;
	border: 1px solid rgb(255, 255, 255);
	top: 72%;
}

/* 
.divmain0211 span {
  padding-top: 0%; 
  position: relative; 
  left: 17%; 
  font-size: 1.5vw; 
  border-radius: 5px; 
  top: 25%;
} */

.analysis-header {
	height: 25px;
	text-align: center;
	font-weight: bold;
}

.divmain0212 {
	height: 84.4%;
	/* display: grid;  
        grid-template-columns: auto auto  ;
        grid-gap:2px;
        grid-auto-rows:auto; 
        overflow-y:auto;  */
}

.divmain0230 .divmain0212 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.card {
	height: 100%;
	width: 100%;
	border: ghostwhite;
	background-color: ghostwhite;
}

.card2 {
	height: 100%;
	width: 100%;
	border: ghostwhite;
	background-color: ghostwhite;
}

.brandsPopup {
	display: grid;
	height: 84.4%;
	grid-template-columns: 33% 33% 33%;
	grid-gap: 2px;
	grid-auto-rows: auto;
	overflow-y: auto;
}

th {
	text-align: center;
}

.qc-select-store {
	width: 100px;
	font-size: 13px;
}

@media screen and (max-width: 1180px) {
	.detail {
		font-size: 24px;
	}
	#div1m .food-image {
		height: calc(100% + 110px);
		clip-path: inset(110px 0px 0px 0px);
		margin-top: -110px;
	}
}

@media screen and (max-width: 1080px) {
	.detail {
		font-size: 21px;
	}
	.time-stamp {
		font-size: 16px;
	}
	.measurement {
		margin-top: 10px;
		font-size: 55px;
	}
	.measurement #other-temp {
		font-size: 30px;
	}
	.btn {
		font-size: 18px;
	}
	#div2m #product-name {
		font-size: 16px;
	}

	#div1m .food-image {
		height: calc(100% + 100px);
		clip-path: inset(100px 0px 0px 0px);
		margin-top: -100px;
	}
}

@media screen and (max-width: 991px) {
	#div1m .food-image {
		height: calc(100% + 80px);
		clip-path: inset(80px 0px 0px 0px);
		margin-top: -80px;
	}

	.detail {
		font-size: 20px;
	}
	.live-text {
		font-size: 14px;
	}
	.measurement {
		margin-top: 20px;
		font-size: 45px;
	}
	.measurement #other-temp {
		font-size: 20px;
	}
	.pass-img {
		top: 30px;
		width: 50px;
		height: 50px;
	}
	#div2 .detail,
	#div3 .detail {
		font-size: 16px;
	}
	.time-stamp {
		font-size: 14px;
	}
	#div1 p {
		font-size: 12px;
	}
	.btn {
		font-size: 14px;
	}
	#div2m #product-name {
		font-size: 14px;
	}
	#div00 .cf .stat-name {
		font-size: 10px;
		font-weight: 600;
	}

	#div00 .cf .stat-value {
		font-size: 10px;
	}

	#div00 .div00-child {
		font-size: 12px;
	}

	#inventoryList table {
		font-size: 13px;
	}

	.divmain0111 {
		font-size: 10px;
	}
}

@media screen and (max-width: 768px) {
	#div2 .detail,
	#div3 .detail {
		font-size: 10px;
	}
	.measurement {
		margin-top: 35px;
		font-size: 30px;
	}
	.measurement #other-temp {
		font-size: 18px;
		display: block;
	}
	.pass-img {
		top: 40px;
		width: 25px;
		left: 10px;
		height: 25px;
	}
	#div2m #product-name {
		font-size: 12px;
	}
	#div2m .is-veg-img {
		width: 15px;
		height: 15px;
	}
	.btn {
		font-size: 10px;
	}
	.divmain0111 .pass-percent {
		font-size: 1.275rem;
	}
	.divmain0130 a {
		font-size: 25px;
	}
	.liveqc {
		width: 10px;
		height: 10px;
	}
	.analysis-header {
		height: 15px;
		font-size: 12px;
	}

	.qc-select-store {
		font-size: 10px;
	}
}

@media screen and (max-width: 768px) {
	.divmain0111 .pass-percent {
		font-size: 1rem;
	}

	#div1m .food-image {
		height: calc(100% + 60px);
		clip-path: inset(60px 0px 0px 0px);
		margin-top: -60px;
	}
}

/* * {margin: 0;padding: 0;height:100%;width:100%;} */

/* 
.divheader00{background-color:#f5f5f5;position:absolute;height:7%;width:100%;}
.divheader01{position:absolute;top:2.5%;left:1%;height:95%;width:13%;float:left;}
.divheader02{position:absolute;top:10%;right:0.4%;height:80%;width:8%;left:46%}
.divheader03{position:absolute;float:right;top:2.5%;right:0.4%;height:95%;width:18%}

.divsidebar00{background-color:silver;position:absolute;height:93%;width:4%;top:7%}

.divsidebar01{position:absolute;width:100%;padding-top:50%;height:5%;background-color:silver}
.divsidebar01 i{position: relative;height:0%;background-color:#f5f5f5;left:20%;transition:0.1s;transition-property: color; }
.divsidebar01 i:hover{color:#19B3D3} */
