@import '../../../../Styles';

.root {
	@include flex(row, flex-start);
	// min-width: 75px;
	// width: auto;
	width: fit-content;
	// height: 30px;
	background: $dark-grey;
	color: $mid-grey;
	margin-right: 1rem;
	border-radius: 4px;
	padding: 0.25rem 0.75rem;
	overflow: hidden;

	&.active {
		background: $uy-bg;
		color: $black;
	}

	.drag-handle {
		@include flex;
		cursor: move;
		position: relative;
		margin-left: -0.5rem;
		&.hide {
			display: none;
		}
	}

	.text {
		@include flex(row, flex-start);
		position: relative;
		width: 100%;
		height: 30px;
		cursor: pointer;
		white-space: nowrap;
	}
}
