@import '../../../../Styles/';

.root {
	position: relative;
	width: 100%;
	.clock {
		position: absolute;
		right: 0.5rem;
		cursor: pointer;
		&:hover {
			color: $pastel-red;
		}
	}
	.discountPrice {
		text-decoration: line-through;
		margin-bottom: 0.5rem;
	}
	width: 100%;
	.discount {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.dis {
			max-width: 40px;
			input {
				text-align: right;
			}
		}
		margin-bottom: 0.5rem;
	}
	.price {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		.price-wrapper {
			@include flex(column-reverse, center, flex-end);
		}
		.actual-price {
			font-size: 0.75rem;
			text-decoration-color: $pastel-red;
			text-decoration: line-through;
		}
	}
	.discounted-price {
	}
	.isAddOn {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.slider {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 0.5rem;
		padding: 0rem 5px;
	}
}
