@import '../../../../Styles';

.root {
	@include flex(row);
	width: 100%;
	height: 100px;
	background: rgba($mid-grey, 0.4);
	color: $dark-grey;
	border: 1px solid $mid-grey;
	border-radius: 4px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.4s;

	&:hover {
		color: $mid-grey;
		background: $dark-grey;
	}

	.add-icon {
		font-size: 1.25rem;
        margin-right: 0.5rem;
	}
}
