@import '../../Styles/';

.root {
	@include flex(column, space-between, flex-start);
	// height: 200px;
	flex-wrap: wrap;
	background: $uy-ex-light-bg;
	border-radius: 10px;
	border: 2px solid $uy-bg;
	width: 100%;
	padding: 8px 13px;
	position: relative;
	transition-duration: 2s;
	p {
		// border-bottom: 1px solid #FFB703;
		width: auto;
		margin: 8px 0px;
	}

	.header {
		width: 100%;
		@include flex(row, space-between);
		padding: 0.25rem 0;
		.headerButtons {
			display: flex;
			flex-direction: row;
			gap: 0.25rem;
			.add-btn {
				border: 1px solid black;
				border-radius: 8px;
				padding: 6px;
				@include flex;
				gap: 0.25rem;
				background: $uy-bg;
				font-weight: 500;
				&:hover {
					background: rgba($uy-bg, 0.4);
				}
			}
		}
	}

	.body {
		width: 100%;
		@include flex(row, space-between, flex-start);
		padding-top: 0.5rem;
		padding-bottom: 6rem;


		.find-combine-container {
			@include flex(column);
			gap: 1rem;
			height: 100%;
			position: absolute;
			// right: 130px;
			bottom: 0rem;
            right: 1rem;

			.animate {
				animation: zoom-in-zoom-out 0.3s ease;
				animation-delay: 1s;
				animation-iteration-count: 2;
			}

			@keyframes zoom-in-zoom-out {
				0% {
					transform: scale(1, 1);
				}

				50% {
					transform: scale(1.2, 1.2);
					border: 2px solid rgb(57, 73, 0);
				}

				100% {
					transform: scale(1, 1);
				}
			}
			.search-icon {
				width: 50px;
				height: 50px;
				background: $uy-bg;
				border-radius: 50%;
				@include flex;
				cursor: pointer;

				.icon {
					color: #fff;
				}
			}

			.combine-po {
				@include flex;
				color: #fff;
				padding: 0.25rem 0.5rem;
				border-radius: 4px;
				background: $uy-bg;
				cursor: pointer;
			}
		}
	}

	.storeDiv {
		display: flex;
		flex-direction: column;
	}
	.storeProcess {
		top: 71px;
		position: relative;
		right: 202px;
		display: flex;
		flex-direction: column;
	}
	.pp-filter {
		margin-right: 58px;

		/* opacity: 1; */
		padding-left: 14px;
		border-radius: 14px;
		width: 24%;
		position: relative;
		right: 175px;
		// @include flex(column, flex-start, flex-start);
		gap: 0.25rem;
		&-container {
			@include flex(column, flex-start, flex-start);
			gap: 0.5rem;
			.select {
				width: 210px;
				height: 35px;
				margin-top: 3px;
				border-radius: 8px;
				background: white;
			}
			.duration {
				// @include flex;
				gap: 0.5rem;
				.dur-type {
					@include flex;
					gap: 0.5rem;
					padding: 0 0.5rem;
					border-radius: 8px;
					input:checked {
						background: red;
					}
					p {
						font-size: 0.9rem !important;
					}
				}
			}
		}
	}

	.store-select {
		min-width: 150px;
		width: 200px;
		height: 35px;
		border-radius: 8px;
		margin-top: 7px;
		background: white;
	}

	.status-select {
		min-width: 150px;

		.select {
			width: 100%;
		}
	}

	.range-container {
		@include flex(row);
		gap: 1rem;
		padding: 0.5rem;
		border-radius: 4px;
		position: relative;
		right: 214px;
		border: 1px solid #d9d9d9;
		height: 63px;
		background: #fffee0;
		border-radius: 8px;
		.disabled {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(189, 189, 189, 0.75);
			z-index: 99;
		}
		.time-select {
			border: 1px solid white;
			background: white;
			width: 12.5rem;
			height: 2.5rem !important;
		}
	}

	.filter {
		@include flex(column, flex-start);

		.filter-heading {
			// width: 100%;
			// text-align: center;
			// padding: .5rem;
			// border-top: 1px solid black;
			// border-bottom: 1px solid black;
			font-size: 1rem;
			text-transform: uppercase;
		}

		.filter-body {
			@include flex;
			flex-direction: column;
			width: 100%;
			gap: 0.5rem;
			padding: 1rem;

			.tags {
				@include flex(row, center);
				gap: 0.5rem;
				flex-wrap: wrap;
			}

			.add-button {
				background: $white;
				border: 1px solid $primary;

				&:hover {
					background: $white;
				}
			}
		}
	}

	.seperator {
		@include flex(column);
		color: $black;
		padding: 1rem;
		font-size: 1.5rem;
		font-weight: 500;

		svg {
			font-size: 2rem;
		}
	}

	p {
		.selectedVendor {
			font-size: smaller;
			display: flex;
			max-width: 200px;
			column-gap: 0.5rem;
			row-gap: 0.2rem;
			flex-wrap: wrap;
			div {
				background-color: rgba(4, 0, 255, 0.164);
				padding: 0.1rem 0.3rem;
				border-radius: 5px;
			}
		}

		font-size: 1.2rem;
		font-weight: 500;

		span {
			font-size: 0.75rem;
			border-radius: 10px;
			padding: 0.25rem 0.35rem;
			background-color: $primary;
			color: #fff;
			margin-right: 0.25rem;
		}
	}

	&.hide {
		// height: 200px;
	}

	.hide-icon-container {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		@include flex(column);
		cursor: pointer;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
		background: #fff;
		box-shadow: 0 5px 20px #c2d2c2;

		.icon {
			font-size: 1.25rem;
		}
	}
}
