@import '../../../../Styles/index';

.root {
	.tags {
		@include flex(row, flex-start);
		flex-wrap: wrap;
		max-width: 100%;
		gap: 1rem;
	}

	.add-tag {
		@include flex;
		gap: 0.5rem;
	}

	.choose_img_wrapper{
		@include flex(column, flex-start, flex-start);
		.img_tag{
			@include flex;
			cursor: pointer;
			width: 200px;
			max-width: 100%;
			background: $primary;
			color: $white;
			padding: 0.75rem;
			margin: 1rem 0px;
			border-radius: 4px;
			text-transform: uppercase;
		}
	
		img {
			@include flex;
			width: 200px;
			height: 200px;
		}
	}
}
