
    .header {
        display: inline-flex;
        // padding-bottom: 5px;
        font-weight: 500;
        font-size: 1.1rem;
        padding: 0px 0px 5px 0px;
        .tooltipIcon{
            position: relative;
            left: 5px;
            font-size: small;
        }
    }

