@import '../../../../../Styles';

.root {
	@include flex(row, flex-start);
	background-color: $very-dark-grey;
	border-radius: 4px;
	height: 50px;
	padding: 0.5rem 1rem;
	margin-bottom: 1.275rem;
	overflow-x: auto;

	@include customScrollBar;
}
