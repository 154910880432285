@import '../../../Styles/index';

.chart-container {
	border-radius: 8px;
	// height: 200px;
	background: #eff5f5;
	@include boxShadow;
	padding: .5rem .5rem 1rem .5rem;
	@include flex(column);
	position: relative;
	height: 100%;
	.unitx {
		position: absolute;
		bottom:.7rem;
		right: 46%;
		transform: translate(50%,50%);
		font-size: 0.9rem;
		font-weight: 600;
		border-radius: 1rem;
	}
	.unity {
		position: absolute;
		top: 1rem;
		left: 1.5rem;
		font-size: 0.9rem;
		font-weight: 600;
		border-radius: 1rem;
	}
	p {
		font-size: 1.5rem;
		font-weight: 500;
	}
	.title{
		@include flex;
		gap: .25rem;
	}
	.chart {
		height: 90% !important;
		width: 100% !important;
	}
}
