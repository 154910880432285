.root {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}
.root .time-selector {
  height: 100%;
}
.root .time-selector .slot-label {
  text-align: left;
  margin-bottom: 0.25rem;
}
.root .time-selector .time-wrapper {
  margin: 3rem 0 1rem 0;
}
.root .time-selector .time-wrapper .time-picker {
  margin-top: 0.5rem;
}
.root .time-selector .time-wrapper .add-btn {
  width: 100%;
  background-color: #4a70fb;
  color: white;
  margin-top: 0.5rem;
  padding: 8px 0;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.root .time-selector .time-wrapper .add-btn:disabled {
  background-color: #c4c4c4;
  color: black;
}
.root .time-selector .time-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  background: #FFB703;
  border-radius: 5px;
  height: 35px;
  margin-bottom: 0.5rem;
  width: 100%;
  border-color: #eeeeee;
  position: relative;
}
.root .time-selector .time-container .heading {
  width: 100%;
  text-align: center;
}
.root .time-selector .time-container .delete-icon {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  font-size: 1rem;
  cursor: pointer;
}
.root .time-selector .time-container .delete-icon:hover {
  color: #ff6961;
}
.root .date-selector {
  height: 100%;
  width: calc(280px + 4.2rem + 1rem);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.root .date-selector .dates-wrapper {
  margin-top: 3rem;
}
.root .date-selector .no-filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
  gap: 0.5rem;
}
.root .days-selector {
  height: 100%;
  width: 33.3333333333%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.root .days-selector .days-container {
  width: 100%;
}
.root .days-selector .days-container .days-wrapper {
  width: 100%;
  margin: 3rem 0 0 0;
}
.root .days-selector .no-filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
  gap: 0.5rem;
}
.root .divider {
  width: 1px;
  height: 100%;
}/*# sourceMappingURL=TimeShowModal.module.css.map */