@import '../../../Styles/';

.selector{
    padding: 0 .5rem;
    
    .list {
        width: 70%;
        min-width: 50%;
        border: 1px solid #aaa;
        padding: .5rem;
        &.INTERMEDIATE{
            background: #FBF1D3;
            border-radius: 0 5px 5px 5px;
        }
        &.INGREDIENT{
            background: #D3FAC7;
            border-radius: 5px;
        }
    }
    
    .tabs {
        width: 100%;
        @include flex(row, flex-start);
        margin-top: 1rem;
        .tab {
            color: black;
            opacity: 0.7;
            // &.INTERMEDIATE{
            // }
            // &.INGREDIENT{
            // 	background: #2a9d8f;
            // }
    
            &.selected {
                font-weight: 500;
                opacity: 1;
            }
        }
    }
}
