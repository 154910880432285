@import '../../../Styles/index';

.root {
    .header{
        @include flex(row, space-between);
        padding: 0.5rem;
        .input-container{
            width: 70%;
            gap: 2rem;
            @include flex(row, space-between);

            .btn {
                &:disabled {
                    svg {
                        color: rgba($primary, 0.4);
                    }
                }
        
                svg {
                    font-size: 2.5rem;
                    color: $primary;
                }
            }
        }
    }
}
