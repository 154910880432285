@import '../../../Styles';

.header {
	@include flex(row, space-between);
	background: #f1f1f1;
	border-radius: 6px;
	p {
		padding: 6px 12px;
		font-size: 1.25rem;
		font-weight: 500;
		color: #000;
		margin: 0;
	}
}

.items-list {
	width: 100%;
	@include flex(column);
	padding: 6px 12px;
	overflow: auto;
	.item {
		width: 100%;
		@include flex(row, space-between);
		border-bottom: 1px solid #d5d5d5;
		.name{
			max-width: 200px;
		}
		p {
			font-size: 1.25rem;
			margin: 0;
		}
	}
}

.fields{
	@include flex(column);
	gap: .5rem;
	.field {
		width: 100%;
		border-bottom: 1px solid #dedede;
		// height: 100%;
		// padding: .25rem 0;
		min-height: 30px;
		&.redirect_container{
			padding: 0
		}
		.redirect{
			@include flex;
			gap: .5rem;
			cursor: pointer;
			img{
				width: 30px;
				height: 30px;
				// border-radius: 25px;
			}
			&:hover{
				color: rgb(68, 68, 255);
			}
		}
	}
}