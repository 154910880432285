@import '../../../Styles';

.root {
	position: relative;
	width: max-content;
	max-width: 100%;
	max-height: 100%;
	.map {
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
	}

	.marker {
		@include absolute(calc(50% - (3rem / 2) + 2px));
		width: 3rem;
		height: 3rem;
		opacity: 1;
		color: #f24242;
	}
}
