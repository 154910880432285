@import '../../../../Styles/';

.list-table-root {
	width: 100%;
	border-collapse: collapse;

	.icon-action {
		&:hover {
			cursor: pointer;
		}
	}

	thead {
		tr {
			background: $mid-grey;
		}

		.time-div {
			width: 230px;

			.time-slot {
				width: 5rem;
			}
		}

		th {
			text-transform: uppercase;
			font-weight: 400;
			text-align: center;
			padding: 0.75rem 1.5rem;
		}
	}

	tbody {
		width: 100%;

		tr {
			border-bottom: 1px solid $mid-grey;
		}

		td {
			padding: .2rem .3rem;
			text-align: center;
			width: 100px;

			.time-div {
				width: 230px;

				.time-slot {
					width: 5rem;
				}
			}
		}
	}

	.action-item {
		width: 10px;
		text-align: center;

		.action-btn {
			font-size: 1.25rem;
			cursor: pointer;
			transition: color 0.375s;

			&:hover {
				&.clone {
					color: $success;
				}

				&.view {
					color: $primary;
				}

				&.delete {
					color: $pastel-red;
				}
			}
		}
	}
}