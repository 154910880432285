@import '../../Styles/index';

.root {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.header {
		width: 100%;
		@include flex(row, space-between);
		padding: 1rem 1.5rem;
		border: 1px solid black;
		position: relative;

		.buttons {
			position: absolute;
			bottom: 0px;
			right: 1rem;
		}

		.heading {
			font-size: x-large;
			font-weight: 500;
		}

		.mappingTypes{
			.helperLabel{
				color: red;
				font-weight: 500;
			}
		}
	}

	.tabs {
		width: 100%;
		margin-top: .5rem;
		padding: .2rem 1rem;
		@include flex(row, space-between);
		position: relative;

		.tab {
			display: flex;
			gap: 1rem;
			padding: .1rem .5rem;
			font-weight: 500;
		}

		.selector {
			width: 50%;
			gap: 1rem;
			padding: .1rem .5rem;
			font-weight: 500;
			@include flex; gap: 0.5rem;
			align-items: center;

			.store-selector{
				position: relative;
				@include flex(column, flex-start, flex-start);
				.warning{
					color: red;
					font-weight: 500;
					position: absolute;
					top: 105%;
				}
			}
			.store-type-selector{
				width: 100%;
				position: relative;
				@include flex(column, flex-start, flex-start);
				.warning{
					color: red;
					font-weight: 500;
					position: absolute;
					top: 105%;
				}
			}

			.pro-wise-map{
				width: 125px;
				text-decoration: underline;
				color: blue;
				cursor: pointer;
			}
		}

		.buttons {}
	}

	.container {
		padding:.1rem 1rem;


	}
}