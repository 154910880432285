@import '../../Styles';

.root {
	padding: 1rem;
	.text-wrapper {
		@include flex(row, flex-start, center);
		.checkbox {
			margin-bottom: 1rem;
			margin-right: 0.5rem;
		}

		.text-container {
			padding-bottom: 0.5rem;
			margin-bottom: 1.25rem;
			border-bottom: 1px solid $mid-grey;
			width: 100%;

			.section-name {
				text-transform: uppercase;
				color: $dark-grey;
			}

			.help-icon {
				width: 1rem;
				margin-left: 0.25rem;
			}
		}
	}
}
