@import '../../../../Styles/index';

.root {
	width: 100%;
	height: 100%;
	.header {
		border-bottom: 1px solid rgba(151, 151, 151, 0.5);
		padding: 1rem;
		p {
			font-size: 1.5rem;
		}
	}

	.summary-data-selector {
		width: 100%;
		@include flex(row);
		gap: 0.5rem;
		margin: 0.5rem 0;

		.option {
			font-size: 0.8rem;
			text-transform: uppercase;
			font-weight: 500;
			padding: 0.5rem 0.75rem;
			border-radius: 0.5rem;
			border: 1px solid #7a7a7a70;
			color: #00000088;
			background-color: #6b6b6b3f;
			cursor: pointer;
			transition: all 0.5s ease-in-out;

			&.selected {
				background: #227c703f;
				color: #227c70;
				border-color: #227c70;
			}
		}
	}

	.summary-data {
		width: 100%;
		height: 100%;
		.table {
			animation-duration: 1s;
			animation-fill-mode: both;
			background-color: #e6ddc4;
			width: 95%;
			padding: 1.5rem 1rem;
			margin: 0 auto;
			border-radius: 20px;
			animation-name: fadeInBottom;

			// &.fade-in {
			// }

			table {
				width: 100%;
				text-align: center;

				th:nth-child(1),
				td:nth-child(1) {
					// background-color: red;
					text-align: left;
				}
				thead {
					th {
						border-bottom: 1px solid #227c70;
						padding-bottom: 0.5rem;
					}
				}
				tbody {
					tr {
						line-height: 30px;
						td:nth-child(1) {
							@include flex;
							gap: 0.5rem;
						}
						td {
							border-bottom: 1px solid #227c70;
						}
					}
				}
				.sku-img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					border: 1px solid #227c70;
					padding: 0.1rem;
				}
			}
		}
	}
}

@keyframes fadeInBottom {
	from {
		opacity: 0;
		transform: translateY(100%);
	}
	to {
		opacity: 1;
	}
}
