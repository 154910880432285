.root {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
  padding: 1rem;
}
.root .image-results {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
}
.root .fallback {
  width: 100%;
  padding: 1rem;
}
.root .numbers {
  display: flex;
  width: 100%;
  padding: 0.2rem 0.1rem;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.root .numbers .element {
  border-radius: 0.5rem;
  padding: 0.1rem 1rem;
  background: #0F3D3E;
  color: white;
  display: flex;
  align-items: center;
}
.root .numbers .element span {
  width: 30px;
  text-align: center;
  font-weight: 800;
}/*# sourceMappingURL=AiOutwardingImagesPage.module.css.map */