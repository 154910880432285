@import '../../../Styles/';

.root{
    width: 100%;
    height: 100%;
    @include sm{
        @include flex(column, flex-start, flex-start);
        gap: 0.5rem;
    }
    @include md{
        display: grid;
        gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
        // background: red;
    }
    @include lg{
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }


    .chart-container{
        width: 100%;
        height: 200px;
         @include mlg{
            height: 100%;
        }
    }
}