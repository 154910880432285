@import '../../../Styles/';

.root {
    .density{
        @include flex(row, flex-start, flex-start);
        gap: .25rem;
        .input, .unit-selector{
            width: 75px;
            height: 25px;
        }
        .unit-selector{
            padding: 2px 0 !important;
        }
        p{
            margin: 0;
        }
    }
}
