@import '../../../../Styles';

.root {
	@include flex(column, flex-start,flex-start);
	background-color: $very-dark-grey;
	width: 100%;
	max-width: 200px;
	border-radius: 4px;
	height: auto;
	padding: 1rem;
	margin-bottom: 1.275rem;
	overflow-x: auto;

	@include customScrollBar;
}
