@import '../../../Styles';
.root {
	@include flex(column, flex-start, stretch);
	gap: 1rem;
	padding: 1rem;

	.btn {
		@include flex;
		gap: 0.25rem;
		padding: 0.25rem 0.75rem;
		background: $uy-bg;
		border-radius: 25rem;
		@include boxShadow;
	}

	.header {
		@include flex(row, space-between);
		padding: 0.5rem;
		border: 1px solid $uy-bg;
		border-radius: 12px;
		.heading {
			font-size: 1.25rem;
			font-weight: 500;
		}
	}

	.item-center{
		width: 100%;
		@include flex;
		.custom-input{
			width: 100%;
			padding: .25rem;
			border: 2px solid rgba($uy-bg, 0.75);
			outline: none;
		}
	}
}

.label-container {
	.popover{
		.categories_list {
			@include flex(column);
			gap: 0.5rem;
		}
	}
}

.items_list {
	width: 100%;
	@include flex(column);
	gap: 0.25rem;
	margin-top: .5rem;
	.list{
		width: 100%;
		padding: 0.25;

		&:hover {
			background: rgba($uy-bg, 0.75);
		}
	}
}