@import '../../Styles/';
.root {
	padding: 1rem;
	position: sticky;
	top: 60px;
	background: $white;
	z-index: 11;
	border-bottom: 1px solid $mid-grey;
	width: 100%;
	.breadcrumb {
		overflow-x: auto;
		width: 100%;
		flex: 1;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.btn-group {
		@include flex(row, flex-end);
		width: 150px;
		gap: 1rem;
	}
	.date {
		width: 100%;
		max-width: 180px;
		text-align: center;
		float: right;
	}
}
