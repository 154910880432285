@import '../../../Styles';

.root {
	label {
		@include flex;
		cursor: pointer;
		width: 200px;
		max-width: 100%;
		background: $primary;
		color: $white;
		padding: 0.75rem;
		margin: 0.5rem auto;
		border-radius: 4px;
		text-transform: uppercase;
	}

	.cropped {
		@include flex;
		max-width: 400px;
		max-height: 300px;
		width: 100%;
		height: 100%;
		.canvas {
			border: 1px solid $mid-grey;
			border-radius: 4px;
		}
	}
}
