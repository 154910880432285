@import '../../Styles/index';

.root {
    width: 100%;


    .body {
        padding: 1rem;
        width: 100%;

    }

    .items-details-container {
        width: 95%;
    }

}