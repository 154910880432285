@import '../../../../Styles/';

.root {
	width: 100%;
	max-height: 90%;
	@include flex(column, flex-start, flex-start);
	overflow: auto;
	gap: 1rem;

	.type{
		@include flex(row, flex-start);
		gap: 1rem;
		.checkbox{
			padding: .1rem;
			border-radius: 8px;
			border: 1px solid #777777;
		}
	}
	.condition{
		width: 100%;
		text-align: center;
		font-size: 1.25rem;
		animation: jump 1s ease-in-out infinite;
	}

	.filter {
		width: 100%;
		max-width: 100%;
		// width: 300px;
		height: 100%;
        max-height: 400px;
		@include flex(column, flex-start, flex-start);
		// background: red;
		border: 2px solid rgba($uy-bg, 0.4);
		border-radius: 4px;
		gap: 0.25rem;
		background: #f5f5f52d;

		&-heading {
			width: 100%;
			font-weight: 500;
			background: #408e913b;
			text-align: center;
			margin-bottom: 0.25rem;
            font-size: 1.25rem;
		}

		&-body {
			width: 100%;
			height: 100%;
			overflow-y: auto;

			.tags {
				width: 100%;
				height: 80%;
				overflow-y: auto;
				@include flex(column, flex-start, flex-start);
				gap: 0.2rem;

				.tag {
					width: 100% !important;
				}
			}
		}
	}
}

@keyframes jump {
	0%{
		transform: scale(.9);
	}
	50%{
		transform: scale(1);
		color: red;
	}
	100%{
		transform: scale(.9);
	}
}