@import '../../../Styles/';

.root {
	@include flex(column);
    font-size: 0.875rem;
    margin-top: 1rem;
    .form-wrapper{
        margin-top: 1rem;
        @include flex(row);
        gap: 1rem;
        width: 100%;
        input{
            border-radius: 4px;
            border: none;
            background-color: #F1F1F1;
            width: 250px;
            padding: 10px 5px;
            font-weight: 500;
        }
    }
    .details-wrapper{
        width: 100%;
        p{
            margin: 10px 0;
            text-align: center
        }
        .btn-container{
            @include flex(row);
            gap: 1rem;
            button{
                border: none;
                border-radius: 5px;
                background-color: $primary;
                color: white;
                cursor: pointer;
                padding: 8px 0;
                width: 200px;
            }
        }
    }
}
