@import '../../../Styles';
.custom-node {
	display: flex;
	flex-direction: column;
	position: relative;
	min-width: 3000px;
	height: 100%;
	background: #164b60;
	min-height: 300px;
	border-radius: 4px;
	// border: 2px solid yellow;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

	.actions-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 10rem;
		height: 100%;
		position: absolute;
		top: 0rem;
		right: -11rem;
		border-radius: 8px;
		z-index: 999;
		background-color: #569daa83;
		padding: 1rem 0;

		.action {
			cursor: pointer;
			.icon {
				color: #000;
				font-size: 14rem;
				align-self: center;
			}
			.FactCheck {
				color: green;
			}
		}
	}
	.img-container {
		display: flex;
		position: absolute;
		top: -20rem;
		left: 0rem;
		z-index: 999;
		gap: 2.5rem;
		// padding: .5rem 1rem;
		img {
			filter: drop-shadow(0 0 10px #808080);
			height: 25rem;
		}
		p {
			border-radius: 25px;
			background-color: #164b60;
			color: #fff;
			font-weight: 500;
			font-size: 10rem;
			padding: 1rem 3rem;
		}
	}

	.img-heading {
		display: flex;
		flex-direction: column;
		width: 50%;
		img {
			&.editable {
				transform: scale(1);
			}
			transform: scale(1.15);
			transform-origin: bottom;
			width: 100%;
			object-fit: cover;
			// position: absolute;
			// top: -30px;
		}
	}
	.Top-heading {
		width: calc(100% - 0.5rem);
		padding: 0 0.5rem;
		text-align: center;
		font-weight: 500;
		font-size: 6rem;
		border-radius: 2px;
		// transform: translate(90deg);
		color: #fff;
	}
	.section {
		width: calc(100% - 0.5rem);
		background-color: rgb(0, 128, 128);
		padding: 0 0.5rem;
		text-align: center;
		font-weight: 400;
		font-size: 6rem;
		border-radius: 2px;
		// transform: translate(90deg);
		color: #fff;
	}

	.data-container {
		// background: #ecf2ff;
		border-radius: 2px;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding: 0.5rem 0.25rem;
		// background: red;
		margin-bottom: 5px;
		gap: 0.25rem;
		overflow-y: auto;

		.analytics {
			display: flex;
			width: 100%;
			padding: 0.25rem 0.5rem;
			background: rgba(214, 214, 214, 0.75);
			border-radius: 4px;
			position: relative;
			z-index: 1;
			.analytic-allInfo-temp {
				display: flex;
				flex-direction: column;
				width: 75%;
				.analytic-info-allInfo {
					display: flex;
					.info {
						font-size: 6rem;
						display: flex;
						align-items: center;
						gap: 0.5rem;
						p {
							font-weight: 600;
						}
						.container-icon {
							display: flex;
							height: 25px;
							align-items: center;
							gap: 0.5rem;
						}
						.heading {
							font-size: 6rem;
							font-weight: 400;
							color: black;
						}
					}
					.chipInfo {
						font-size: 0.9rem;
						align-items: center;
						.heading {
							font-size: 6rem;
							font-weight: 400;
							color: black;
							padding-right: 10px;
						}
					}
					.imageInfo {
						font-size: 0.9rem;
						display: flex;
						justify-content: center;
					}
					.machineName {
						display: flex;
						flex-direction: column;
					}
					.cms-customize-chip {
						min-width: 220px;
						font-size: 6rem;
						font-weight: 600;
						height: 220px;
						display: flex;
						justify-content: space-between;
						padding: 0.5rem;
						align-items: center;
						gap: 0.5rem;
						border: 2px solid rgb(249, 251, 242);
						border-radius: 15px;
						background-color: '#F9FBF2';
						color: black;
						.cms-range {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							height: 80px;
							font-size: 6rem;
							font-weight: 400;
							padding: 0;
							color: grey;
						}
						.cms-customize-icon {
							display: flex;
						}
						.cms-customize-label {
							display: flex;
						}
					}
					.showbigimage {
						height: 400px;
						width: 500px;
						border-radius: 8px;
						object-fit: fill;
						transition: 0.5s;
						&:hover {
							cursor: pointer;
							scale: 1.1 1.1;
						}
						// cursor: pointer;
					}
					.analytic-info-allInfo-dbInfo {
						display: flex;
						flex-direction: column;
						flex: 1;
					}
					.analytic-info-allInfo-machineInfo {
						display: flex;
						flex-direction: column;
						flex: 1;
					}
				}
				.analytic-info-temp-weight {
					display: flex;
					justify-content: space-between;
					padding: 10px;
					.chipInfo {
						font-size: 0.9rem;
						align-items: center;
						.cms-customize-chip {
							min-width: 220px;
							font-size: 6rem;
							font-weight: 600;
							height: 220px;
							display: flex;
							justify-content: space-between;
							padding: 0.5rem;
							align-items: center;
							gap: 0.5rem;
							border: 2px solid rgb(249, 251, 242);
							border-radius: 15px;
							background-color: '#F9FBF2';
							color: black;
							.cms-range {
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								height: 80px;
								font-size: 6rem;
								font-weight: 400;
								padding: 0;
								color: grey;
							}
							.cms-customize-icon {
								display: flex;
							}
							.cms-customize-label {
								display: flex;
							}
						}
						.heading {
							font-size: 6rem;
							font-weight: 400;
							color: black;
							padding-right: 10px;
						}
					}
					.analytic-chip {
						display: flex;
					}
				}
			}
			.analytic-info-with-date {
				display: flex;
				width: 25%;
				flex-direction: column;
				border-left: 2px solid black;
				justify-content: space-between;
				.batch-dateTime {
					display: flex;
					flex-direction: column;
					flex: 1;
				}
			}
			.productName {
				display: flex;
				flex: 1;
			}
			.batchNo {
				display: flex;
				flex-direction: column;
				align-items: center;
				flex: 1;
			}
			.label {
				align-items: center;
				font-weight: 400;
			}
			.value {
				font-weight: 400;
			}
			.dates {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.progress {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 50%;
				z-index: -1;
			}

			p {
				font-size: 6rem;
				font-weight: 500;
				margin: 0;
			}
			.p1 {
				font-size: 6rem;
				font-weight: 100;
				margin: 0;
			}
			.field2 {
				display: flex;
				justify-content: space-between;
			}
			.field {
				display: flex;
			}
			.value-title {
				text-transform: uppercase;
				font-weight: 400;
			}
		}
	}
	.show-more {
		font-size: 100px;
		padding: 5px;
		padding-left: 15px;
		color: whitesmoke;
		&:hover {
			background-color: burlywood;
		}
	}
}
