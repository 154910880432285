@import '../../../Styles/index';

.root{
    @include flex(column, flex-start, center);
    
    .body{
        width: 400px;
        margin-top: 1rem;
        .input-container{
            width: 100%;
            background: white;
            padding: 1rem;
            border-radius: 12px;
            .search-input{
                width: 100%;
                font-size: 1.25rem;
                padding: 0.5rem;
                border-radius: 12px;
                border: 1px solid #d2d2d2;
            }
        }
        .result-container{
            @include flex(column);
            gap: 0.5rem;
            margin-top: 0.5rem;
            .result{
                width: 100%;
                @include flex(column, flex-start, flex-start);
                padding: 0.5rem;
                border-radius: 12px;
                background: white;
                cursor: pointer;
                .filler{
                    font-size: 0.9rem;
                    font-weight: 500;
                }
                .name{
                    font-size: 1.25rem;
                    font-weight: 500;
                    margin: 0;
                    color: $uy-bg;
                }
            }
        }
    }
}