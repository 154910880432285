@import '../../../Styles/index';

.root {
	width: 100%;
	height: 100%;
	@include flex(column, flex-start, flex-start);
  gap: 0.5rem;
	.tile {
		@include flex(row, flex-start, flex-start);
		.mandatory {
			color: rgb(200, 0, 0);
		}
		label {
			width: 200px;
			text-align: left;
		}
		.btn-list-container {
			@include flex(column, flex-start, flex-start);
			gap: 0.5rem;
			.tags {
				width: 100%;
				flex-wrap: wrap;
				@include flex(row, flex-start);
				gap: 0.5rem;
			}
		}
	}
}
.footer {
	@include flex(row, space-evenly, flex-end);
	gap: 0.5rem;
	width: 100%;
	// height: 100%;
	// background-color: red;
}
