@import '../../Styles/';

.root{
    @include flex(column, center, flex-start);
    gap: 1rem;
    width: 80%;
    // background: red;
    .multi{
        width: 100%;
        @include flex(row, space-between);
        gap: 7.5rem;
    }

    .rejected{
            width: 100%;
            @include flex(row, flex-start);
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            background: rgba(238, 190, 33, 0.25);
            border-radius: 4px;
            font-weight: 200;
            p{
                margin: 0;
            }
            .reason{
                font-weight: 500;
                color: red;
            }
        }

    .vertical-container{
        border:.5rem solid rgba(0, 0, 0, 0.108);
        padding:.2rem .2rem  ; 
        display: flex;
        gap: 1rem;
        flex-direction: column;
        align-items: center;
        .note{
            font-size: smaller;
            color: rgb(84, 67, 0);
            span {
                font-weight: 600;
                text-decoration: underline;
            }   
        }
    }
    .label-container{
        @include flex(row, flex-start);
        .mandatory{
            color: rgb(200, 0, 0);
        }
        .shiftStartEnd{
            width: 100%;
            font-size: smaller;
            text-align: center;
            color:green;
        }
        label{
            width: 200px;
            font-weight: 500;
            color: rgba(95, 95, 95, 0.75);
        }
        .vendor-selector{
            border: 1px solid #a2a2a2;
            border-radius: 4px;
            input{
                padding: 0.5rem;
                border-radius: 4px;
            }
        }

        .deliver-to{
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
        }
    }
}