@import '../../../Styles';
.root {
	@include flex(row, space-between, flex-start);
	width: 100%;

	position: relative;
	.right {
		flex-basis: 80%;
		max-width: 80%;
		padding-right: 1.5rem;
		margin-left: 1.25rem;
		.time-container{
			margin-right: 1rem;
		}
	}

	.delete-icon {
		position: absolute;
		right: 0.5rem;
		top: 0px;
		cursor: pointer;

		&:hover {
			color: $pastel-red;
		}
	}
	.product-wrapper {
		@include flex(row, flex-start, flex-start);
		@include customScrollBar('light');
		// flex-wrap: wrap;
		overflow-x: auto;
		margin-bottom: 2rem;
	}
}
