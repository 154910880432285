@import '../../../../Styles/';
.root {
	height: calc(100vh - 4rem);
	overflow-y: auto;
	cursor: pointer;
	position: relative;
	.critical {
		background: rgba($pastel-red, 0.5);
		&.text {
			color: rgba($bright-red, 1);
		}
	}

	.moderate {
		background: rgba($dark-yellow, 0.5);
		&.text {
			color: rgba($dark-yellow, 1);
		}
	}

	.normal {
		background: rgba($success, 0.5);
		&.text {
			color: rgba($bright-green, 1);
		}
	}

	.skipped {
		background: rgba($grey, 0.5);
		&.text {
			color: rgba($grey, 1);
		}
	}

	.text {
		@include flex(row, flex-start);
		background: none;
		width: 100%;
		text-align: left;
		gap: 0.5rem;
	}

	.brand-column {
		@include flex(column, flex-start, left);
		background: none;
		width: 100%;
		text-align: left;
		gap: 0.5rem;
	}

	.no-wrap {
		white-space: nowrap;
		display: flex;
		align-items: center;
	}
	.logo {
		max-width: 3rem;
		max-height: 3rem;
	}
}
