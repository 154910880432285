@import '../../../Styles/';

.root {
	height: calc(100vh - 60px) !important;
	position: relative;

	.header {
		@include flex(row, space-between);
		// background-color: red;
		gap: 1rem;
		width: 100%;
		padding: 0px 0px 0px 1rem;
		height: 70px;

		.search-container{
			@include flex;
			gap: .5rem;
			.search {
				max-width: 25rem;
			}
	
			.order-status{
				max-width: 15rem;
			}
		}

		.brand-filter {
			margin-right: auto;
		}

		.search-field-select {
			margin-right: auto;
			max-height: 2.5rem;
			overflow: hidden;
		}

		.dateFilter {
			gap: .1rem;
			@include flex(row, space-between);

			.dateType {
				padding: 0 .1rem;
			}
		}
	}
}