@import '../../Styles/';

.root {
	width: 100%;
    margin-top: 2rem;
	table {
		width: 100%;
		text-align: center;
		thead {
            background-color: $grey;
			tr {
				th {
					padding: 8px 0;
				}
			}
		}
		tbody {
			tr {
				td {
					padding: 8px 0;
				}
			}
            .colored{
                background-color: $grey;
            }
		}
	}
}
