@import '../../../../Styles/';
.root {
	.heading {
		font-size: medium;
		font-weight: 600;
		text-align: center;
		padding: 1rem;
	}

	.list-container {
		height: calc(100vh - 320px);
	}
}
