@import '../../../Styles/';

.root{
    width: 100%;
    height: calc(100vh - 80px);
    @include flex(column);
    gap: 1rem;
    .text-container{
        p{
            text-align: center;
            font-size: 2rem;
            font-weight: 500;
        }
    }

    .redirect-timer{
        @include flex;
        gap: 1rem;
        span{
            font-size: 3rem;
            font-weight: 500;
        }
    }

    .backBtn{
        width: 200px;
        padding: 0.5rem;
        border: none;
        outline: none;
        border-radius: 8px;
        @include flex;
        gap: 0.5rem;
        font-size: 1rem;
        background: rgb(44, 100, 255);
        color: #fff;
    }
}