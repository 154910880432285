.root {
  min-height: 90vh;
  padding: 1rem;
}
.root .stores {
  width: 100%;
  max-width: 100%;
  padding: 0 0.5rem;
  padding-top: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  border-radius: 25px;
  background-color: rgba(221, 255, 255, 0.5);
  gap: 1rem;
  overflow-x: scroll;
  letter-spacing: 1px;
}
.root .stores::-webkit-scrollbar {
  height: 0.5rem;
}
.root .stores::-webkit-scrollbar:hover {
  height: 1rem;
}
.root .stores::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.527);
  border-radius: 25px;
}
.root .stores .store {
  transition: all;
  transition-duration: 0.2s;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.15);
  padding: 0.25rem 1rem;
  min-width: 200px;
  border-radius: 25px;
}
.root .stores .store:first-child {
  min-width: auto;
}
.root .stores .store:hover {
  transform: scale(1.02);
}
.root .stores .store.active {
  font-weight: 500;
  color: white;
  box-shadow: 0 1px 5px 0 rgba(23, 26, 41, 0.2), 0 3px 10px 0 rgba(23, 26, 41, 0.2);
  border: 1px solid rgba(23, 26, 41, 0.2);
  background-color: rgb(1, 111, 145);
  letter-spacing: 1.2px;
}
.root .tabs {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.root .tabs .date-tabs {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
}
.root .tabs .date-tabs .link-button {
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 0.9rem;
  transition-duration: 100ms;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}
.root .tabs .date-tabs .link-button:nth-child(1) {
  background: #ced4da;
  color: #000;
}
.root .tabs .date-tabs .link-button:nth-child(2) {
  color: #000;
  background: #cbc0d3;
}
.root .tabs .date-tabs .link-button:nth-child(3) {
  color: #000;
  background: #f8f4ea;
}
.root .tabs .date-tabs .link-button:nth-child(4) {
  color: #000;
  background: #c3e7ff;
}
.root .tabs .date-tabs .link-button.selected {
  border-radius: 8px 8px 0 0;
  border-bottom: 15px solid transparent;
}
.root .tabs .dateRangeDiv {
  align-items: center;
  top: 0.3rem;
  display: none;
  justify-content: flex-end;
}
.root .tabs .dateRangeDiv.visibleAnimation {
  display: flex;
  transform-origin: right;
  animation: zoom-in-zoom-out 0.3s ease;
  animation-delay: 1s;
  animation-iteration-count: 2;
}
.root .tabs .dateRangeDiv .dateButton {
  font-size: 2.5rem;
}
.root .tabs .dateRangeDiv .dateButton.disableClick {
  transform: scale(0.7, 0.7);
  font-size: 2.5rem;
  color: rgba(168, 168, 168, 0.5882352941);
  opacity: 50;
}
.root .tabs .dateRangeDiv .dateButton.allowClick {
  transform: scale(1, 1);
  transition: all;
  font-size: 2.5rem;
  color: #52b9fd;
  animation: scale-in-out 0.3s ease;
  animation-delay: 0.3s;
  animation-iteration-count: 2;
}
.root .data-box-container {
  height: 100%;
  background-color: red;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  transition-duration: 500ms;
}
.root .data-box-container.TODAY {
  background: #ced4da;
  border-radius: 0 8px 8px 8px;
}
.root .data-box-container.THIS_MONTH {
  background: #f8f4ea;
  border-radius: 8px;
}
.root .data-box-container.THIS_WEEK {
  background: #cbc0d3;
  border-radius: 8px;
}
.root .data-box-container.CUSTOM {
  background: #c3e7ff;
  border-radius: 8px;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes scale-in-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}/*# sourceMappingURL=Dashboard.module.css.map */