@import '../../../Styles/';

.root {
	// height: calc(100vh - 60px) !important;
	position: relative;
	.header {
		@include flex(row, space-between);
		gap: 1rem;
		width: 100%;
		padding: 0px 0px 0px 1rem;
		height: 70px;
		.search {
			max-width: 20rem;
		}
		.brand-filter {
			margin-right: auto;
		}
		.search-field-select {
			margin-right: auto;
			max-height: 2.5rem;
			overflow: hidden;
		}
		.animate {
			animation: zoom-in-zoom-out 0.3s ease;
			animation-delay: 1s;
			animation-iteration-count: 2;
		}
	
		@keyframes zoom-in-zoom-out {
			0% {
				transform: scale(1, 1);
			}
	
			50% {
				transform: scale(1.2, 1.2);
				border: 2px solid rgb(57, 73, 0);
			}
	
			100% {
				transform: scale(1, 1);
			}
		}
	
		.btn {
			margin-right: 12px;
			svg {
				font-size: 2.5rem;
				color: $uy-bg;
			}
	
	
		}
	
		.disabled {
			svg {
				color: rgba($primary, 0.4) !important;
			}
		}
		.downloadData {
			width: 40px;
			height: 32px;
			text-align: center;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: center;
			gap: 0.25rem;
			z-index: 10;
			background-color: #1d6f42;
			border-radius: 7px;
			img {
				width: 25px;
			}
		}
	}
}
