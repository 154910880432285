@import '../../../../Styles/';

.root{
    position: relative;
    .labelName{
        position: absolute;
        top: 2.25rem;
        &.right{
            right: 0;
        }
    }
}