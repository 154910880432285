@import '../../Styles';

.root {
	@include flex;
	height: 2.25rem;
	width: 100%;
	position: relative;

	button{
		height: 2.25rem;
		border-bottom-left-radius: 0%;
		border-top-left-radius: 0%;
	}

	.search {
		position: relative;
		flex: 1;
		height: 100%;
		.input {
			width: 100%;
			font-size: 1rem;
			padding: 0.5rem 0.75rem 0.5rem 3rem;
			outline: none;
			border-radius: 4px;
			border: 1px solid $mid-grey;
		}

		.icon {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translate(0, -50%);
		}
	}

	.search-by {
		display: none;
		height: 100%;
		border-radius: 0px 0px 0px 0px;
	}

	&.search-by-active {
		.search {
			.input {
				border-radius: 4px 0px 0px 4px;
				border-right: 0px;
			}
		}
		.search-by {
			display: flex;
			margin-top: .1rem;
		}
	}
}
