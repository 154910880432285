.root {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.root svg {
  font-size: 1.25rem;
}
.root .stat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.root .stat .label {
  font-weight: 500;
}
.root .stat .label,
.root .stat .value {
  text-align: center;
}/*# sourceMappingURL=PageNavigation.module.css.map */