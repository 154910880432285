@import '../../../Styles/index';

.label-container{
    @include flex(row, flex-start);
    .mandatory{
        color: rgb(200, 0, 0);
    }
    label{
        width: 200px;
        font-weight: 500;
        color: rgba(95, 95, 95, 0.75);
    }
    .vendor-selector{
        border: 1px solid #a2a2a2;
        border-radius: 4px;
        input{
            padding: 0.5rem;
            border-radius: 4px;
        }
    }

    .deliver-to{
        @include flex(column, flex-start, flex-start);
        gap: 0.5rem;
    }
    .vendor-details{
        @include flex(column, flex-start, flex-start);
        // margin: 0.5rem 0;
        padding: .5rem;
        border-radius: 4px;
        gap: 0.5rem;
        background: rgb(240,240, 240);
        font-size: 0.9rem;
        .address-container{
            @include flex(row, flex-start, flex-start);
            .address{
                width: 250px;
                .header{
                    font-weight: 500;
                }
            }
        }
    }
}
