.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.8rem;
  width: 100%;
  height: 100%;
}
.root .day {
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 0;
}
.root .selected {
  background-color: #4a70fb;
  color: #ffffff;
}
.root .disabled {
  background-color: #c4c4c4;
}/*# sourceMappingURL=CustomWeekDays.module.css.map */