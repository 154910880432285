@import '../../.././../../Styles';

.root {
	@include flex(row);
	min-width: 100px;
	width: auto;
    height: 55px;
	background: $light-yellow;
	margin-right: 1rem;
	border-radius: 4px;
	padding: 0.25rem 0.5rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.4s;
    border-radius: 5px;
    &:hover {
        background: $dark-yellow;
    }

    .add-icon {
        font-size: 1.25rem;
    }
}
