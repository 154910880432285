@import '../../../Styles';

.root {
	width: 100%;
	@include flex(column);
	gap: 0.5rem;

	.finder {
		width: 100%;
		@include flex(row, flex-start);
		background: $uy-ex-light-bg;
		border: 1px solid $uy-bg;
		border-radius: 8px;
		padding: 0.5rem;
		.filters-container{
			@include flex(row, flex-start,flex-start);
			width: 90%;
			height: 50%;
			gap: 1rem;
			overflow-x: auto;
			.store-mapType{
				@include flex(column, flex-start, flex-start);
				gap: 2rem;
				.store{
					position: relative;
					.warning{
						position: absolute;
						top: 105%;
						font-weight: 500;
						color: red;
					}
				}
			}
	
			.filter {
				// width: 100%;
				min-width: 300px;
				width: 300px;
				height: 250px;
				@include flex(column, flex-start, flex-start);
				// background: red;
				border: 1px solid #6fa1ff75;
				border-radius: 4px;
				gap: 0.25rem;
				background: #f5f5f52d;
		
				&-heading {
					width: 100%;
					font-weight: 500;
					background: #408e913b;
					text-align: center;
					margin-bottom: 0.25rem;
				}
		
				&-body {
					width: 100%;
					height: 100%;
					overflow-y: auto;
					.tags {
						width: 100%;
						height: 80%;
						overflow-y: auto;
						@include flex(column, flex-start, flex-start);
						gap: 0.2rem;
						.tag {
							width: 100% !important;
						}
					}
				}
			}
		}
		.submit {
			@include flex(column);
			width: 100px;
			border-left: 1px solid $uy-bg;
			padding: 1rem;
			font-size: 1.25rem;
			text-transform: uppercase;

			.btn {
				&:disabled {
					svg {
						color: rgba($primary, 0.4);
					}
				}

				svg {
					font-size: 2.5rem;
					color: $uy-bg;
				}
			}
		}
	}
}
