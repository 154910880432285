@import '../../../../../Styles/';

.root {
	position: relative;
	@include flex(column);
    gap: 0.5rem;
	.label {
		width: 400px;
		@include flex(row, space-between);
		background: #fff;
		border: 1px solid #000;
		border-radius: 25px;
		padding: 0.25rem 0.5rem;
		font-weight: 500;
        cursor: pointer;
		transition-duration: 0.5s;
		&-selected {
			background: $uy-bg;
			// background: rgb(60, 80, 255);
			color: #000;
		}
        &-disabled{
            cursor: initial;
            background: rgba(197, 197, 197, 0.75);
            color: rgba(82, 82, 82, 0.75);
        }
	}

	.selected {
		width: 380px;
		@include flex(column);
        gap: 0.5rem;
		// background: rgba(60, 79, 255, 0.75);
		color: rgb(0, 0, 0);
		// color: #fff;
		font-weight: 500;
        &-item{
			padding: 0.20rem 0.5rem;
			border-radius: 25px;
			background: $uy-bg;
            width: 100%;
            @include flex(row, space-between);
            .remove{
                padding: 0;
                color: #000;
            }
        }
	}

	.content {
		width: 325px;
		height: 350px;
		background: #fff;
		border-radius: 8px;
		position: absolute;
		top: 2rem;
        left: 0;
		box-shadow: 0 0 2px 2px rgb(223, 223, 223);
		padding: 1rem;
		transition-duration: 1s;
		gap: 0.5rem;
        z-index: 999;

		.search {
			border-radius: 25px;
			outline: none;
			padding: 0.25rem 0.5rem;
			width: 100%;
		}

		&.show {
			@include flex(column, flex-start, flex-start);
		}

		&.hide {
			display: none;
		}
		.list-container {
			@include flex(row, space-between, flex-start);
			width: 100%;
			height: 300px;
			overflow-y: auto;
			.inner-container{
				@include flex(row, space-between, flex-start);
				flex-wrap: wrap;
				width: 100%;
				// height: 100%;
				.select {
					@include flex(row, flex-start, flex-start);
					height: auto;
					gap: 0.25rem;
					margin: 0.25rem 0;
					// border: 1px solid #e2e2e2;
					p {
						width: 110px;
					}
					input {
						width: 15px;
						height: 15px;
						margin-top: 0.35rem;
					}
				}
			}
		}
		.done {
			font-size: 1.2rem;
			font-weight: 500;
			background: none;
			border: none;
			outline: none;
			color: rgb(60, 80, 255);
		}
	}
}
