@import '../../../Styles/';

.advance {
	width: 100%;
	padding: 0.5rem;
	background: $uy-ex-light-bg;
	margin-bottom: 1rem;
	font-size: 1.5rem;
	border-radius: 0.5rem;
}

.root {
	@include flex(column, flex-start, flex-start);
	gap: 0.25rem;
	width: 100%;

	.header {
		width: 100%;
		@include flex(row, flex-end);
		.downloadData {
			width: 125px;
			text-align: center;
			// margin-left: auto;
			cursor: pointer;
			@include flex;
			gap: 0.25rem;
			z-index: 10;
			background-color: #1d6f42;
			color: white;
			border-radius: 1rem;
			padding: 0.25rem 0.5rem;
			img {
				width: 25px;
			}
		}
	}

	.tabs {
		width: 100%;
		@include flex(row, flex-start);
		margin-top: 1rem;
		.tab {
			color: black;
			opacity: 0.7;
			// &.INTERMEDIATE{
			// }
			// &.INGREDIENT{
			// 	background: #2a9d8f;
			// }

			&.selected {
				font-weight: 500;
				opacity: 1;
			}
		}
	}

	.items {
		width: 100%;
		.list {
			border: 1px solid #aaa;
			padding: 0.5rem;
			width: 100%;
			min-height: 300px;
			&.INTERMEDIATE {
				background: #fbf1d3;
				border-radius: 0 5px 5px 5px;
			}
			&.INGREDIENT {
				background: #d3fac7;
				border-radius: 5px;
			}
			overflow: auto;
		}
	}
}
