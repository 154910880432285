@import '../../../../Styles/';

.root {
	@include flex(column, flex-start, stretch);
	gap: 1rem;
	padding: 1rem;

	.image-results {
		@include flex(column, flex-start, flex-start);
		gap: 1.5rem;
	}

	.fallback {
		width: 100%;
		padding: 1rem;
	}	
}
