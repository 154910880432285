@import '../../../../Styles';

.root {
	.enable-disable-container{
		.enableDisable {
			margin-bottom: 1.5rem;
		}
		div {
			label {
				margin-left: 1rem;
			}
			.label {
				margin-left: 5rem;
			}
		}
	}
}
