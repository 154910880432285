@import '../../../Styles/';

.root {
	.tnc-label {
		@include flex;
		border-radius: 4px;
		background-color: $primary;
		color: $white;
		cursor: pointer;
		text-align: center;
		padding: 0.25rem 0.75rem;
		text-transform: uppercase;
	}
	.product-wrapper {
		@include flex(row, flex-start, flex-start);
		@include customScrollBar('light');
		overflow-x: auto;
		margin-bottom: 2rem;
		max-width: 750px;
	}
}
