@import '../../../Styles';

.root {
	position: relative;

	.delete-icon {
		position: absolute;
		right: 0.5rem;
		top: 0px;
		cursor: pointer;

		&:hover {
			color: $pastel-red;
		}
	}

	.section {
		@include flex(row, flex-start, flex-start);
		.group-wrapper {
			flex-basis: 80%;
			max-width: 80%;
			min-width: 400px;
			margin-right: 1rem;
		}
		.pricing-wrapper {
			min-width: 200px;
			flex-basis: 20%;
			position: sticky;
			top: calc(1.25rem + 115px);
			.pricing-root {
				@include flex(column, flex-start, flex-start);
				padding: 1rem;
				width: 100%;
				height: 150px;
				background: $very-dark-grey;
				border-radius: 4px;
			}

			.pricing-heading {
				color: $white;
				margin-bottom: 2rem;
			}

			.price-wrapper {
				justify-content: space-between;

				label {
					color: $white;
				}
			}
			.savings {
				width: 100%;
				margin-top: auto;
				text-align: center;
				color: $grey;
				font-weight: 500;
			}
		}
	}
}
