@import '../../Styles/';

.list-container {
	@include grid(2);
	padding: 1rem 0px;
	justify-items: center;
	gap: 2rem;
	.brand-filter-btn {
		.filter-icon {
			height: 2.5rem;
			width: 1.5rem;
		}
	}
	.geography-filter-btn {
		.filter-icon {
			height: 2.5rem;
			width: 1.5rem;
		}
	}
}
.failure-analysis {
	@include grid(2);
	padding: 1rem 0px;
	justify-items: center;
}
.brand-filter {
}

.statistical-filter {
	@include flex(column, flex-start, stretch);
	height: 100%;
	.statistical-filter-header {
		@include flex;
		overflow: hidden;
		padding-right: 1rem;
		gap: 1rem;
		margin-bottom: 1rem;
		max-height: 2.75rem;

		.header-item {
			flex: 1 1 33.33%;
			max-width: 33.33%;

			&.brand-select {
				max-height: 95%;
			}
		}
	}
}

.list-data-filter{
	@include flex(column, flex-start, stretch);
	gap: 1rem;
	margin-top: 1rem;
	width: 100%;
	// height: 100%;
}