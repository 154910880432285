@import '../../../Styles/index';

.root {
	@include flex(row, space-between);
	@include boxShadow;
	padding: 1rem;
	.box {
		@include flex(column, space-between);
	}
}
