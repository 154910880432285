@import '../../../Styles/index';

.root {
    width: 100%;

    .header {
        width: 100%;
        @include flex(row, space-between);
        padding: 0.5rem;
        border-bottom: 1px solid $grey;

        .page-back {
            @include flex;
            gap: .5rem;

            .page-name {
                font-size: 1.25rem;
                font-weight: 400;
            }
        }
    }

    .body {
        width: 100%;
        @include flex(column, flex-start, flex-start);
        gap: 1rem;
        padding: 0.5rem;

        .tile {
            @include flex(row, flex-start, center);

            .store-selector {
                border: 1px solid #a1a1a1;
                border-radius: 6px;
            }

            .mandatory {
                color: rgb(200, 0, 0);
            }

            label {
                width: 180px;
                text-align: left;
            }

            .btn-list-container {
                @include flex(column, flex-start, flex-start);
                gap: 0.5rem;

                .tags {
                    width: 100%;
                    flex-wrap: wrap;
                    @include flex(row, flex-start);
                    gap: 0.5rem;
                }
            }
        }

        .multi {
            @include flex(row, flex-start);
            gap: 2rem;
        }
    }

    .items-details-container {
        width: 95%;
    }

}