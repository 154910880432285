.rs-sidenav-body {
	height: 100% !important;
	border-radius: 0.5rem !important;
	background: #ffeec18f !important;
}

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle,
.rs-sidenav-nav > .rs-sidenav-item {
	border-radius: 0.5rem !important;
	background: #ffcf568f !important;
}
.rs-dropdown-expand {
	background: rgba(255, 203, 72, 0.3);
}
.rs-dropdown-menu-collapse-in :hover {
	background: rgba(255, 184, 3, 0.2549019608);
}
.rs-dropdown-item-active {
	border-radius: 0.5rem !important;
	color: black !important;
	font-weight: 500 !important;
	background: #ffb703 !important;
}
.rs-table-cell-bordered .rs-table-cell {
	border-right: 1px solid #91680098  !important ;
	border-bottom: 1px solid #91680098  !important ;
}
.rs-table-cell-group-left-shadow {
	box-shadow: 3px 0 2px #ffb70065 !important;
}
.rs-table-cell-header .rs-table-cell {
	background: #ffb70073 !important;
	color: black;
}
