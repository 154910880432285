@import '../../../Styles/index';

.root {
    @include flex(column, flex-start, flex-start);
    position: relative;
    width: 100%;

    .body {
        width: calc(100% - 5rem);
        @include flex(column, center, flex-start);
        gap: 1rem;
        padding: 1rem 0.5rem;
    }
    .order-type{
        width: 100%;
        @include flex(row, flex-start);
        padding: 0.5rem;
        border-bottom: 1px solid #777777;
        margin-top: 5rem;
        label{
            width: 200px;
            font-weight: 500;
            color: rgba(95, 95, 95, 0.75);;
        }
        .selector-container{
            @include flex;
            gap: 1rem;
            .warning-text{
                color: red;
                font-weight: 500;
            }
        }
    }
    .select-text{
        width: 100%;
        @include flex;
        min-height: 25vh;
    }
}
