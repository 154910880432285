@import '../../Styles/';

// .overlay {
// 	width: 100%;
// 	min-height: 100vh;
// 	height: 125%;
// 	position: absolute;
// 	top: 0;
// 	bottom: 0;
// 	right: 0;
// 	left: 0;
// 	background: rgba(0, 0, 0, 0.75);
// 	z-index: 9;
// }

.root {
	width: 100%;
	position: relative;
	background: #fff;

	// z-index: 5;
	.input {
		width: 100%;
		@include flex(row, flex-start);
		position: relative;
		
		.disabled{
			width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 8;
			background: #85858544;
		}

		.search-input {
			width: 100%;
			border: none;
			outline: none;
			padding: 1rem 0.5rem;
			border-radius: 12px;
		}

		.icon-conainer {
			@include flex;
			gap: 0.25rem;

			.remove {
				@include flex;
				cursor: pointer;
				padding: 0.15rem;

				&:hover {
					background: #0000005d;
					border-radius: 50%;
				}
			}
		}
	}

	.query-items {
		position: absolute;
		top: 100%;
		background: white;
		border-radius: 4px;
		width: 100%;
		max-height: 30vh;
		height: 30vh;
		// overflow-y: auto;
		// box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		z-index: 10;

		.empty {
			width: 100%;
			height: 100%;
			text-align: center;
			@include flex;
		}

		.dropdown-item {
			@include flex(row, flex-start);
			gap: 0.5rem;
			cursor: pointer;

			&:hover {
				background: #f8df2275;
			}

			padding: 0.75rem 0.25rem;
			border-bottom: 1px solid #f8de22;

			img {
				width: 50px;
				height: 50px;
				border-radius: 12px;
				box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
			}

			.details-container {
				@include flex(column, flex-start, flex-start);

				.variants {
					position: relative;
					z-index: 10;
				}
			}

			.icon {
				color: #b39e00;
			}

			.variants {
				@include flex(row, flex-start);
				flex-wrap: wrap;
				row-gap: 1rem;
				column-gap: 0.5rem;

				.variantInfoContainer {
					display: flex;
					flex-direction: column;
					gap: .1rem;
					align-self: start;

					.variant {
						min-width: 95px;
						padding: .0rem 0.25rem 0.3rem 0.25rem;
						background: rgb(155, 155, 155);
						cursor: pointer;
						border-radius: 25px;
						text-align: center;

						&:hover {
							background: #ffe62c;
							border: 1px solid #000;
						}


					}

					.quantities {

						padding: .1rem .2rem;
						gap: .1rem;
						width: 100%;
						text-align: center;
						bottom: -0.8rem;
						border-radius: 2rem;
						left: 0px;
						white-space: nowrap;
						z-index: 1;
						display: flex ;
						align-items: center;
						justify-content: space-between;

						.title {
							font-size: .6rem;
						}

						.value {
							font-size: .7rem;
							font-weight: 600;
						}
					}
				}

			}
		}
	}
}