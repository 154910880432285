@import '../../../../Styles/';

.root {
	padding: 1rem;
	min-height: 300px;
	flex: 1;
	position: relative;

	.list-button {
		position: absolute;
		top: 1rem;
		right: 2rem;
	}

	.chart {
		height: 100%;
		max-width: 100%;
		min-height: 250px;
		max-height: calc(100vh - 60px - 71px - 2rem - 150px - 2rem - 70px);
		@include md {
			min-height: 300px;
		}
	}
}
