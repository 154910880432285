.highlight{
    display: flex !important;
    gap: 1rem;
    align-items: center;
    label{
        padding: 0 !important;
    }
    input{
        width: 20% !important;
        // height: 30px;
    }
}