@import '../../../../Styles/';

.root {
	.app-module{
		@include flex(row, flex-start, flex-start);
		gap: 0.25rem;
		max-width: 325px;
		flex-wrap: wrap;
		.module{
			@include flex;
			width: 150px;
			border-radius: 25px;
			padding: 0.5rem 0;
			background: #bdbdbd;
			cursor: pointer;
			&.selected{
				background: rgb(15, 185, 0);
				color: #fff;
				border: 2px solid rgb(11, 134, 0);
			}
		}
	}
}

.map-container {
	width: 300px;
	height: 220px;
	max-width: 100%;
}
.btn-location {
	width: 250px;
	max-width: 100%;
}
.map {
	width: 100%;
	height: 100%;
}
@media screen and (max-width: 768px) {
	.map-container {
		width: 200px;
	}
	.btn-location {
		width: 200px;
	}
}