@import '../../../Styles/index';

.root{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    .tile{
        @include flex(row, flex-start);
        .mandatory {
            color: rgb(200, 0, 0);
        }
        label{
            width: 150px;
            text-align: left;
        }
    }
}


.btnsContainer{
    width: 100%;
    @include flex;
    gap: 1rem;
}