.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.root .feed-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.root .feed-container .feed {
  box-shadow: 0 1px 5px 0 rgba(23, 26, 41, 0.2), 0 3px 10px 0 rgba(23, 26, 41, 0.2);
  border: 1px solid rgba(23, 26, 41, 0.2);
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 768px) {
  .root .feed-container .feed {
    min-height: 37.5rem;
  }
}
.root .camera-info {
  width: 100%;
  max-width: 8rem;
  font-size: 1rem;
  background: #171a29;
  padding: 0.5rem 1rem;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  visibility: hidden;
}
.root .camera-info.disconnected {
  visibility: visible !important;
  background: #f50057;
}
.root .footer {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  max-height: 4.5rem;
  width: 100%;
  position: absolute;
  bottom: 1rem;
  padding: 0.5rem 1.5rem;
}
.root .footer .live {
  width: 100%;
  max-width: 8rem;
  font-size: 1rem;
  background: #171a29;
  padding: 0.5rem 1rem;
  color: #ffffff;
  border-radius: 4px;
  text-align: center;
}
.root .footer .live.disconnected {
  visibility: visible !important;
  background: #f50057;
}
.root .footer .live .label {
  font-size: 0.75em;
}
.root .measurement {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 16px;
  padding: 0 16px;
  gap: 5px;
}
.root .measurement .live-measurement-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.25rem;
}
.root .measurement .live-measurement-container .live-measurement {
  width: 200px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-weight: 600;
  border-radius: 50px;
  padding: 0.25rem 0.5rem;
}/*# sourceMappingURL=VideoFeed.module.css.map */