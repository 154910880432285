@import '../../../Styles/index';

.root {
	@include flex(column, flex-start);
	border-radius: 8px;
	background: #EDF2F4;
	@include boxShadow;
	padding: 0.3rem;
	// justify-content: center;
	grid-column: span 1;
	grid-row: span 1;
	height: 100%;
	position: relative;
	padding-bottom: 1rem;

	// @include rwd(1400) {
	// 	height: 325px;
	// 	// width: 325px;
	// }
	.xLabel {
		// z-index: 3;
		position: absolute;
		bottom: 0px;
		font-size: 0.9rem;
		font-weight: 600;
		right: 50%;
		transform: translate(50%, 0%);
		z-index: 1;
	}

	.yLabel {
		top: 2.1rem;
		left: 1rem;
		position: absolute;
		font-size: 0.9rem;
		font-weight: 600;
	}

	.yLabelLine {

		top: 2.1rem;
		right: 1rem;
		position: absolute;
		font-size: 0.9rem;
		font-weight: 600;

	}

	.chart {
		width: 100% !important;
		flex: 1;
		// height: 90% !important;
	}

	.leftClick {
		background-color: #a9cccc;
		position: absolute;
		top: .2rem;
		padding: .2rem .3rem;
		left: 1rem;
		border-radius: .3rem;
		cursor: pointer;

	}

	.rightClick {
		background-color: #a9cccc;
		cursor: pointer;
		border-radius: .3rem;
		position: absolute;
		top: .2rem;
		padding: .2rem .3rem;
		right: 1rem;

	}

	.downloadButton {
		position: absolute;
		bottom: 0px;
		right: 0px;
	}

}

.title {
	@include flex;
	flex-wrap: wrap;
	font-size: 18px;
	text-align: center;
	gap: 0.25rem;
}

.chart {

	// height: 100%;
	// width: 100%;
	// height: 275px;
	// // width: 275px;
	// min-height: 275px;
	// @include rwd(1400) {
	// 	height: 325px;
	//     // width: 325px;
	// }


	// .pieChart{
	//     height: 85% !important;
	// 	// width: 85% !important;
	// }
	// width: 100%;
	// padding: 2rem;
	// background-color: aqua;

}