$primary: #4a70fb;
$grey: #eeeeee;
$mid-grey: #c4c4c4;
$dark-grey: #5f5f5f;
$very-dark-grey: #3d3d3d;
$white: #ffffff;
$black: #171a29;
$success: #66c24d;
$bright-green: #229c00;
$pastel-red: #ff6961;
$bright-red: #ff1100;
$yellow: #ffbf47;
$dark-yellow: #FFB703;
$light-yellow: #fdf8de;
$link: #051c72;
$neon-green: #1fff0f;
$neon-red: #f72119;
$dark-green: #009b72;
$red: #f50057;
$green: #69995d;
$orange: #FB8500;
$purple: #7b1fa2;
$uy-ex-light-bg: #ffb80334;
$uy-bg: #FFB703;