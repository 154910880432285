@import '../../Styles/index';

.root{
    @include flex(column);
    gap: 0.8rem;
    width: 100%;
    height: 100%;
    .day{
        width: 100%;
        background-color: #e2e2e2;
        border-radius: 15px;
        font-size: 1rem;
        cursor: pointer;
        padding: 5px 0;
    }

    .selected{
        background-color: $primary;
        color: $white;
    }

    .disabled{
        background-color: $mid-grey;
    }
}