@import '../../Styles/index';

.root {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.header {
		width: 100%;
		display: flex;
		padding: 1rem 1.5rem;
		border: 1px solid black;
		align-items: center;
		justify-items: center;
		justify-content: center;
		position: relative;

		.buttons {
			position: absolute;
			bottom: 0px;
			right: 1rem;
		}

		.heading {
			font-size: x-large;
			font-weight: 500;
		}
	}

	.tabs {
		width: 100%;
		margin-top: .5rem;
		padding: .2rem 1rem;
		@include flex(row, space-between);
		border-bottom: 1px solid #d1d1d1;
		.tab {
			flex: 1;
			display: flex;
			gap: 1rem;
			padding: .1rem .5rem;
			font-weight: 500;
		}

		.selector {
			width: 40%;
			padding: .1rem .5rem;
			// font-weight?: 500;
			.helper-text{
				color: red;
				font-weight: 500;
			}
		}

		.buttons {}
	}

	.container {
		padding:.1rem 1rem;


	}
}