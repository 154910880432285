@import '../../styles/index';

.root {
	@include flex(row);
	gap: 1rem;
	background-color: #1d6f42;
	padding: 0.5rem 1rem;
	border-radius: 0.5rem;
	cursor: pointer;
	img {
		height: 25px;
	}
	p {
		color: white;
		text-transform: uppercase;
	}
}
