.root {
	width: 100%;
	height: 120px;
	overflow-x: hidden;

	.list {
		overflow-x: auto;
		padding: 5px;
		display: flex;
		flex-wrap: nowrap;
		gap: 12px;
		width: 100%;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		height: 100%;

		&::-webkit-scrollbar {
			width: 0 !important;
		}
		.doneGreen {
			opacity: 1 !important;
			background-color: rgba(0, 70, 0, 0.872) !important;
			filter: none !important;
		}
		.selected{
			scale: 1.15;
			transform-origin: top;
		}
		.item {
			cursor: pointer;
			opacity: 0.7;
			padding: 5px;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 90px;
			position: relative;
			display: flex;
			height: 100px;
			border: 1px solid white;
			flex-direction: column;
			border-radius: 0.2rem;
			color: white;
			filter: grayscale(1);
			background-color: #0004;
			.img {
				flex: 1;
				position: relative;
				height: 55px;
				width: 85px;
				overflow-y: hidden;
				.completedText {
					font-size: xx-small;
					font-weight: 700;
					width: 100%;
					text-align: center;
					transform: translate(-50%, 0px);
					position: absolute;
					bottom: 3px;
					left: 50%;
					word-break: break-all;
				}
			}
			.name {
				font-weight: 500;
				font-size: 0.6rem;
				flex: 0.1;
				width: 100%;
			}
		}
	}
}
