@import '../../../Styles/';

.custom-node {
	@include flex(column);
	position: relative;
	width: 800px;
	height: 100%;
	background: #164B60;
	min-height: 465px;
	border-radius: 4px;
	// border: 2px solid yellow;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
		rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

	.actions-container{
		@include flex(column, flex-start);
		width: 10rem;
		height: 100%;
		position: absolute;
		top: 0rem;
		right: -11rem;
		border-radius: 8px;
		z-index: 999;
		background-color: #569daa83;
		padding: 1rem 0;

		.action{
			cursor: pointer;
			.icon{
				color: #000;
				font-size: 7.5rem
			}
			.FactCheck{
				color: black;
			}
		}
	}
	.img-container{
		@include flex;
		position: absolute;
		top: -20rem;
		left: 0rem;
		z-index: 999;
		gap: 2.5rem;
		// padding: .5rem 1rem;
		img{
			filter: drop-shadow(0 0 10px #808080);
			height: 25rem;
		}
		p{
			border-radius: 25px;
			background-color: #164B60;
			color: #fff;
			font-weight: 500;
			font-size: 10rem;
			padding: 1rem 3rem;
		}
	}

	.img-heading{
		@include flex(column);
		width: 50%;
		img {
			&.editable {
				transform: scale(1);
			}
			transform: scale(1.15);
			transform-origin: bottom;
			width: 100%;
			object-fit: cover;
			// position: absolute;
			// top: -30px;
		}
	
	}
	.heading {
		width: calc(100% - 0.5rem);
		padding: 0 0.5rem;
		text-align: center;
		font-weight: 500;
		font-size: 6rem;
		border-radius: 2px;
		// transform: translate(90deg);
		color: #fff;
	}
	.orders_count{
		position: absolute;
		top: -10rem;
		right: -11rem;
		z-index: 999;
		@include flex(row);
		gap: 1rem;
		// background: $uy-bg;
		border-radius: 12px;
		.count{
			padding: 1rem;
			border-radius: 50%;
			@include flex(column);
			width: 150px;
			height: 150px;
			&.pending{
				background: $uy-bg;
			}
			&.completed{
				background: rgb(0, 157, 0);
			}
			// .count_header{
			// 	font-size: 2rem;
			// }
			p{
				font-size: 5rem;
				font-weight: 500;
			}
		}	
	}
	.section {
		width: calc(100% - 0.5rem);
		padding: 0 0.5rem;
		text-align: center;
		font-weight: 400;
		font-size: 4rem;
		border-radius: 2px;
		// transform: translate(90deg);
		color: #fff;
	}

	.data-container {
		// background: #ecf2ff;
		border-radius: 2px;
		width: 100%;
		height: 100%;
		@include flex(column, flex-start, flex-start);
		padding: 0.5rem 0.25rem;
		// background: red;
		margin-bottom: 5px;
		gap: 0.25rem;
		overflow-y: auto;

		.analytics {
			@include flex(row, space-between);
			width: 100%;
			padding: 0.25rem .5rem;
			background: rgba(214, 214, 214, 0.75);
			border-radius: 4px;
			position: relative;
			z-index: 1;
			.progress{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 50%;
				z-index: -1;
			}

			p {
				font-size: 5rem;
				font-weight: 500;
				margin: 0;
			}
			.value-title{
				text-transform: uppercase;
				font-weight: 400;
			}
		}
	}
}
