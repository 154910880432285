.dot-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background: #ffffff;
  width: 30px;
  height: 30px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  position: relative;
}
.dot-container.small {
  cursor: default;
  width: 20px;
  height: 20px;
}
.dot-container.small .dot {
  width: 10px;
  height: 10px;
}
.dot-container .dot {
  width: 15px;
  height: 15px;
  border-radius: 50px;
}
.dot-container.veg {
  border: 1px solid #66c24d;
}
.dot-container.veg .dot {
  background: #66c24d;
}
.dot-container.non-veg {
  border: 1px solid #ff6961;
}
.dot-container.non-veg .dot {
  background: #ff6961;
}/*# sourceMappingURL=CheckMark.module.css.map */