.root {
  box-shadow: 0 1px 5px 0 rgba(23, 26, 41, 0.2), 0 3px 10px 0 rgba(23, 26, 41, 0.2);
  border: 1px solid rgba(23, 26, 41, 0.2);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 1rem;
  background: #ffffff;
  color: #ffffff;
}
.root.PASS {
  background: #66c24d;
}
.root.SKIPPED {
  background: #5f5f5f;
}
.root.OVERRIDDEN {
  background: #FFB703;
}
.root.FAIL {
  background: #f50057;
}
.root .text {
  text-align: center;
  font-size: 1.5rem;
}
.root .button {
  border-color: #f50057;
  color: #f50057;
  background: #ffffff;
  position: absolute;
  right: 1rem;
}
.root .button:hover {
  background: #ffffff;
}/*# sourceMappingURL=Footer.module.css.map */