@import '../../Styles/index';

.root {
	.header {
		padding: 0.5rem;
	}
    .actions {
        @include flex(column);
        gap: 0.5rem;
    }
}
