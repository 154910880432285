@import '../../../../Styles/';

.root {
	position: relative;
	padding: 1rem;
	.chart {
		max-width: 100%;
		min-height: 250px;
		max-height: calc(
			100vh - 60px - 10rem - 36px - 2rem - 2rem - 50px - 30px - 1rem
		);
		@include md {
			min-height: 300px;
			height: calc(
				100vh - 60px - 10rem - 36px - 2rem - 2rem - 50px - 30px - 1rem
			)
		}
	}
	.filters {
		position: relative;
		z-index: 11;
	}
	.list-button {
		position: absolute;
		right: 1rem;
		top: 1rem;
	}
	.year-selection {
		width: 10rem;
	}
}
