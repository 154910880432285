@import '../../Styles/';

.root {
	position: relative;
	.filter {
		padding: 0.5rem;
	}
	.type {
		padding: 0.1rem 0.5rem;
		border-radius: 8px;
		font-size: 0.75rem;
		font-weight: 500;
		color: white;
		text-align: center;
		min-width: 75px;
		max-width: 100px;
		@include bill_type_colors;
	}
}

.row {
	background: white;
	&.PAID {
		background: rgba(0, 122, 0, 0.5);
	}
	&.PARTIALLY_PAID {
		background: rgba(255, 251, 0, 0.5);
	}
	&.APPROVAL_PENDING_MANAGER {
		background: rgba(255, 187, 0, 0.25);
	}
	&.APPROVAL_REJECTED {
		background: rgba(255, 0, 0, 0.5);
	}
	&.CANCELLED {
		background: rgba(255, 0, 0, 0.5);
	}
	&.REJECTED {
		background: rgba(255, 0, 0, 0.25);
	}
}
