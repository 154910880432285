@import '../../../../../Styles';

.root {
	width: 100%;
	position: relative;

	.delete-icon {
		cursor: pointer;

		&:hover {
			color: $pastel-red;
		}
	}

	.process-section {
		@include flex(row, flex-start, flex-start);
		width: 100%;

		.tabs-wrapper {
			width: 200px;
			max-width: 200px;
			flex: 1 1 25%;
		}

		.process-wrapper {
			flex: 1 1 75%;
			margin-left: 1.25rem;
			overflow-x: auto;
		}
	}
}
