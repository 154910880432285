@import '../../../Styles/index';


.root {
	max-height: 93vh;
	display: flex;
	flex-direction: column;

	.container {
		padding: .5rem;
		overflow-y: auto;
		flex: 1;
		min-height: 70vh;
		width: 100%;

		display: flex;

		.navContainer {
			height: 70vh;
			max-width: 18rem;
			position: relative;

			.nav {
				height: 100%;
				display: flex;
				min-height: 100%;
				overflow-y: auto;
				-ms-overflow-style: none;  /* Internet Explorer 10+ */
				scrollbar-width: none; 
				&::-webkit-scrollbar{
					display: none;
				}
				.defaultHeight {
					height: 100%;
				}

			}

			.hideViewNav {
				position: absolute;
				top: 50%;
				z-index: 10;
				right: -1.5rem;

				.expandIcon {
					cursor: pointer;
					background-color: rgba(255, 184, 3, 0.2549019608);
					transform: rotate(-90deg);
				}

				.rotateExpandIcon {
					transform: rotate(90deg) !important;
				}
			}
		}

		.tableContainer {
			flex: 1;
			padding-left: .5rem;
			position: relative;

			.tableLoader {
				background-color: rgba(145, 104, 0, 0.267);
				color: black;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
			}

			.cached {
				position: absolute;
				left: .2rem;
				border-radius: 2rem;
				top: -.4rem;
				padding: .05rem .1rem;
				z-index: 10;
				cursor: pointer;
				color: rgb(145, 106, 0);
				background-color: rgba(255, 184, 3, 0.795);
				display: flex;
				justify-content: center;
				align-items: center;
			}


			.table {
				width: 100%;
				height: 100%;

			}

			.downloadData {
				text-align: center;
				position: absolute;
				top: -.4rem;
				right: -.2rem;
				// margin-left: auto;
				cursor: pointer;
				@include flex;
				z-index: 100;
				background-color: #1d6f42bb;
				color: white;
				border-radius: 1rem;
				padding: 0.1rem .8rem;

				img {
					width: 15px;
				}
			}
		}
	}

	.dateRangeDiv {
		align-items: center;
		top: 0.3rem;
		display: none;
		justify-content: flex-end;

		&.visibleAnimation {
			display: flex;
			transform-origin: right;
			animation: zoom-in-zoom-out 0.3s ease;
			animation-delay: 1s;
			animation-iteration-count: 2;
		}

		.dateButton {
			font-size: 2.5rem;

			&.disableClick {
				transform: scale(0.7, 0.7);
				font-size: 2.5rem;
				color: #a8a8a896;
				opacity: 50;
			}

			&.allowClick {
				transform: scale(1, 1);
				transition: all;
				font-size: 2.5rem;
				color: #52b9fd;

				animation: scale-in-out 0.3s ease;
				animation-delay: 0.3s;
				animation-iteration-count: 2;
			}
		}
	}

}

@keyframes zoom-in-zoom-out {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.1, 1.1);
	}

	100% {
		transform: scale(1, 1);
	}
}

@keyframes scale-in-out {
	0% {
		transform: scale(1, 1);
	}

	50% {
		transform: scale(1.2, 1.2);
	}

	100% {
		transform: scale(1, 1);
	}
}