.root {
  width: 100%;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.root .header-text {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1.5rem;
  width: 60%;
}
.root .header-text h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.root .header-text .select {
  width: 25%;
}/*# sourceMappingURL=AIModelHistoryHeader.module.css.map */