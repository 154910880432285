@import '../../../../Styles/';

.root {
	@include flex(column, flex-start, stretch);
	gap: .5rem;
	padding: 1rem;

	.image-results {
		@include flex(column, flex-start, flex-start);
		gap: 1.5rem;
	}

	.fallback {
		width: 100%;
		padding: 1rem;
	}

	.numbers {
		display: flex;
		width: 100%;
		padding: .2rem .1rem;
		// background: #b3ffbb;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		.element{
			border-radius: .5rem;
			padding: .1rem 1rem;
			background: #0F3D3E;
			color: white;
			display: flex;
			align-items: center;
			span{
				width: 30px;
				text-align: center;
				font-weight: 800;
			}
		}
	}
}