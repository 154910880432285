@import '../../../../Styles/';

.root {
    width: 100%;
    @include flex(column, flex-start, flex-start);
    .body{
        width: 100%;
        @include flex(column, flex-start, flex-start);
        padding: 0.5rem 1rem;

        .tile{
            @include flex(row, flex-start, flex-start);
            position: relative;
            .mandatory {
                color: rgb(200, 0, 0);
            }
            // label{
            //     width: 200px;
            //     text-align: left;
            // }
            .error{
                font-size: 0.8rem;
                position: absolute;;
                left: 180px;
                bottom: -1rem;
                color: red;
                font-weight: 500;
                // max-width: 180px;
            }
        }

        .multi{
            width: 80%;
            @include flex(row, flex-start);
            gap: 2rem;
        }
        .access-level{
            width: 100%;
            @include flex(row, flex-start);
            gap: 0.5rem;
            // max-width: 475px;
            flex-wrap: wrap;
            &-item{
                @include flex;
                width: 150px;
                border-radius: 25px;
                padding: 0.5rem 0;
                background: #bdbdbd;
                cursor: pointer;
                &.selected {
                    background: $uy-bg;
                    font-weight: 500;
                    border: 2px solid $uy-bg;
                }
            }
        }
    }
}

.btns{
    @include flex;
    gap: 0.5rem;
}