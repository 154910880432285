@import '../../../Styles/';

.root {
	@include flex(row, flex-start);
	gap: 0.5rem;

	p {
		margin: 0;
		font-weight: 500;
	}

	&.no-gstin {
		color: red;
	}

	.btn-container {
		@include flex;
		gap: 0.5rem;

		.edit-btn {
			border-radius: 20px;
			padding: 0 0.5rem;
			border: 1px solid blue;
			background: transparent;
			color: blue;
			font-weight: 500;

			&:hover {
				background: rgba(0, 0, 255, 0.25);
			}
		}
	}
}