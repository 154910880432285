.root {
  width: calc(50% - 0.5rem);
  border-radius: 8px;
  padding: 0.5rem;
  text-align: left;
  box-shadow: 0px 1px 5px #b8b8b8;
  cursor: pointer;
}
.root .head-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(187, 187, 187, 0.4666666667);
}
.root .head-container .header {
  font-size: 1.25rem;
  font-weight: 500;
}
.root .head-container .date {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}
.root .duration {
  font-weight: 500;
}/*# sourceMappingURL=BatchBox.module.css.map */