@import '../../Styles';

.btns-container {
	width: 100%;
	@include flex(row, flex-end);
	gap: 0.5rem;
	position: relative;
	min-height: 50px;
	padding: 0 0.5rem;
	.total {
		@include flex;
		gap: 0.5rem;
		font-weight: 500;
		p {
			margin: 0;
		}
	}

	.plan-btn {
		position: absolute;
		left: 0.25rem;
	}

	.downloadData {
		width: 125px;
		text-align: center;
		// margin-left: auto;
		cursor: pointer;
		@include flex;
		gap: 0.25rem;
		z-index: 10;
		background-color: #1d6f42;
		color: white;
		border-radius: 1rem;
		padding: 0.25rem 0.5rem;
		img {
			width: 25px;
		}
	}
	.import_data {
		width: 125px;
		text-align: center;
		// margin-left: auto;
		cursor: pointer;
		@include flex;
		gap: 0.25rem;
		z-index: 10;
		background-color: #3f6cffcc;
		color: white;
		border-radius: 1rem;
		padding: 0.25rem 0.5rem;
	}
	.importData {
		width: 125px;
		text-align: center;
		// margin-left: auto;
		cursor: pointer;
		@include flex;
		gap: 0.25rem;
		z-index: 100;
		background-color: #2a3fff;
		color: white;
		border-radius: 1rem;
		padding: 0.25rem 0.5rem;
		.icon {
			width: 25px;
		}
	}

	.column_dropdown {
		padding: 0.5rem;
		margin: 0.25rem;
		min-width: 150px;
	}
}

tr {
	// background: #f1f1f1;
	background: #fff;
	min-width: 100%;
}

th {
	font-weight: 400;
	text-align: left;

	&.center {
		text-align: center;
	}

	&.change-value {
		@include flex;
		gap: 0.5rem;
		.icon {
			@include flex;
			padding: 5px;
			background-color: #dafffb;
			border-radius: 8px;
			cursor: pointer;
		}
	}

	&:not(.custom-col) {
		text-transform: uppercase;
	}
}

tr {
	border-bottom: 1px solid $mid-grey;
}

.list-table-root {
	width: 100%;
	position: relative;
	border-collapse: collapse;

	thead {
	}

	tbody {
		width: 100%;

		// .default{
		// 	background: $uy-ex-light-bg;
		// }
	}
}

th {
	padding: 0.75rem;
	text-align: center;
	font-size: 0.9rem;
	font-weight: 500 !important;
	border-top: 1px solid #0000003f;
	border-bottom: 1px solid #0000003f;
}

td {
	font-weight: 500;
	font-size: 0.95rem;
	padding: 0.25rem 0.5rem;
}

.action-item {
	width: 10px;
	text-align: center;

	.action-btn {
		font-size: 1rem;
		cursor: pointer;
		transition: color 0.375s;

		&:hover {
			&.clone,
			&.add {
				color: $success;
			}

			&.view {
				color: $primary;
			}

			&.delete {
				color: $pastel-red;
			}
		}
	}
}

.fallback {
	text-align: center;
	.noteText {
		font-weight: 500;
		font-size: smaller;
	}
	.td {
		text-align: center;
	}
}

.sticky {
	height: auto;
	max-height: 100%;

	thead {
		tr {
			z-index: 3;
			position: sticky;
			top: $header-height;
			background: #f1f1f1;
		}
	}
}
