.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  position: relative;
}
@media (min-width: 768px) {
  .root {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 60px - 80px - 2rem);
  }
}
@media (orientation: landscape) {
  .root {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 60px - 80px - 2rem);
  }
}
.root .overlay {
  display: none;
  z-index: 11;
  position: absolute;
  inset: 0;
  background: rgba(23, 26, 41, 0.8);
  font-size: 2rem;
  color: #ffffff;
}
.root .overlay.show {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.root .image-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  position: relative;
  height: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}
.root .labels-wrapper {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  height: 100%;
  padding: 1rem;
}
.root .labels-wrapper .heading,
.root .labels-wrapper .sub-heading {
  width: 100%;
  text-align: center;
}
.root .labels-wrapper .heading {
  font-size: 1.25rem;
}
.root .labels-wrapper .fallback {
  text-align: center;
  margin-bottom: 20rem;
  color: #c4c4c4;
}
.root .labels-wrapper .black {
  color: #171a29;
}
.root .labels-wrapper .footer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.root .labels-wrapper .labels {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: thin;
  overflow: auto;
  height: 100%;
}
.root .labels-wrapper .labels::-webkit-scrollbar {
  width: 100%;
  height: 5px;
}
.root .labels-wrapper .labels::-webkit-scrollbar {
  background: #3d3d3d;
}
.root .labels-wrapper .labels::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}
.root .labels-wrapper .labels .label-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
  max-width: 360px;
  border-radius: 4px;
  width: 100%;
  padding: 0.5rem 1rem;
}
.root .labels-wrapper .labels .label-container .key-color {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
}
.root .labels-wrapper .labels .label-container .label-name {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
  width: 70%;
  max-width: 120px;
  gap: 1rem;
}
.root .labels-wrapper .labels .label-container .label-name .defect {
  font-size: 0.8em;
  color: #f50057;
}
.root .labels-wrapper .labels .label-container .label-name .defect:hover {
  cursor: pointer;
  text-decoration: underline;
}
.root .labels-wrapper .labels .label-container .label-name .identification-type {
  padding: 0.2rem 0.6rem;
  border-radius: 8px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.8rem;
  height: 100%;
}
.root .labels-wrapper .labels .label-container .label-name .AI {
  background-color: #1C6758;
}
.root .labels-wrapper .labels .label-container .label-name .MN {
  background-color: #f28f3b;
}
.root .labels-wrapper .labels .label-container .label-actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.root .image-container,
.root .labels-wrapper {
  width: 100%;
}
.root .image-container {
  min-height: 400px;
}
@media (min-width: 768px) {
  .root .image-container {
    min-height: auto;
    width: 65%;
  }
}
@media (orientation: landscape) {
  .root .image-container {
    min-height: auto;
    width: 65%;
  }
}
.root .labels-wrapper {
  max-height: 400px;
}
@media (min-width: 768px) {
  .root .labels-wrapper {
    max-height: auto;
    width: 45%;
  }
}
@media (orientation: landscape) {
  .root .labels-wrapper {
    max-height: auto;
    width: 45%;
  }
}/*# sourceMappingURL=BoxAnnotator.module.css.map */