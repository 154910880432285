@import '../../../Styles';

.details-containers {
	display: flex;
	flex-direction: column;
	justify-content: right;
	align-items: right;
	.inner-details {
		font-weight: 20px;
		color: white;
		display: flex;
		justify-content: space-between;
		gap: 0.25rem;
	}
}
.icon {
	padding-left: 40px; /* Space for the icon */
	line-height: 32px; /* Adjust based on icon size */
	background-size: 32px 32px; /* Adjust based on icon size */
	background-repeat: no-repeat; /* Prevents icon from repeating */
	display: inline-block;
}
.arrow {
	width: 0;
	height: 0;
	border-style: solid;
	position: absolute;
	z-index: 1;
}

.arrow.right {
	border-width: 10px 0 10px 10px;
	border-color: transparent transparent transparent #007bff;
	left: -5px; /* Adjust position as needed */
}

.arrow.left {
	border-width: 10px 10px 10px 0;
	border-color: transparent #007bff transparent transparent;
	right: -5px; /* Adjust position as needed */
}
.int {
	max-height: 194px;
	&:hover {
		max-height: 300px;
	}
}
.showbigimage {
	height: 80px;
	width: 100px;
	border-radius: 8px;
	object-fit: fill;
	transition: 0.5s;
	&:hover {
		cursor: pointer;
		scale: 1.1 1.1;
	}
	// cursor: pointer;
}
.card {
	display: flex;
}
.card_left_content {
	width: 72%;
	height: 100%;
	padding: 5px;
}
.card_right_component {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	margin-right: 0;
	width: 28%;
	min-height: 100%;
	border-left: 1px solid white;
}
.time_component {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	color: white;
	font-size: 12px;
	// border-bottom: 1px solid white;
	p {
		margin: 0;
	}
	.date {
		font-size: 15px;
	}
}
.duration {
	display: flex;
	justify-content: center;
	width: 100%;
	color: white;
	font-size: 20px;
	font-weight: 500;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
}
.Time {
	width: 100%;
	// padding: 5px;
	padding-left: 30px;
	padding-right: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.label {
	width: 100%;
	text-align: left;
	padding: 5px;
}
.chip {
	width: 200px;
	color: 'black';
	font-weight: 600;
	font-size: 20px;
	@include flex;
	gap: 0.25rem;
	height: 40px;
	background-color: '#F9FBF2';
	padding: '.25rem';
}
.customize-chip {
	min-width: 200px;
	font-size: 20px;
	font-weight: 600;
	height: 40px;
	display: flex;
	justify-content: space-between;
	padding: 0.5rem;
	align-items: center;
	gap: 0.5rem;
	border: 2px solid rgb(249, 251, 242);
	border-radius: 15px;
	background-color: '#F9FBF2';
	color: black;
	.range {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 20px;
		font-size: 10px;
		font-weight: 200;
		padding: 0;
		color: grey;
	}
	.customize-icon {
		display: flex;
	}
	.customize-label {
		display: flex;
	}
}
.info {
	font-size: 0.9rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	p {
		font-weight: 600;
	}
	.container-icon {
		display: flex;
		height: 25px;
		align-items: center;
		gap: 0.5rem;
	}
}
.infoRight {
	font-size: 0.9rem;
	display: flex;
	flex-direction: column;
	// gap: 0.5rem;
	p {
		font-weight: 600;
	}
	.container-icon {
		display: flex;
		height: 25px;
		align-items: center;
		gap: 0.5rem;
	}
}
.heading {
	font-size: 0.9rem;
	font-weight: 400;
}
.chipInfo {
	font-size: 0.9rem;
}
.imageInfo {
	font-size: 0.9rem;
}
.link {
	color: white;
	.download_chip {
		font-size: 0.9rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		border: 1px solid white;
		border-radius: 25px;
		background: none;
		padding: 0 0.25rem;
		transition: 0.25s;
		&:hover {
			background: $uy-bg;
			color: black;
		}
	}
}
.general-details {
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
}
.side {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	.image {
		display: flex;
		width: 100%;
		justify-content: center;
		flex: 1;
	}
	.backIcon {
		display: flex;
		width: 100%;
		flex: 1;
		justify-content: flex-end;
	}
	.forwardIcon {
		display: flex;
		width: 100%;
		flex: 1;
		justify-content: flex-start;
	}
}
.machineName {
	display: flex;
	flex-direction: column;
}
