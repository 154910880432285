@import '../../Styles/';

.root {
	@include flex(column, flex-start, flex-start);
	gap: 1.5rem;

	.tile {
		@include flex(row, flex-start, flex-start);

		.mandatory {
			color: rgb(200, 0, 0);
		}

		label {
			width: 175px;
			display: flex;
			flex-direction: column;
			text-align: left;			

			span {
				font-size: smaller;
			}
		}
	}

	.multi {
		// width: 60%;
		@include flex(row, flex-start, flex-start);
		gap: 8rem;
	}

	.custom-selector {
		border: 1px solid #a2a2a2;
		border-radius: 4px;

		input {
			padding: 0.5rem !important;
			border-radius: 4px;
		}
	}

	.uploaded-file {
		@include flex(column, flex-start, flex-start);
		gap: 0.5rem;

		// position: relative;
		.btns {
			@include flex;
			gap: 0.5rem;
		}

		.preview {
			@include flex(column, flex-start, flex-start);
			gap: 0.25rem;

			iframe {
				height: 200px;
			}
		}
	}
}