@import '../../../../../Styles';
.root {
	.tag-wrapper {
		@include flex(column, flex-start, flex-start);
		flex-wrap: wrap;
		gap: 0.5rem;
		margin-left: 170px;
		.tag {
			height: 30px;
			border-radius: 4px;
			border: 1px solid $mid-grey;
			padding: 0.5rem 1rem;
			cursor: pointer;
			transition: all 0.4s;
			&.selected {
				color: $white;
				background-color: $success;
			}
			&:hover {
				color: $white;
				background-color: $success;
			}
		}
		.add {
			height: 70px;
			width: 550px;
			border-radius: 4px;
			border: 1px solid $mid-grey;
			padding-left: 1rem;
			margin-left: 0.5rem;
			padding-top: 3.5rem;
			padding-bottom: 2rem;
			margin-right: 5px;
			cursor: pointer;
			transition: all 0.4s;
			@include flex(row, center, center);
			.addIcon {
				margin-bottom: 1.5rem;
			}
		}
		@media screen and (min-width: 1067px) {
			.add {
				width: 600px;
			}
		}
	}

	&.disabled {
		.tag {
			cursor: default;
			&:hover {
				background-color: transparent;
				color: $black;
			}
		}
	}
}
