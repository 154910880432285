@import '../../../../Styles/';

.root {
	width: 100%;
	@include flex(column, flex-start, flex-start);
	.body {
		width: 100%;
		@include flex(column, flex-start, flex-start);
		padding: 0.5rem 1rem;

		.tile {
			@include flex(row, flex-start, flex-start);
			position: relative;
			.mandatory {
				color: rgb(200, 0, 0);
			}
			// label{
			//     width: 200px;
			//     text-align: left;
			// }
			.error {
				font-size: 0.8rem;
				position: absolute;
				left: 180px;
				bottom: -1rem;
				color: red;
				font-weight: 500;
				// max-width: 180px;
			}
		}

		.multi {
			width: 100%;
			@include flex(row, flex-start);
			gap: 2rem;
		}
		.access-level {
			width: 100%;
			@include flex(row, flex-start);
			gap: 0.5rem;
			// max-width: 475px;
			flex-wrap: wrap;
			&-item {
				@include flex;
				width: 150px;
				border-radius: 25px;
				padding: 0.5rem 0;
				background: #bdbdbd;
				cursor: pointer;
				&.selected {
					background: $uy-bg;
					font-weight: 500;
					border: 2px solid $uy-bg;
				}
			}
		}
	}
}

.btns {
	@include flex;
	gap: 0.5rem;
}

.checkbox {
	background-color: #fff;
	border: solid 1px #d7d7d7;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	height: 20px;
	margin-right: 5px;
	text-align: center;
	width: 20px;
	vertical-align: middle;

	svg {
		stroke: #fff;
		margin-top: 1.5px;
	}
}

.negative {
	background-color: #dc4e4e;
	border-color: #c74545;
}

.positive {
	background-color: #4aca65;
	border-color: #43b45b;
}

.tableDescriptionContainer {
	width: 100%;
}

.tableContainer {
	width: 100%;
	overflow-x: auto;
	padding-bottom: 10px;
}

.dropdown {
	padding: 5px;
	display: flex;
	justify-content: space-between;
	min-width: 175px;
	.dropdownMenu {
		min-width: 70px;
		border: 1px solid #d1d1d1;
		border-radius: 4px;
	}
}

.includeIcon {
	color: #4aca65;
	width: 40px;
	height: 20px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	display: flex;
	align-items: center;
	justify-content: right;
	margin-right: 2px;
}
.excludeIcon {
	color: #c74545;
	width: 40px;
	height: 20px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	justify-content: left;
	display: flex;
	align-items: center;
	margin-right: 2px;
}

.inputAndDescription {
	width: 100%;
	display: flex;
	justify-content: space-between;
	.inputContainer {
		width: 100%;
	}
	.levelDescriptionHidden {
		min-width: 250px;
	}
	.levelDescription {
		padding: 10px;
		color: red;
		font-size: 18px;
		background-color: rgb(232, 232, 232);
		min-width: 250px;
		border-radius: 5px;
		margin-left: 20px;
		margin-bottom: 10px;
		.header {
			font-weight: 700;
			margin-bottom: 10px;
			text-align: center;
		}
		.descriptionContainer {
			color: blue;
			font-size: 16px;
			font-weight: 600;
			display: flex;
			text-wrap: nowrap;
			margin: 5px;
			.description {
				margin-left: 15px;
				font-size: 15px;
				text-wrap: wrap;
				color: black;
			}
		}
	}
}

.checkToggleContainer {
	display: flex;
	min-width: 80px;
	justify-content: space-between;
	align-items: center;
	.checkboxType {
		background-color: #fff;
		border: solid 1px #d7d7d7;
		border-radius: 4px;
		cursor: pointer;
		display: inline-block;
		height: 20px;
		margin: 5px;
		width: 20px;
	}
}
