@import '../../../../Styles';

.root {
	// height: calc(100vh - 60px) !important;
	.page-name{
		padding: 0 .5rem;
		font-size: 1.2rem;
		font-weight: 500;
		text-align: left;
	}
	.header {
		@include flex(row, space-between);
		gap: 1rem;
		width: 100%;
		// padding-left: 1rem;
		padding: .5rem 1rem;

		.search {
			max-width: 20rem;
		}

		.search-field-select {
			max-height: 2.5rem;
			overflow: hidden;
		}

		.toggle-btns{
			@include flex(column, flex-start, flex-start);
			gap: .25rem;
		}

		.show-stocked-out {
			margin-right: auto;
		}

		.total-items{
			@include flex(row);
			gap: 0.3rem;
			cursor: pointer;
		}

		.store-select{
			width: 100px;

		}
		.animate {
			animation: zoom-in-zoom-out 0.3s ease;
			animation-delay: 1s;
			animation-iteration-count: 2;
		}
	
		@keyframes zoom-in-zoom-out {
			0% {
				transform: scale(1, 1);
			}
	
			50% {
				transform: scale(1.2, 1.2);
				border: 2px solid rgb(57, 73, 0);
			}
	
			100% {
				transform: scale(1, 1);
			}
		}
	
		.btn {
			margin-right: 12px;
			svg {
				font-size: 2.5rem;
				color: $uy-bg;
			}
	
	
		}
	
		.disabled {
			svg {
				color: rgba($primary, 0.4) !important;
			}
		}
		.downloadData {
			width: 40px;
			height: 32px;
			text-align: center;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: center;
			gap: 0.25rem;
			z-index: 10;
			background-color: #1d6f42;
			border-radius: 7px;
			img {
				width: 25px;
			}
		}
	}
	.view {
		font-size: 1.25rem;
		cursor: pointer;
		transition: color 0.4s;

		&:hover {
			color: $primary;
		}
	}
}
