@import '../../../Styles/index';
.root {
	position: relative;
	.input-wrapper {
		position: -webkit-sticky;
		position: sticky;
		top: calc(1.25rem + 42px);
	}
	.search-bar-container{
		width: 15rem;
		max-width: 15rem;
		margin: 1rem;
	}
}
