@import '../../../../Styles';

.root {
	.unit {
		max-width: 120px;
	}

	.variant-inputs {
		padding: 0px 1rem 6rem 1rem;
	}
	.inputWidthOVerride{
		width: 120px !important;
	}
}
