@import '../../Styles';

.hide-show-btn {
	width: 35px;
	height: 35px;
	// padding: 5rem;
	position: fixed;
	bottom: 4rem;
	right: 1rem;
	background: $uy-bg;
	@include flex;
	gap: 0.5rem;
	font-size: 1.25rem;
	border-radius: 50%;
	z-index: 99;
}

.root {
	@include flex(row, space-between, center);
	width: 100%;
	height: 50px;
	background: #f1f1f1;
	border-bottom: 1px solid $mid-grey;
	padding: 0px 1rem;
	position: sticky;
	top: 0px;
	z-index: 110;

	&.internal {
		background: #edf6f9 !important;
	}

	&.hidden {
		display: none;
	}

	.left {
		@include flex;

		.hamburger {
			z-index: 112;
			position: relative;
		}

		.client-logo {
			// width: 10rem;
			height: 60px;
			object-fit: contain;
			padding: 0.25rem;
		}

		.heading {
			@include flex(row, space-between, center);
			font-size: 1.75rem;
			height: 100%;
			margin-left: 0.5rem;
			display: flex;
			align-items: flex-end;

			span {
				font-size: 1.2rem;
				padding-left: 1rem;
				padding-bottom: 0.2rem;
			}

			@include lg {
				margin: 0px;
			}
		}
	}
}
