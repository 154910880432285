@import '../../../Styles/index';

.root{
    .accordion-heading{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .indicator{
            padding: 0 0 0 1rem;
        }
    }
    // .accordion-details{
            
    // }
}