@import '../../../../Styles';

.root {
	.enableDisable {
		margin-top: 1.5rem;
	}
	div {
		div {
			label {
				margin-left: 1rem;
			}
			.label {
				margin-left: 5rem;
			}
			select {
				width: 7rem;
				height: 3rem;
				border-radius: 4px;
				border: 1px solid $mid-grey;
				outline: none;
				font-weight: 100;
				background-color: white;
			}
		}
	}
}
