@import '../../../../Styles/';

.root {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
	gap: 1rem;
    grid-row-gap: 0.25rem;
    padding: 8px;
    background: $uy-ex-light-bg;
    .multi{
        @include flex(row, space-between);
    }
    .tile{
        @include flex(row, flex-start, flex-start);
        label{
            width: 200px;
            text-align: start;
            font-weight: 400;
            color: #606060;
        }
        
        .value{
            text-align: start;
            font-weight: 500;
        }

        .inner-obj{
            margin-top: 1rem;
            padding: 4px;
            border: 2px solid #c1c1c1;
            border-radius: 4px;
        }

        .APPROVAL_REJECTED{
            color: red;
        }
    }
}
