@import '../../../../Styles/';

.cpk {
	@include flex(row, flex-start);
	cursor: pointer;

	.subscript {
		margin-top: 0.5rem;
		font-size: 0.8em;
	}

	.sort-icon {
		font-size: 1.25rem;

		&.up {
			transform: rotate(180deg);
		}
	}
}

.legend {
    border-radius: 4px;
    width: 50px;
    height: 25px;
}

.critical {
	background: rgba($pastel-red, 0.5);
	&.text {
		color: rgba($bright-red, 1);
	}
}

.moderate {
	background: rgba($dark-yellow, 0.5);
	&.text {
		color: rgba($dark-yellow, 1);
	}
}

.normal {
	background: rgba($success, 0.5);
	&.text {
		color: rgba($bright-green, 1);
	}
}