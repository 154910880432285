.root {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding: 1rem;
}
.root .image-results {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1.5rem;
}
.root .fallback {
  width: 100%;
  padding: 1rem;
}/*# sourceMappingURL=CookingImagesPage.module.css.map */