@import '../../Styles/';

.root {
	width: 100%;
	@include flex(column);
	.header {
		width: 100%;
		@include flex(row, flex-start);
		padding: 0.75rem;
		border-bottom: 1px solid #e2e2e2;
        font-size: 1.25rem;
	}
    .body{
        width: 100%;
        .accordion{
            width: 100%;
            padding: 0.5rem;
            .summary{
                p{
                    font-weight: 500;
                    color: blue;
                }
            }
            .details{
                
            }
        }
    }
}
