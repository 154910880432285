@import '../../../Styles';

.root {
	width: 100%;
	display: flex;
	flex-direction: column;

	.flow {
		padding: .8rem 0rem;
		width: 100%;
		display: grid;
		align-items: center;
		justify-items: start;
		grid-template-columns: 1fr 1fr;

		.text {
			font-weight: 500;
		}
	}
	.textNote{
		font-weight: 300;
		font-size: smaller;
	}
}