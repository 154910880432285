@import '../../../../Styles/';

.root {
	@include flex(row, flex-start, stretch);
	overflow-x: auto;
	@include xl {
		@include flex(row, center, stretch);
	}
	gap: 1rem;
	height: 180px;
	width: 100%;
	padding: 1rem;
	.left {
		@include flex(column, center, stretch);
		gap: 0.5rem;
		flex-basis: 25%;
		max-width: 150px;

		.stats {
			@include flex(row, space-between, stretch);
			gap: 0.5rem;
		}
		.label {
			@include flex(row, center, stretch);
			@include boxShadow;
			padding: 0.5rem;
			border-radius: 4px;
		}
	}
	.right {
		flex-basis: 85%;
		position: relative;
		overflow: auto;
	}
}
