.root {
  height: calc(100vh - 60px) !important;
  position: relative;
  width: 100%;
}
.root .header {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0px 0px 0px 1rem;
  height: 70px;
}
.root .header .search {
  max-width: 20rem;
}
.root .header .brand-filter {
  margin-right: auto;
}
.root .header .search-field-select {
  margin-right: auto;
  max-height: 2.5rem;
  overflow: hidden;
}/*# sourceMappingURL=WastageHistory.module.css.map */