@import '../../Styles';

.root {
	width: 100%;
	.filters {
		margin: 0.5rem;
	}
	.legend {
		position: sticky;
		left: 96%;
		top: 3.5rem;
		cursor: pointer;
		z-index: 99;
		padding: 0.5rem;
		border-radius: 50%;
		background: rgba(255, 255, 255, 0.7);
		width: 40px;
		height: 40px;
		box-shadow: 0px 0px 5px #a3a3a3;
		@include flex;
		.icon {
			width: 25px;
			height: 25px;
		}
	}
	.summary {
		width: 100%;
		@include flex(column);
		gap: 0.5rem;
		margin-top: 1rem;
	}
}

.header {
	@include flex;
	gap: 0.5rem;
	.query {
		&.show {
			animation: tilt-shaking 0.5s linear forwards;
		}
	}
}
@keyframes tilt-shaking {
	0% {
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(2deg);
	}
	40% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(-2deg);
	}
	60% {
		transform: rotate(0);
	}
	75% {
		transform: rotate(2deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
