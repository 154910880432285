@import '../../../../Styles/';

.root {
	width: 100%;
	@include flex(column, flex-start, flex-start);
	gap: 1rem;
    height: 100%;
	
	.labelled_input {
		width: 100%;
		@include flex(column, flex-start, flex-start);
		// gap: 0.25rem;
		label{
			width: 100%;
			font-weight: 500;
			background: #408e913b;
			text-align: center;
			margin-bottom: 0.25rem;
			border-radius: 2px;
			font-size: 1.25rem;
		}
	}
	.filters{
		width: 100%;
		height: 100%;
		@include flex;
		gap: 1rem;
		.filter {
			width: 100%;
			max-width: 100%;
			// width: 300px;
			height: 100%;
			max-height: 340px;
			@include flex(column, flex-start, flex-start);
			// background: red;
			border: 2px solid rgba($uy-bg, 0.4);
			border-radius: 4px;
			gap: 0.25rem;
			background: #f5f5f52d;
			flex: 1;
			&.product{
				.remove_all{
					cursor: pointer;
					font-weight: 500;
					font-size: 12px;
					color: red;
					border: 1px solid red;
					border-radius: 25px;
					padding: 0 .5rem ;
					display: flex;
					align-items: center;
				}
			}

			&-heading {
				width: 100%;
				font-weight: 500;
				background: #408e913b;
				display: flex;
				justify-content: space-between;
				padding-right: 5px;
				padding-left: 5px;
				margin-bottom: 0.25rem;
				font-size: 1.25rem;
			}
	
			&-body {
				width: 100%;
				height: 100%;
				overflow-y: auto;
	
				.tags {
					width: 100%;
					height: 80%;
					overflow-y: auto;
					@include flex(column, flex-start, flex-start);
					gap: 0.2rem;
	
					.tag {
						width: 100% !important;
					}
				}
			}
		}
	}
}
