@import '../../../Styles/';

.box-annotator {
	width: 100%;
	// height: auto;
	// .img-container {
	// 	// height: 60vh;
	// 	@include md {
	// 		width: 65%;
	// 		// height: 60vh;
	// 	}
	// 	@include landscape {
	// 		// height: 60vh;
	// 		width: 65%;
	// 	}
	// }
}

.annotator-container {
	width: 100%;
	@include md {
		// width: 100%;
		@include flex(row, space-between);
		height: calc(100vh - 60px - 80px - 2rem);
	}
	@include landscape {
		@include flex(row, space-between);
		height: calc(100vh - 60px - 80px - 2rem);
	}
}
