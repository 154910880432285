@import '../../../../Styles/';
.root {
	.input {
		align-items: center;
		.image-container {
			border: none;
			height: 100px;
			width: 133px;
			background-color: $pastel-red;
			border-radius: 10px;
			.image-preview {
				height: 100px;
				width: 133px;
				.image {
					height: 100px;
					width: 133px;
				}
			}
		}
	}
	.refund-amount {
		width: 133px;
	}
	.upload-btn {
		width: 135px;
		margin-top: 1rem;
	}
}
