@import '../../../Styles/index';

$navbar-height: 80px;
$header-height: 60px;
$footer-height: 60px;
$measurementBox-height: 10rem;
$body-height: calc(
	100vh - #{$header-height} - #{$navbar-height} - #{$footer-height}
);

.app-qc{
	@include flex(column, flex, flex-start);
	.body{
		width: 100%;
		// display: grid;
		// grid-template-columns: 1fr 1fr;
		// grid-template-rows: 1fr 1fr;
		height: calc($body-height + 100px);
	}
}

.duplicacy_text{
	font-weight: 500;
	font-size: 1.25rem;
	animation: jump 1s infinite ease-in-out
}

// .root {
// 	@include flex(column);
// 	width: 100%;
// 	.header {
// 		height: $header-height;
// 	}
// 	.body {
// 		@include flex(row, center);
// 		width: 100%;
// 		height: 100%;
// 		@include landscape {
// 			max-height: fit-content;
// 		}

// 		.videoFeed {
// 			flex-basis: 65%;
// 			@include landscape {
// 				height: auto;
// 			}

// 			padding: 1rem;
// 		}
// 		.reference-measurement {
// 			@include flex(column, space-between);
// 			gap: 1rem;
// 			flex-basis: 45%;
// 			padding: 1rem;
// 			.referenceBox {
// 				max-height: 24rem;
// 			}
// 			.measurementBox {
// 				height: $measurementBox-height;
// 			}
// 		}
// 	}
// 	.footer {
// 		height: $footer-height;
// 	}
// }

@keyframes jump {
	0% {
		transform: scale(0.95);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.95);
	}
}
