@import '../../../../Styles';

.root {
    @include flex;
    gap: 1rem;
    align-items: center;

    .headerBrandSelector {
        font-size: small;
    }

    .submit {
        @include flex(column);
        flex-basis: 10%;
        text-transform: uppercase;

        .btn {
            &:disabled {
                svg {
                    color: rgba($primary, 0.4);
                }
            }

            svg {
                font-size: 2.5rem;
                color: $primary;
            }

            animation: scale-in-out 0.3s ease;
            animation-delay: 0.3s;
            animation-iteration-count: 2;
        }

        @keyframes scale-in-out {
            0% {
                transform: scale(1, 1);
            }

            50% {
                transform: scale(1.3, 1.3);
            }

            100% {
                transform: scale(1, 1);
            }
        }
    }
}