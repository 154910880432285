@import '../../Styles/';

.root{
    width: 100px;
    @include flex(column);
    gap: .25rem;
    .input{
        width: 100%;
        border: 1px solid #d2d2d2;
        border-radius: 4px;
        padding: 4px; 
    }
    .toggle-container{
        width: 100%;
        @include flex(row, flex-start, flex-start);
        gap: 2px;
        .toggle{
            width: 100%;
            flex: 1;
            padding: 0 4px;
            border: 1px solid #d2d2d2;
            font-size: 0.8rem;
            border-radius: 2px;
            cursor: pointer;
            &.selected{
                background: $uy-bg !important;
            }
            &.disabled{
                cursor: none;
                background: rgba(184, 184, 184, 0.5);
            }
            .active{
                &:hover{
                    background: rgba($uy-bg, 0.5);
                }
            }
        }
    }
}