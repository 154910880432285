@import '../../../Styles/index';

.root{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    .tile{
        @include flex(row, flex-start);
        .mandatory {
            color: rgb(200, 0, 0);
        }
        label{
            width: 150px;
            text-align: left;
        }
    }
    .multi{
        @include flex(row, flex-start, flex-start);
        gap: 1rem;
    }

    .payment-input-container{
        @include flex(column, flex-start);
        gap: .25rem;
        .pay-full{
            font-weight: 500;
            color: blue;
            cursor: pointer;
        }
        .payment-details{
            @include flex(column, flex-start, flex-start);
            padding: .25rem .5rem;
            border-radius: 12px;
            text-align: left;
            background: rgb(220, 220, 220);
            width: 225px;
            align-self: flex-end;
        }
    }

    .credits{
        width: 100%;
        position: relative;
        input{
            color: black;
        }
        .heading{
            font-size: 1.25rem;
            position: absolute;
            top: 1.25rem;
            left: 0;
        }
        .selected{
            background: green;
            color: white
        }
    }
    .bill-credit-btn{
        @include flex(row, flex-start);
        gap: 1rem;
    }
}

.btns-container{
    width: 100%;
    @include flex;
    gap: 1rem;
}