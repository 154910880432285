@import '../../../Styles/index';

.root{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    .tile{
        @include flex(row, flex-start);
        .mandatory {
            color: rgb(200, 0, 0);
        }
        label{
            width: 150px;
            text-align: left;
        }
    }
    .multi{
        @include flex(row, flex-start);
        gap: 1rem;
    }
    .credits{
        width: 100%;
        .selected{
            background: green;
        }
    }
    .type{
        padding: 0.1rem .5rem;
        border-radius: 8px;
        font-size: 0.75rem;
        font-weight: 500;
        color: white;
        &.ADVANCE{
            background: red;
        }
        &.GRN{
            background: green;
        }
    }

}

.btns-container{
    width: 100%;
    @include flex;
    gap: 1rem;
}