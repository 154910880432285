.root {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  top: 20%;
  right: 0;
}
.root .meal-image {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 275px;
  position: relative;
}
@media (min-width: 425px) {
  .root .meal-image {
    height: 325px;
  }
}
@media (min-width: 500px) {
  .root .meal-image {
    height: 375px;
  }
}
@media (min-width: 576px) {
  .root .meal-image {
    height: 425px;
  }
}
@media (min-width: 768px) {
  .root .meal-image {
    height: 100%;
  }
}
.root .meal-image .defects {
  position: absolute;
  left: 1rem;
  top: 1rem;
}
.root .meal-image .image {
  width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.root .meal-image .meal-results {
  position: absolute;
  height: 100%;
}
.root .meal-image .meal-results .result {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  font-weight: 500;
  padding: 1rem;
}
.root .meal-image .meal-results .result .result-header {
  font-size: 0.8em;
}
.root .meal-image .meal-results .result .result-stat {
  font-size: 1.25em;
}
.root .meal-image .meal-results .result .result-stat svg {
  color: #ff6961;
}
.root .meal-image .meal-results .result .result-stat svg.result-detected {
  color: #66c24d;
}
.root .meal-image .meal-results.vertical {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
}
.root .meal-image .meal-results.horizontal {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
  display: none;
  max-height: 4.5rem;
  bottom: 0;
  left: 0;
  right: 0;
}
.root .meal-image .meal-results.horizontal .result {
  flex: 1;
  max-width: 100px;
}
.root .meal-details {
  display: none;
  flex: 1 2 33.3%;
  width: 100%;
  padding: 0px 1rem;
}
@media (min-width: 768px) {
  .root .meal-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 2 1 66.6%;
    gap: 1rem;
    width: auto;
  }
}
.root .meal-details .row {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
@media (min-width: 768px) {
  .root .meal-details .row {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
}
.root .meal-details .info-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  width: 50%;
  padding: 0.5rem 0px;
}
.root .meal-details .info-col .info-label {
  font-weight: 500;
  text-align: center;
}
.root .meal-details .info-col .info-value {
  font-size: 1.5rem;
}
.root .meal-details .info-col .info-value svg {
  color: #ff6961;
}
.root .meal-details .info-col .info-value svg.detected {
  color: #66c24d;
}/*# sourceMappingURL=MealDetails.module.css.map */