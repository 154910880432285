@import '../../Styles/';

.root {
    // display: flex;
    width: 100%;
    // align-items: center;
    position: relative;
    @include flex(row, space-between, center);
    gap: 1rem;

    .selected {
        background-color: #264653;
        color: #fff;
    }

    .filter {
        @include flex(row, flex-start);
        gap: 1rem;

        .filter-heading {
            font-size: 1rem;
            text-transform: uppercase;
        }

        .filter-body {
            @include flex;
            width: 100%;
            gap: 0.5rem;
            padding: 1rem;

            .tags {
                @include flex(row, center);
                gap: 0.5rem;
                flex-wrap: wrap;
            }

            .add-button {
                background: $white;
                border: 1px solid $primary;

                &:hover {
                    background: $white;
                }
            }
        }
    }

    .indicators{
        @include flex(column);
        &-red{
            @include flex(row, space-between);
            gap: 0.25rem;
            padding: 0.25rem;
            border: 1px solid #F15A59;
            border-radius: 25px;
            .color{
                width: 25px;
                height: 20px;
                border-radius: 25px;
                background: #F15A59;
            }
            p{
                font-size: 0.85rem;
            }
        }
    }
}