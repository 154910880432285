@import '../../../Styles/index';

.root {
	@include boxShadow;
	position: relative;
	width: 100%;
	min-height: 7rem;
	min-width: 7rem;
	max-height: 12.5rem;
	max-width: 12.5rem;
	height: auto;
	border-radius: 4px;

	.selected {
		background-color: #fff9;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.values {
		position: absolute;
		display: flex;
		z-index: .5;
		bottom: 0.5rem;
		width: 100%;
		font-size: small;
		font-weight: 600;
		flex-direction: row;
		justify-content: center;
		gap: 0.5rem;

		div {
			border-radius: 0.2rem;
			padding: 0.1rem;
		}
	}

	.info {
		position: absolute;
		display: inline-flex;
		z-index: 2;
		width: 100%;
		bottom: 0.1rem;
		font-size: small;
		flex-direction: column;
		color: white;
		justify-content: center;
		align-items: center;
		div {
			padding: 0rem 0.1rem;
			border-radius: 0.2rem;
			background-color: rgba(0, 0, 0, 0.671);
		}
	}

	.checkbox {
		background-color: #fffa;
		z-index: 5;
		padding: 0px !important;
		border-radius: 0px !important;
		position: absolute;
		top: 0.1rem;
		left: 0.1rem;
	}

	.identification-type {
		position: absolute;
		top: 3px;
		right: 3px;
		font-weight: 500;
		font-size: 0.8rem;
		padding: 0.2rem;
		color: #eef2e6;
		border-radius: 0.3rem;
	}

	.validated {
		position: absolute;
		top: 1.6rem;
		z-index: 4;
		right: 0px;
		padding: 0.3rem;
		color: rgb(89, 178, 0);
	}

	.confidence {
		position: absolute;
		bottom: 3px;
		right: 3px;
		font-weight: 500;
		font-size: 1rem;
		padding: 0.3rem;
		color: #fff;
		border-radius: 0.3rem;
		background-color: rgb(2, 173, 133);
	}

	// .indicator{
	// 	position: absolute;
	// 	bottom: 3px;
	// 	right: 2.9rem;
	// 	min-width: 1.5rem;
	// 	min-height: 1.5rem;
	// 	border: .2rem solid white;
	// 	border-radius: 0.3rem;
	// 	background-color: rgb(255, 0, 0);
	// }
	.classNameText {
		font-size: smaller;
		position: absolute;
		bottom: -0.1rem;
		left: 0rem;
		color: wheat;
		border: 0.2rem solid white;
		border-radius: 0.3rem;
		background-color: rgba(0, 61, 69, 0.989);
	}

	.labelNameText {
		position: absolute;
		font-size: smaller;
		bottom: -0.1rem;
		left: 0rem;
		color: wheat;
		border: 0.2rem solid white;
		border-radius: 0.3rem;
		background-color: rgba(0, 23, 81, 0.989);
	}

	.feature-count {
		position: absolute;
		top: 3px;
		left: 3px;
		font-weight: 500;
		font-size: 1rem;
		padding: 0.3rem 0.5rem;
		color: #eef2e6;
		background-color: #0000ff;
		border-radius: 0.3rem;
	}

	.AI {
		background-color: #1c6758;
	}

	.MN {
		background-color: #f28f3b;
	}

	img {
		border: 1px solid transparent;
		object-fit: contain;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		max-width: 100%;
	}

	.skeleton {
		position: absolute;
		inset: 0;
		transform: none;
	}

	.overlay {
		@include flex(column);
		cursor: pointer;
		visibility: hidden;
		opacity: 0;
		border-radius: 4px;
		background: rgba($color: $black, $alpha: 0.5);
		color: $white;
		gap: 1rem;

		svg {
			font-size: 2.5rem;
		}

		inset: 0;
		position: absolute;
		transition: all 0.4s;
	}

	&:hover {
		.overlay {
			z-index: 2;
			width: 100%;
			height: 100%;
			visibility: visible;
			opacity: 1;
		}
	}
}
