.accordion-details {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.accordion-details .batch-list {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.prod-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}/*# sourceMappingURL=WorkOrderItemsModal.module.css.map */