@import '../../Styles/';

.root{
    width: 100%;
    height: 100vh;
    background-image: url('../../Images/loginBg.svg');
    background-color: rgba(255, 255, 255, 0.749);
    @include flex(column);
    // position: relative;
    .container{
        width: 60%;
        height: 100%;
        @include flex(column);
        gap: 1rem;

        .success-container{
            width: 400px;
            height: 300px;
            @include flex(column);
            border-radius: 15px;
            background: white;
            box-shadow: 0 0 15px $uy-bg;
            .icon{
                font-size: 10rem;
                color: green;
            }
            .success_header{
                font-size: 3rem;
            }
        }

    }

    .cx_name{
        font-size: 2rem;
        font-weight: 500;
    }

    .po_details{
        width: 100%;
        min-height: 150px;
        border: 2px solid $uy-bg;
        border-radius: 8px;
        backdrop-filter: blur(2px);
        padding: 1rem;
        @include grid;
        grid-template-columns: repeat(2, 1fr);
        background: $uy-ex-light-bg;

        .multi{
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
        }

        .tile{
            @include flex(row, flex-start);
            gap: 1rem;
            font-size: 1.5rem;
            label{
                color: #636363;
            }
            p{
                font-weight: 500;
            }
        }
    }

    .upload_container{
        width: 100%;
        height: 400px;
        // background: white;
        border: 2px solid $uy-bg;
        border-radius: 8px;
        backdrop-filter: blur(2px);
        background: rgba(240, 240, 240, 0.5);
        @include flex(column, flex-start);
        gap: 1rem;
        padding: 0.5rem;
        .header{
            font-weight: 300;
            font-size: 1.5rem;
        }

        .uploader_preview{
            @include flex;
            gap: 1rem;
            width: 100%;
            height: 100%;
            .uploader{
                flex: 2;
                margin-top: 0;
            }
            .preview{
                // width: 40%;
                height: 100%;
                flex: 1;
                border-radius: 8px;
                position: relative;
                .previous{
                    position: absolute;
                    left: 0;
                    top: -1.5rem;
                }
                .full_view_btn{
                    position: absolute;
                    right: 0;
                    top: -1.5rem;
                    background: none;
                }
            }
        }

    }
}