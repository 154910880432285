@import '../../../../../Styles';

.root {
	.enable-disable-container {
		padding: 1rem 0px 6rem 1rem;
		.enableDisable {
			margin-bottom: 1.5rem;
		}
	}
	.label {
		margin-left: 5rem;
	}

	.defect-field{
		@include flex(row, space-between);
		width: 80%;

	}
	.inputWidthOverride{
		width: 120px !important;
	}
}

