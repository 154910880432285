@import '../../Styles';

.root {
	@include flex(column, flex-start);
	margin-right: .25rem;
	padding: 0.25rem;
	border: 1px solid rgba($mid-grey, 0.25);
	border-radius: 4px;

	&:hover {
		@include boxShadow;
		border: 1px solid $mid-grey;
		border-radius: 4px;
	}

	.image-wrapper {
		width: 133px;
		height: 100px;
		border-radius: 4px;
		position: relative;

		.image {
			width: 100%;
			height: 100%;
			border-radius: 4px;
		}

		.delete-icon {
			position: absolute;
			font-size: 1.25rem;
			top: -0.5rem;
			right: -0.5rem;
			cursor: pointer;

			&:hover {
				color: $pastel-red;
			}
		}
	}
	.title {
		// @include applyEllipsis;
		width: 100%;
		max-width: 133px;
		text-transform: capitalize;
		padding: 0.5rem 0px;
	}
}
