@tailwind base;
@tailwind components;
@tailwind utilities;
@import './Styles';


html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	font-size: 10px;
	color: black;
	@include lg {
		font-size: 13px;
	}

	@include rwd(1440) {
		font-size: 16px;
	}
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	// min-height: 100%;
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
a{
	text-decoration: none !important;
	&:hover, &:after, &:before{
		text-decoration: none !important;

	}
}

.rs-picker-default .rs-picker-toggle {
	border-radius: 25px;
	border: 1px solid #1d1d1d;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover{
	border-color: $uy-bg;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active{
	background-color: $uy-bg !important;
	color: black;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-focus, .rs-picker-select-menu-item:focus, .rs-picker-select-menu-item:hover{
	color: black;
	background-color: rgba($uy-bg, 0.5);
}

.rs-picker-toggle-indicator .rs-picker-clean{
	display: none;
}

.list-select-input{
	padding: 0.25rem;
}