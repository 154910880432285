// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// // Tablets and small desktops
$screen-mlg-min: 1024px;

// Large tablets and desktops
$screen-xl-min: 1200px;

@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
}

@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
}

@mixin lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
}
@mixin mlg {
	@media (min-width: #{$screen-mlg-min}) {
		@content;
	}
}

@mixin xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
}

@mixin rwd($screen) {
	@media (min-width: $screen + 'px') {
		@content;
	}
}

@mixin landscape() {
	@media (orientation: landscape) {
		@content;
	}
}