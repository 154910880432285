@import '../../.././../../Styles';

.root {
	@include flex(row);
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 0.25rem;
    .add-icon {
        font-size: 1rem;
    }
}
