@import '../../../Styles';

.root {
	width: 100%;
	@include flex(row);
	gap: 0.5rem;
	.filter {
		width: 100%;
		// width: 300px;
		height: 250px;
		@include flex(column, flex-start, flex-start);
		// background: red;
		border: 1px solid #6fa1ff75;
		border-radius: 4px;
		gap: 0.25rem;
		background: #f5f5f52d;

		&-heading {
			width: 100%;
			font-weight: 500;
			background: #408e913b;
			text-align: center;
			margin-bottom: 0.25rem;
		}

		&-body {
			width: 100%;
			height: 100%;
			overflow-y: auto;
			.tags {
				width: 100%;
				height: 80%;
				overflow-y: auto;
				@include flex(column, flex-start, flex-start);
				gap: 0.2rem;
				.tag {
					width: 100% !important;
				}
			}
		}
	}
}
