@import '../../../../Styles';

.root {
	position: relative;
	&:hover {
		.drag-handle {
			.icon-container {
				visibility: visible;
			}
		}
	}
	.drag-handle {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 130px;
		&.hide {
			display: none;
		}
		&.dragging {
			background: transparent;
		}
		.icon-container {
			@include flex;
			@include boxShadow;
			z-index: 10;
			cursor: move;
			visibility: hidden;
			position: relative;
			margin-top: 0.5rem;
			margin-left: 0.5rem;
			width: 2rem;
			padding: 0.5rem;
			background: $white;
			border-radius: 4px;
			svg {
				font-size: 1.25rem;
			}
		}
	}
}
