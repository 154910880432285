.root {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 1px 5px 0 rgba(23, 26, 41, 0.2), 0 3px 10px 0 rgba(23, 26, 41, 0.2);
  border: 1px solid rgba(23, 26, 41, 0.2);
  padding: 1rem;
}
.root .box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}/*# sourceMappingURL=Header.module.css.map */