@import '../../../../Styles/';
.root {
	@include flex(column, flex-start);
	@include boxShadow;
	border-radius: 4px;
	width: 100%;
	position: relative;
	.header {
		@include flex(column, flex-start, flex-start);
		font-size: 1rem;
		background: #000000BF;
		color: $white;
		width: 100%;
		border-radius: 4px;
		position: relative;

		.title{
			font-size: 1.2rem;
			padding: 5px 5px 0;
		}
		.helper-text{
			position: absolute;
			font-size: 1.5rem;
			top: .5rem;
			right: 50%;
			transform: translate(50%);
		}
		.animate{
			animation: scaleUpDown .2s  infinite alternate ;
		}
		@keyframes scaleUpDown {
			from {
			  transform: scale(1);
			  color: white;
			}
			to {
				color: rgba(209, 255, 163, 0.719);
				transform: scale(.9) ;
			}
		}
		.animateRed{
			animation: scaleUpDownRed .2s  infinite alternate ;
		}
		@keyframes scaleUpDownRed {
			from {
			  transform: scale(1);
			  color: white;
			}
			to {
				color: rgba(255, 163, 163, 0.719);
				transform: scale(.9) ;
			}
		}
		.values{
			@include flex(row, space-between, flex-end);
			width: 100%;
			.ranges {
				.label {
					font-size: 0.75em;
				}
				flex-basis: 20%;
				text-align: center;

				&:last-child{
					border-right: none;
					// border-left: 1px solid #E2E2E2;
				}
			}

			
			.divider{
				background: #fff;
				height: 4rem;
				width: 1px;
			}

			.current-value-container{
				flex-basis: 60%;
				@include flex;
				gap: 1rem;

				.current-value{
					.values{
						@include flex;
						gap: .5rem;
						.red {
							color: #FF1E00;
						}
			
						.blue {
							color: #62CDFF;
						}
			
						.lg {
							font-size: 2rem;
						}
			
						.md {
							font-size: 1.25rem;
						}
					}
	
					.label{
						font-size: 1rem;
						text-align: center;
					}
				}
				
				.icon{
					font-size: 2.5rem;
					
					&.high{
						color: #FF1E00;
					}
					&.low{
						color: #62CDFF;
					}
				}
			}
		}
	}
}
