.root {
    display: flex;
    justify-content: center;
    align-items: center;

    .holder {
        display: inline-flex;
        flex-direction: row;
        column-gap: 2rem;
        padding: .5rem;
        justify-content: space-evenly;
        border-radius: 2rem;
        background: #fff;

        .row {
            background: #e1e1e1;
            display: flex;
            padding: .1rem .3rem;
            border-radius: 2rem;
            flex-direction: row;
            align-items: center;
            
            .color {
                border-radius: 50%;
                padding: .2rem;
                width: 20px;
                height: 20px;
            }
            
            .label {
                padding: 0px .4rem;
                width: calc(100% - 20px);
            }
            
            .fullLabel {
                padding: .1rem .4rem;
                width: 100%;
                font-size: .85rem;
                border-radius: 2rem;
            }
        }
    }
}