@import '../../../../Styles';

.root {
	width: 100%;
	font-size: 0.875rem;
	.form-control-label {
		margin: 0px;
		justify-content: space-between;
		width: 100%;
		span {
			font-size: 0.875rem;
		}
		.switch {
			margin-right: -0.5rem;
		}
	}
    .label {
        width: 50px;
    }
}
