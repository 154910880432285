@import '../../../../Styles/';

.root {
    width: 100%;
    font-size: 13px !important;
    @include flex;
    padding: .25rem;
    border-radius: 8px;
    gap: 0.5rem;
    @include boxShadow;

    .lhs {
        .icon-container {
            @include flex;
            background: #F2E5E5;
            width: 50px;
            height: 50px;
            border-radius: 15px;

            .icon {
                font-size: 2.5rem;
            }
        }
    }

    .rhs {
        line-height: 15px;
        flex: 1;

        .header {
            font-size: 16px !important;
            font-weight: 500;
            margin: 0;
            padding: 0;
        }
        .subHeader {
            font-size: 13px !important;
        }



        // width: 70%;
    }

    .link {
        cursor: pointer;
    }

    .link:hover {
        transform: scale(.95);
    }

    .link:active {
        transform: scale(.85);
    }
}