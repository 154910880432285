@import '../../../Styles/index';

.root {
	// width: 100%;
	// padding: 1rem;
    position: relative;
	display: flex;
	flex-direction: column;
	// height: max-content;
	align-items: center;
	// justify-items: center;
    box-sizing: border-box;
	border-radius: 8px;
	background: #EDF2F4;
	@include boxShadow;
	padding: .5rem;
	justify-content: space-around;
		height: 100%;

	// }
	.chart{
		height: 100%;
	}
	
	.pieChart {
		// height: 90% !important;
        // transform: scale(1.2);
        // height: 115%;
		// width: 85% !important;
	}
	.pieChartScroll {
		// height: 90% !important;
        // transform: scale(1.2);
        // height: 115%;
		// width: 95% !important;
	}
	.title {
		@include flex;
		flex-wrap: wrap;
		font-weight: 500;
		// left: 30px;
		font-size: 18px;
	}
}
.note{
	position: absolute;
    bottom: 5px;
    font-weight: 500;
	color: rgba(0,0,0,.5);
    right: 30px;
	font-size: .7rem;
}
.chartScroll{
	// flex: 1;
	&::-webkit-scrollbar {
		width: .3rem;
		// height: .1rem;
	  }
	   
	  &::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	  }
	   
	  &::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		// outline: 1px solid slategrey;
	  }



	overflow-y: auto;
	height: 240px;
	width: 80%;
	


}

.chart {
	// height: 100%;
	// width: 100%;
	// height: 275px;
	// // width: 275px;
	// min-height: 275px;
	// @include rwd(1400) {
	// 	height: 325px;
	//     // width: 325px;
	// }

	// .pieChart{
	//     height: 85% !important;
	// 	// width: 85% !important;
	// }
	// width: 100%;
	// padding: 2rem;
	// background-color: aqua;
}
