@import '../../../Styles';

.root {
	position: relative;
	.delete-icon {
		position: absolute;
		right: 0.5rem;
		top: 0px;
		cursor: pointer;

		&:hover {
			color: $pastel-red;
		}
	}
	.enabledisable {
		margin-bottom: 0.65rem;
	}
}
