@import '../../Styles/index';
.root {

    .version{
        width: 100%;
        @include flex;
        gap: 1rem; 
        span{
            border-radius: .5rem;
            font-weight: 600;
            // background-color: rgba(223, 223, 223, 0.437);
            background: $uy-ex-light-bg;
            padding: .1rem 1rem;
        }
    }

    .header {
        width: 100%;
        // height: 8rem;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;

        .heading {
            width: 40%;
            height: 10rem;
            display: flex;
            font-size: xx-large;
            font-weight: 600;
            align-items: center;
            justify-content: center;
        }

        .filter {
            width: 60%;
            height: 10rem;

        }
    }
    .list-container{
        width: 100%;
        @include flex(column);
        position: relative;
        overflow-x: auto;

        .bills-strip{
            width: 100%;
            // background: red;
            position: absolute;
            top: 0.5rem;
            padding-right: 15rem;
            @include flex(row, flex-end);
        }
    }
    

    .action-btn{
        font-size: 1rem;
        cursor: pointer;

        &:hover {
            color: $primary;
        }
    }

    .actions{
        @include flex(column);
        gap: .5rem;
        .advance{
            color: red;
            font-weight: 500;
        }
    }
    .row{
        background: white;
        &.PAID{
            background: rgba(0, 122, 0, 0.5);
        }
        &.PARTIALLY_PAID{
            background: rgba(255, 251, 0, 0.5);
        }
        &.APPROVAL_PENDING_MANAGER{
            background: rgba(255, 187, 0, 0.25)
        }
        &.APPROVAL_REJECTED{
            background: rgba(255, 0, 0, 0.5);
        }
        &.CANCELLED{
            background: rgba(255, 0, 0, 0.5);
        }
        &.REJECTED{
            background: rgba(255, 0, 0, 0.25);
        }
    }

    .reupload{
        // color: red;
        font-size: 0.8rem;
        padding: 4px;
        border-radius: 8px;
        border: 1px solid rgba(255, 0, 0, 0.25);
    }

    .footer{
        width: 100%;
        @include flex(row, flex-start);
        gap: 1rem;
        .bill_type{
            @include flex(row, flex-start);
            gap: 1rem;
        }
    }
}