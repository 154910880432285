@import '../../../../Styles';

.root {
	@include flex(row);
	min-width: 100px;
	width: 100%;
	min-height: 30px;
	height: auto;
	background: $dark-grey;
	color: $mid-grey;
	margin-bottom: 0.75rem;
	border-radius: 4px;
	padding: 0.25rem 0.5rem;

	&.active {
		background: $uy-bg;
		color: $black;
	}

	.drag-handle {
		@include flex;
		cursor: move;
		position: relative;

		&.hide {
			display: none;
		}
	}

	.text {
		@include flex(row, flex-start);
		position: relative;
		width: 100%;
		height: 30px;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
	}
}
