@import '../../Styles';

.root {
	@include flex(row, flex-start);
	background: $white;
	width: auto;
	border-radius: 4px;
	border: 1px solid $mid-grey;
	cursor: pointer;
	gap: 0.25rem;
	// white-space: nowrap;
	color: $black;
	flex: 0 0 auto;

	&:hover {
		background: $white;
	}

	.clear {
		font-size: 1.25rem;
		margin-right: 0.25rem;
	}
	.text {
		@include flex;
		width: 100%;
		height: 100%;
		word-wrap: break-word;
		text-align: left;
	}
	&.selected {
		color: $white;
		background-color: $success;
	}
	&.disabled {
		cursor: default;
		&:hover {
			background-color: transparent;
			color: $black;
			text-decoration: none;
		}
	}
	&:hover {
		.text {
			text-decoration: line-through;
		}
	}
	&.disabled {
		&:hover {
			.text {
				text-decoration: none;
			}
		}
		.clear {
			display: none;
		}
	}
}
