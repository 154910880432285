@import '../../../../Styles/index';

.root{
    @include flex(column);
    padding: 0.5rem 1rem;
    position: relative;

    .header-container{
        width: 100%;
        @include flex(row, space-between, flex-start);
        padding: 1rem 0.5rem;
        // background: red;
        border: 2px solid $uy-bg;
        border-radius: 8px;
        .header{
            font-size: 2.5rem;
            font-weight: 600;
            color: #073b4c;
        }

        .template-download{
            // position: absolute;
            // top: 1.25rem;
            // right: 1rem;
    
            button{
                font-size: 0.8rem;
                font-weight: 600;
                padding: 0.5rem 0.75rem;
                border-radius: 10px;
                background: transparent;
                color: #073b4c;
                @include flex;
                cursor: pointer;
                text-transform: uppercase;
            }
        }
    }

    .filters{
        margin-top: 1.25rem;
        width: 100%;
        // @include flex(row, flex-start, flex-start);
        // gap: 1rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        // align-items: center;
    }


    .store-select{
        .select-field{
            min-width: 200px;
        }
    }

    .order-type-select{
        min-width: 200px;
        margin-bottom: 1rem;
    }

    .uploader-container {
        width: 100%;
        @include flex(row);
        gap: 1rem;
    }

    .upload-btn{
        margin-top: 1rem;
        // width: 200px;
        padding: 0.75rem 1rem;
    }
}
