@import '../../Styles/index';

.list-view-root {
	.list-view-header {
		@include flex(row, space-between);
		gap: 1rem;
		padding: .75rem;
		.filter {
			margin-right: auto;
		}	
	}
}
