@import '../../../../../Styles';

.root {
	height: 120px;
	margin: 5px;
	width: 550px;
	border-radius: 4px;
	border: 1px solid $mid-grey;
	padding-left: 1rem;
	padding-top: 1rem;
	padding-bottom: 2rem;
	transition: all 0.4s;
	gap: 0.5rem;
	position: relative;
	.text {
		font-weight: 400;
		font-size: 1.125rem;
		padding-bottom: 2.3rem;
	}
	.label {
		padding-left: 1.2rem;
	}
	.clear {
		font-size: 1.2rem;
		position: absolute;
		top: -6.5px;
		right: -7.5px;
		transition: all 0.4s;
		cursor: pointer;
	}
	&.selected {
		color: $white;
		background-color: $success;
	}
	&.disabled {
		&:hover {
			.text {
				text-decoration: none;
			}
		}
		.clear {
			display: none;
		}
	}
}
@media screen and (min-width: 1067px) {
	.root {
		width: 600px;
	}
}
