@import '../../../../../Styles/';

.root {
	@include flex(row, flex-start);
	min-width: 100px;
	width: auto;
	height: 30px;
	background: $dark-grey;
	color: $mid-grey;
	margin-right: 1rem;
	border-radius: 4px;
	padding: 0.25rem 0.5rem;

	&.active {
		background: $mid-grey;
		color: $black;
	}

	.drag-handle {
		@include flex;
		cursor: move;
		position: relative;

		&.hide {
			display: none;
		}
	}

	.text {
		@include flex(row, flex-start);
		position: relative;
		width: 100%;
		height: 30px;
		cursor: pointer;
	}
}
