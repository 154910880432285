@import '../../Styles/';

.root {
	@include flex(column, flex-start, flex-start);
	gap: .5rem;

	.select-remove-all {
		width: 100%;
		@include flex(row, space-between);
		.select{
			font-weight: 500;
			color: blue;
			text-decoration: underline;
			cursor: pointer;
		}
		.remove{
			color: red
		}
	}


	.list-container{
		@include flex(row, flex-start, flex-start);
		max-width: 475px;
		flex-wrap: wrap;
		gap: 0.25rem;
	}
	.module {
		@include flex;
		width: 150px;
		border-radius: 25px;
		padding: 0.5rem 0;
		background: #bdbdbd;
		cursor: pointer;
		&.selected {
			background: $uy-bg;
            font-weight: 500;
			border: 2px solid $uy-bg;
		}
	}
}
