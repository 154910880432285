@import '../../../../Styles/';
.root {
	.graph-container {
		@include flex(row, space-between, flex-start);
		padding: 0 01rem;
		height: calc(100vh - 265px);
		div {
			flex-basis: 33%;
		}
		.store-revenue {
			max-width: 33%;
			height: 100%;
			max-height: 400px;
			flex: 1;
		}
		.product-revenue {
			max-width: 33%;
			height: 100%;
			max-height: 400px;
			flex: 1;
		}
		.popular-graph-data {
			max-width: 33%;
			height: 100%;
			max-height: 400px;
			flex: 1;
		}
	}
}
