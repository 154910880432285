@import '../../../Styles/';

.root {
	width: 100%;
	position: relative;

	.scroll {
		position: absolute;
		background: $uy-bg;
		padding: .25rem 0.5rem;
        font-size: 13px;
        font-weight: 500;
		z-index: 99;
	}
    .rightS{
		right: 0;
    }
	.leftS {
		left: 0;
	}

	.tab-container {
		width: 95%;
		background: rgb(218, 218, 218);
		overflow: auto;
		scrollbar-width: none; /* For Firefox */
		-ms-overflow-style: none; /* For IE and Edge */
        &::-webkit-scrollbar {
            display: none; /* Hide the scrollbar for WebKit browsers */
        }
		.tab {
			min-width: 150px !important;
			color: black;
			height: 30px !important;
			padding: 4px 6px !important;
			// color: green !important;
			&.active{
				font-weight: 500;
				font-size: 15px;
				// color: red !important;	
			}
		}
	}
}
