@import '../../.././../Styles';

.root {
	@include flex(row);
	min-width: 100px;
	width: auto;
	height: 30px;
	background: $dark-grey;
	color: $mid-grey;
	margin-right: 1rem;
	border-radius: 4px;
	padding: 0.25rem 0.5rem;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.4s;

	&:hover {
		color: $dark-grey;
		background: $mid-grey;
	}

	.add-icon {
		font-size: 1.25rem;
		margin-right: 0.125rem;
	}

	.text {
		@include flex;
	}
}
