.root {
  width: 100%;
  position: relative;
}
.root .heading {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.384);
  letter-spacing: 1.5px;
  font-weight: bolder;
  font-size: larger;
  padding-bottom: 10px;
}
.root .heading .line {
  height: 1px;
  width: 100%;
  flex: 1;
  background: rgba(0, 0, 0, 0.2039215686);
}
.root .downloadCompleteData {
  position: absolute;
  cursor: pointer;
  padding: 0.4rem 0.2rem 0.2rem 0.2rem;
  background: rgba(20, 20, 20, 0.8);
  border-radius: 5px;
  top: -0.5rem;
  right: 0.5rem;
  color: white;
  transition: all;
  transition-duration: 100ms;
  overflow: hidden;
  transform: scale(0.8);
}
.root .downloadCompleteData:hover {
  transform: scale(1.1);
}
.root .container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
@media (min-width: 900px) {
  .root .container {
    grid-template-columns: repeat(4, 1fr);
  }
}/*# sourceMappingURL=DataTileContainer.module.css.map */