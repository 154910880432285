@import '../../../Styles/';
.root {
	@include boxShadow;
	padding: 1rem;
	// height: 100%;
	.heading {
		font-weight: 600;
		text-align: center;
		padding: 1rem;
		font-size: medium;
	}
	.apply-button {
		@include flex;
		.option {
			padding: 1rem;
		}
	}
	.chart {
		padding: 1rem;
	}
}
