@import '../../../Styles';

.root {
	position: relative;
	.details {
		.delete-icon {
			position: absolute;
			right: 0.5rem;
			top: 0px;
			cursor: pointer;

			&:hover {
				color: $pastel-red;
			}
		}
	}
}
