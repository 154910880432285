@import '../../../Styles';

.root {
	@include flex(column, space-between);
	@include absolute;
	outline: none;
	border-radius: 8px;
	width: 100%;
	height: 100%;
	max-height: 99vh;
	padding: .25rem;
	background-color: $white;
	position: relative;
	gap: .25rem;

	.close-button {
		position: absolute;
		right: .5rem;
		top: .5rem;
		cursor: pointer;
		padding: 0px;
		.close-icon {
			width: 1.75rem;
			height: 2rem;
		}
	}
	.back-button {
		position: absolute;
		left: .75rem;
		top: .75rem;
		cursor: pointer;
		padding: 0px;
		.back-icon {
			width: 2rem;
			height: 1.5rem;
		}
	}
	.header {
		text-align: center;
		text-transform: capitalize;
		font-size: 1.5rem;
		// margin-bottom: 1rem;
		padding-bottom: .5rem;
		width: 90%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.body {
		text-align: center;
		height: 100%;
		width: 100%;
		overflow-y: auto;
		max-height: calc(100% - 60px - 2rem);
		padding: .25rem;
		&.full_body{
			max-height: calc(100% - 50px);
		}
	}

	.footer {
		@include flex(row, space-evenly);
		margin-top: 1.25rem;
		width: 100%;
		gap: .5rem;
	}
}
