@import '../../../Styles/index';

.btns{
    @include flex(row);
    .pay_btn{
        width: 150px;
    }
}

.nav_item{
    @include flex(row, space-between);
    p{
        margin: 0;
    }
    .unpaid_text{
        font-size: 0.9rem;
    }
}