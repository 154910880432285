@import '../../.././../Styles/index';

.root {
	box-shadow: none !important;
	outline: none !important;
	width: 100%;
	height: 100%;
	border-bottom: 2px solid rgb(255, 255, 255);
	padding: 0.3rem;
	margin: 5px 0px !important;

	&.completed {
		background-color: rgba(0, 63, 0, 0.897) !important;
		font-weight: 700;
		// color: rgb(0, 112, 0)
		color: white;
	}

    .accordion-heading{
        width: 100%;
        font-size:small ;
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        min-height: 40px !important;
        height: 40px !important;
        max-height: 40px !important;
        .heading{
            @include flex(row, flex-start);
            width: 100%;
            .overriden{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        
    }
    .accordion-details{
        padding: 0;

        .expanded-div{
            // padding: .5rem;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .details{
                flex:1;
            }
            .measure{
                transform-origin: bottom;
                // transform: scale(.7);
                opacity: .8;
                position: absolute;
                bottom: 5px;
                width: 100%;

            }
            .ingredientImage{
                position: relative;
                width: 100%;
                .image{
                    width: 100%;
                }
                .imagePassFail{
                    position: absolute;
                    top: 0px ;
                    left: 0px;
                    width: 100%;
                    .info{
                        display: flex;
                        width: 100%;
                        align-items: center;
                        font-size: larger;
                        font-weight: 700;
                        color: white;
                        background-color: #0006;
                    }
                }
            }
        }
    }
}
