@import '../../../Styles/';
.root{
	width: 100%;
	position: relative;
	height: 100%;
	.filters{
		@include flex(row, flex-start, flex-end);
		gap: 1rem;
		width: 100%;
		.search{
			width: 150px;
		}
	}
	.list{
		table{
			thead{
				th{
					padding: .5rem;
					font-size: .8rem !important;
				}
			}
		}
		&.fullHeight{
			height: calc(100vh - 10rem);
		}
		height: calc(100vh - 18rem);
		// background: red;
		overflow: auto;
		.deltaHigh {
			background: #ffab7acb;
		}
		.updated{
			background: #6499e9cb;
		}
		.skipped{
			background: #fe000078;
			// color: #fff;
		}
	}
	.desc-input{
		width: 50% !important;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

.btns{
	@include flex;
	gap: 1rem;
	width: 100%;
}

.order-qty{
	@include flex;
	gap: .5rem;
	.actual{
		padding: 0.25rem 0.5rem;
		border-radius: 25px;
		background: rgb(223, 223, 223);
		opacity: 0.9;
	}
}

.no-recipe{
	@include flex;
	gap: 0.25rem;
	.add-recipe{
		@include flex;
		padding: 0.25rem;
		border: 1px solid rgb(39, 39, 255);
		border-radius: 25px;
		p{
			font-size: 0.75rem;
		}
		.icon{
			color: rgb(39, 39, 255);
		}
	}
}