@import '../../../../Styles';

.root {
	@include flex(row, flex-start);
	margin: 5px;
	justify-self: center;
	text-align: center;
	.time-container {
		@include flex(column, flex-start, flex-start);
		background: $dark-yellow;
		border-radius: 5px;
		margin: 5px;
		width: 165px;
		height: 64px;
		justify-content: space-around;
		align-items: center;
		padding: 1.25rem;
		position: relative;
		.heading {
			text-align: left;
			width: 100%;
			margin-bottom: 1rem;
		}
		.time-slot{
			width: 100%;
			text-align: left;
		}
		.delete-icon {
			position: absolute;
			font-size: 1.25rem;
			top: -0.5rem;
			right: -0.5rem;
			cursor: pointer;

			&:hover {
				color: $pastel-red;
			}
		}
	}
}
