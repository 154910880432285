@import '../../../../../Styles';

.root {
	width: 100%;
	position: relative;

	.delete-icon {
		// position: absolute;
		// right: 0.5rem;
		// top: 0.5rem;
		cursor: pointer;

		&:hover {
			color: $pastel-red;
		}
	}

	.move-icon {
		// position: absolute;
		// right: 0.4rem;
		// top: 2.75rem;
		cursor: pointer;
		transform: rotate(90deg);

		&:hover {
			color: $primary;
		}
	}
}
