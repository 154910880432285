.root {
  width: 100%;
  height: 100%;
}
.root .dates {
  display: grid;
  grid-template-columns: 30px 30px 30px 30px 30px 30px 30px;
  gap: 0.5rem;
  grid-column-start: 1;
  grid-column-end: 7;
}
.root .dates .date {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #4a70fb;
  border-radius: 50%;
  cursor: pointer;
}
.root .dates .disabled {
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
}/*# sourceMappingURL=CustomDatesPicker.module.css.map */