@import '../../../../Styles';

.root {
	@include flex(column);
	width: 100%;
	.time-display {
		position: relative;
		width: 100%;
		border: 1px solid rgb(0 0 0 / 0.2);
		padding: 2px;
		border-radius: 5px;

		.add-slot {
			width: 100%;
			margin-bottom: 0.5rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.dates-wrapper{
			@include flex(row, flex-start);
			gap: 0.2rem;
		}

		.mouse-hoverer{
			position: absolute;
			top: 12%;
			z-index: 1;
			background-color: white;
			padding: .25rem .25rem 0 .25rem;
			border-radius: 4px	
			
		}

		.days-wrapper{
			@include flex(row, flex-start);
			gap: 0.2rem;
			margin: 10px 0;
			width: 100%;

			.days{
				width: 20px;
				height: 20px;
				background: $dark-yellow;
				border-radius: 50%;
				text-align: center
			}
		}

		.time-container {
			@include flex(row, flex-start);
			background: $dark-yellow;
			border-radius: 5px;
			height: 35px;
			margin-bottom: 0.5rem;
			width: 100%;
			border-color: $grey;
			position: relative;
			.heading {
				width: 100%;
				text-align: center;
			}
			.delete-icon {
				position: absolute;
				top: -0.5rem;
				right: -0.5rem;
				font-size: 1rem;
				cursor: pointer;
				&:hover {
					color: $pastel-red;
				}
			}
		}
	}
}
