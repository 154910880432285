@import '../../Styles';

.root {
	@include flex(column);
	min-width: 133px;
	max-width: 133px;
	height: 100px;
	color: $dark-grey;
	background: rgba($mid-grey, 0.4);
	border: 1px solid $mid-grey;
	border-radius: 4px;
	padding: 0.25rem 0.5rem;
	margin-top: 0.5rem;
	text-transform: uppercase;
	cursor: pointer;
	transition: all 0.4s;

	&:hover {
		background: $success;
		color: $grey;
	}

	.add-icon {
		font-size: 1.25rem;
		margin-bottom: 0.5rem;
	}
}
