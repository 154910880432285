@import '../../../Styles/index';
.root{
    width: 100%;
    position: relative;
    padding: .5rem;
    border-radius:5px ;
    .heading{
        display: flex;
        width: 100%;
        align-items: center;
        gap: 1rem;
        // color: white;
        .line{
            height: 1px;
            width: 100%;
            flex: 1;  
            background: #00000034;
        }
        width: 100%;
        text-align: center;
        color:  rgba(0, 0, 0, 0.384);
        letter-spacing: 1.5px;
        font-weight: bolder;
        font-size: larger;
        padding-bottom: 10px;
    }
    .downloadCompleteData{
        position: absolute;
        cursor: pointer;
        padding: .4rem .2rem .2rem .2rem;
        background: rgba(20,20,20,.8);
        border-radius: 5px;
        
        top: -.5rem;
        right: .5rem;
        color: white;
        transition: all;
        transition-duration: 100ms;
        overflow: hidden;
        transform: scale(.8);

        &:hover{
            transform: scale(1.1);
        }
        .icon{

        }
    }
    .container{
        display: grid;
        // padding: 2rem;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        @include rwd(900){
            grid-template-columns: repeat(4, 1fr);
        }
        // background-color: aquamarine;
    
    }
}