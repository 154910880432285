@import '../../Styles/';

.root {
	@include flex(flex, flex-start, flex-start);
	position: relative;
	gap: 1rem;
	width: 100%;
	height: 100%;
	background-color: #ecf2ff;
	border-radius: 12px;
    padding: .25rem;

	.measurement {
		width: 40%;
		@include flex(column, flex-start, flex-start);
        gap: 1rem;

        .box{
            .header{
                .title{
                    font-size: 2rem !important;
                }
            }
        }

		.qcDone {
			width: 100%;
			padding: 1.25rem 1rem;
			color: #fff;
			@include flex(row);
			border-radius: 4px;
			font-size: 2rem;
			font-weight: 500;

			&.PASS {
				background: #008142e5;
			}

			&.OVERRIDDEN {
				background: #ffb703;
				color: #000;
			}

			&.FAIL {
				background: #ff1e00e5;
			}
		}
	}

	.imageContainer {
		@include flex(column, flex-start);
		gap: 0.25rem;
		height: 100%;
		width: 60%;

		.image {
			@include boxShadow;
			background: $white;

			@include lg {
				min-width: 350px;
			}

			height: 80%;
			max-width: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 12px;
		}

		.footer {
			@include flex;
			padding: 0.5rem;
			border-radius: 4px;
			width: 100%;

			.brand {
				margin-right: 0.5rem;
				width: 3.5rem;
				height: 3.5rem;
				border-radius: 50%;
				object-fit: cover;
			}

			.name-container {
				@include flex(column, flex-start, flex-start);

				.name {
					font-size: 2rem;
					font-weight: 600;
				}
			}
		}
	}
}
