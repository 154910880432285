@import '../../Styles/';

.root {
	@include flex(column);
	border: 1px solid $mid-grey;
	border-radius: 4px;
	padding: 1rem;
	position: relative;
	cursor: pointer;
	transition: 0.5s box-shadow;

	&.lg {
		width: 15rem;
		height: 15rem;
	}

	&.md {
		width: 10rem;
		height: 10rem;
	}

	&.sm {
		width: 7.5rem;
		height: 7.5rem;
	}

	&:hover {
		@include boxShadow;
	}

	&.checked {
		.check-btn {
			color: $success;
		}
	}
	.rotate-img{
		transform: rotate(180deg);
	}
	.check-btn {
		position: absolute;
		left: 0;
		top: 0;
		display: none;

		&.show {
			display: block;
		}
	}

	.title {
		font-size: 1.25rem;
	}
}
