@import '../../../Styles/index';

.root {
	@include flex(row, space-evenly, flex-end);
	@include sm {
		padding: 5rem;
	}
	padding: 2rem;
	gap: 1rem;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	width: 100%;
	background: $white;
}
