@import '../../../../Styles/';

.root {
	width: 100%;
	padding: 0.25rem;
	@include flex(column);
	border: 2px solid $uy-bg;
	border-radius: 8px;
	animation: scale 0.75s infinite ease-in-out;
	p {
		font-size: 1.2rem;
	}
	.helper {
		font-size: 0.9rem;
	}
}

@keyframes scale {
	0% {
		transform: scale(0.985);
		border: 2px solid $uy-bg;
	}
	50% {
		transform: scale(.99);
		border: 2px solid $uy-ex-light-bg;
        background: $uy-ex-light-bg;
	}
	100% {
		transform: scale(0.985);
		border: 2px solid $uy-bg;
	}
}
