@import '../../../Styles/index';

.root {
	// height: 275px;
	// min-height: 275px;

	// @include rwd(1400) {
	// 	height: 325px;
	// }
	@include flex(column, flex-start);
	border-radius: 8px;
	background: #EDF2F4;
	@include boxShadow;
	padding: 0.5rem;
	height: 100%;

}

.highlight {
	@include flex(column, flex-start, flex-start);
	// gap: .5rem;
	position: relative;

	.unity {
		position: absolute;
		top: 2.8rem;
		left: 1rem;
		font-size: 0.9rem;
		font-weight: 600;
	}

	.unitx {
		position: absolute;
		bottom: .5rem;
		right: 46%;
		transform: translate(50% , 50%);
		font-size: 0.9rem;
		font-weight: 600;
		// display: none;
	}

	&-header {
		// @include flex(row, space-between);
		position: relative;
		width: 100%;
		@include flex;

		.previous,
		.next {
			position: absolute;
			font-size: 1.5rem;
			font-weight: 500;
			cursor: pointer;
			background-color: #a9cccc;
			padding: 0.15rem 0.5rem;
			border-radius: 0.5rem;
			@include flex;
			z-index: 1;
		}

		.previous {
			left: 0.5rem;
		}

		.next {
			right: 0.5rem;
		}

		p {
			font-size: 1.5rem;
			font-weight: 500;
		}

		.heading{
			@include flex(row);
			gap: 0.25rem;
		}
	}

	.chart-container{
		width: 100%;
		// flex: 1;
		height: 80%;
		.chart {
			width: 100% !important;
			// flex: 1;
		}
	}
}