@import '../../../Styles';

.user-stores-list-container {
	position: absolute;
	background-color: white;
	border-radius: 5px;
	border: 1px solid rgb(42, 42, 42);
	top: 48px;
	.user-stores-list {
		max-height: 200px;
		overflow-y: auto;
        padding: 0px 2px;
		.list-item {
			padding: 5px;
			border-bottom: 1px solid rgb(42, 42, 42);
			color: black;
			font-weight: 500;
		}
	}
}

.user-stores-list::-webkit-scrollbar {
	width: 8px;
}

.user-stores-list::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.user-stores-list::-webkit-scrollbar-thumb {
	background: #888;
}
