@import '../../../../Styles';

.root {
	@include flex(row, flex-start, flex-end);

	.applicable-variants {
		@include flex(column, flex-start);
		height: auto;
		gap: .45rem;
		width: 130px;
		
		.filler {
			height: 20px;
		}

		.variant-tile-wrapper {
			height: 45px;
			margin-bottom: 0.5rem;
			display: flex;
			gap: .5rem;
			
			.variant-tile {
				text-align: center;
				min-width: 70px;
				background-color: $success;
				padding: 0.3rem .5rem;
				border-radius: 4px;
				color: $white;
				display: block;
				font-size: 0.75rem;
			}
			.svng-bs{
				// border-top: 1px solid #ddd;
				// border-bottom: 1px solid #ddd;
				@include flex(column);
				font-size: 0.75rem;
				p{
					margin: 0;
					padding: 0;
				}
			}
		}

		&.hidden {
			visibility: hidden;
		}

		&.not-delta-pricing {
			.variant-tile-wrapper {
				height: 22.5px;
				margin-bottom: 0.6rem
			}
		}
	}

	.skus-wrapper {
		@include flex(row, flex-start, flex-start);
		overflow-x: scroll;
		// width: 80%;
	}
}
