@import '../../../Styles/index';

.root {
    @include flex(column);
    width: 100%;

    .combine_po{
        background: rgb(82, 82, 255);
        color: white;
    }

    .table{
        overflow-x: scroll;
        .row{
            background: white;
            // &.PENDING{
            //     background: rgba(255, 187, 0, 0.25)
            // }
            &.DISPATCH{
                background: rgba(255, 217, 0, 0.5);
            }
            &.TRANSIT{
                background: #0080807f;
            }
            &.UNLOADING{
                background: rgba(255, 208, 0, 0.479);
            }
            &.VERIFICATION{
                background: rgba(30, 255, 0, 0.25);
            }
            &.FULFILLED{
                background: rgba(0, 128, 0, 0.5);
            }
            &.CLOSED{
                background: rgba(0, 128, 0, 0.5);
            }
            &.CANCELLED{
                background: rgba(255, 0, 0, 0.5);
            }
            &.ABANDONED{
                background: rgba(200, 0, 0, 0.5);
            }
        }
    }
    .header {
        @include flex(row, space-between);
        width: 100%;
        padding: .5rem;
        padding-bottom: 2rem;
    }

    .template-download {
        position: absolute;
        top: 1.25rem;
        right: 1rem;

        button {
            font-size: 0.8rem;
            border: 2px solid #073b4c;
            font-weight: 600;
            padding: 0.5rem 0.75rem;
            border-radius: 10px;
            background: transparent;
            color: #073b4c;
            @include flex;
            cursor: pointer;
        }
    }
    

    .downloadDiv {
        // background: #000;
    }

    .file-upload-container {
        min-width: 250px;
        width: 70%;
        height: 350px;
        margin-top: 2rem;
        background-color: #caf0f8;
        border-radius: 20px;
        padding: 2rem;
        @include flex;
        box-shadow: 0 5px 35px #8ecae6;
        position: relative;

        .file-uploader {
            border: 4px dashed #073b4c;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            @include flex(column);
            gap: 2rem;

            p {
                font-weight: 500;
                font-size: 1.5rem;
                color: #073b4c;

                &.file-name {
                    position: relative;

                    &:hover {
                        background-color: #67959e;
                        padding: 0.75rem 1rem;
                        border-radius: 25px;
                        color: #caf0f8;
                        cursor: pointer;
                    }

                    &:hover::before {
                        content: 'Remove Selected file';
                        position: absolute;
                        top: 0;
                        right: 50%;
                        transform: translate(50%, 0);
                        font-size: 0.75rem;
                        color: #edae49;
                    }
                }

            }

            .file-input {
                position: relative;
                width: 200px;
                cursor: pointer;

                input {
                    width: 200px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    opacity: 0;
                }

                button {
                    width: 100%;
                    padding: 0.5rem 0;
                    border-radius: 10px;
                    font-size: 1.25rem;
                    border: 2px solid #073b4c;
                    color: #073b4c;
                    background: transparent;
                    cursor: pointer;
                    text-transform: uppercase;
                }
            }
        }

        .message-container {
            width: 100%;
            height: 100%;
            border-radius: 20px;
            @include flex(column);
            gap: 2rem;

            p {
                font-size: 1.5rem;
                font-weight: 500;
                text-transform: uppercase;
            }

            &.error {
                background-color: rgba(236, 74, 74, 0.719);
                color: #ffffff;
            }

            &.success {
                background-color: rgba(65, 206, 65, 0.678);
            }
        }

        .upload-file-btn {
            position: absolute;
            // display: none;
            bottom: 0;
            transform: translate(0, 50%);
            width: 250px;

            &.animation {
                animation: topToBottom 1s ease-in-out;
            }

            button {
                width: 100%;
                padding: 0.75rem 0;
                border-radius: 10px;
                font-size: 1.25rem;
                font-weight: 500;
                // letter-spacing: 2px;
                border: none;
                text-transform: uppercase;
                background: $primary;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

@keyframes topToBottom {
    0% {
        //   left:0;
        bottom: 100%;
        width: 0;
        // display: block;
    }

    100% {
        bottom: 0;
        width: 250px;
        // transform: translate(0, 50%);
    }

}