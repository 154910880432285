@import '../../../Styles/';

.root{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: 0.5rem;
    @include md{
        display: grid;
        column-gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
    @include lg{
        grid-template-columns: repeat(5, 1fr);
    }
}