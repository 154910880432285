@import '../../../Styles/';

.root{
    width: 100%;
    height: 100vh;
    @include flex(column);
    background-image: url('../../../Images/loginBg.svg');
    background-color: rgba(255, 255, 255, 0.749);
    .paper{
        @include flex(column, space-between);
        width: 30rem;
        background: rgba(255, 255, 255, 0.295);
        backdrop-filter: blur(2px);
        height: 100%;
        padding: 2rem;
        padding-top: 5rem;
        .form-container{
            @include flex(column);
            background: rgba(255, 255, 255, 0.75);
            padding: 0 1rem;
            border-radius: 0.25rem;
        }
        .client-logo{
            width: 90%;
            height: 5rem;
            object-fit: contain;
        }
        .uyLogo-version{
            @include flex(column);
            justify-self: baseline;
            .logo{
                width: 80%;
            }
            .version{
                font-size: 1.5rem;
            }
        }
    }
}