@import '../../../../Styles/';

.root {
	.header {
		@include flex(row, space-between);
		width: 100%;
		height: 90px;
		border-bottom: 1px solid $mid-grey;

        .search {
            padding-left: 1rem;
            max-width: 18rem;
        }
	}

	.body {
		height: calc(100vh - 60px - 90px);
        overflow-y: auto;
	}
}
