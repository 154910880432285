.root {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  background-color: #1d6f42;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
}
.root img {
  height: 25px;
}
.root p {
  color: white;
  text-transform: uppercase;
}/*# sourceMappingURL=ExcelDownload.module.css.map */