@import '../../Styles/';

.root {
	height: calc(100vh - 60px) !important;
	position: relative;
	.header {
		@include flex(row, space-between);
		gap: 1rem;
		width: 100%;
		padding: 0px 0px 0px 1rem;
		height: 70px;
		.search {
			max-width: 20rem;
		}
		.brand-filter {
			margin-right: auto;
		}
		.search-field-select {
			margin-right: auto;
			max-height: 2.5rem;
			overflow: hidden;
		}
	}
}
