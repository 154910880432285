@import '../../../../Styles/';

.list-table-root {
	width: 100%;
	border-collapse: collapse;
	thead {
		tr {
			background: $mid-grey;
		}

		th {
			text-transform: uppercase;
			font-weight: 400;
			text-align: center;
		}
	}
	tbody {
		width: 100%;
		tr {
			border-bottom: 1px solid $mid-grey;
		}
	}

	th {
		padding: 0.75rem 1.5rem;
	}

	td {
		padding: 1rem 1.5rem;
		text-align: center;
		max-width: 80px;
		input {
			max-width: 60px;
			text-align: center;
			border: 1px solid $mid-grey;
			border-radius: 4px;
			padding: 0.5rem 0.5rem;
			font-size: 0.85rem;
			outline: none;
		}
		.reject {
			color: $pastel-red;
		}
		.approve {
			color: $success;
		}
	}

	.action-item {
		width: 10px;
		text-align: center;
		.action-btn {
			font-size: 1.25rem;
			cursor: pointer;
			transition: color 0.375s;
			&:hover {
				&.clone {
					color: $success;
				}
				&.view {
					color: $primary;
				}
				&.delete {
					color: $pastel-red;
				}
			}
		}
	}
}
