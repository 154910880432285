.rootHolder {
    position: relative;
    transition: all;
    transition-duration: 2s;
    overflow: auto;
    height: calc(100vh - 60px);
    width: 100%;

    .root {
        // width: 100%;
        display: inline-block;
        background-color: rgba(53, 66, 4, 0.19);
        transition: all;
        backdrop-filter: blur(5px);
        transition-duration: 2s;
        z-index: 100;
        position: sticky !important;
        position: -webkit-sticky !important;
        top: 0rem;
        width: 100%;

        .expandIcon {
            position: absolute;
            bottom: -20px;
            background-color: rgba(237, 255, 174, 0.445);
            z-index: 2;
            cursor: pointer;
            border: 1px solid black;
            border-top: none;
            right: 50%;
            transform: translate(50%, 0%);

        }

        .rotateExpandIcon {
            &>path {
                transform-origin: center;
                transform: rotate(180deg);
            }
        }

        .accordion {
            border: none;
            border-radius: 0%;
            box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.396);
            padding: 0px;
            background-color: #0000;
            border-bottom: 1px solid black;

            .accordionDetailsScroller {
                max-height: calc(100vh - 200px);
                height: calc(100vh - 300px);
                border: 1px dashed black;

                width: calc(100vw - 19rem);
                position: relative;

                // height: calc(100vh - 150px);
                &>div:first-child {
                    width: auto;
                    position: relative;
                    height: 100%;

                    // max-width: 100%;
                }

                .searchDiv {
                    position: absolute;
                    top: 10px;
                    display: inline-block;
                    left: 10px;
                    z-index: 10;
                }

                .tools {
                    background-color: black;
                    position: absolute !important;
                    bottom: 0px !important;
                    right: 0px !important;

                    &>* {
                        padding: .2rem;
                        color: white !important;
                    }

                }


                .treeHolder {
                    transition: all;
                    transition-duration: .1s;
                }

                .treeScale {
                    transform: scale(.6);
                }

                .test {
                    // width: 100%;
                    // display: flex;
                }

            }

            .accordionTitle {
                background-color: rgba(237, 255, 174, 0.445);
                ;
                min-height: 0px !important;
                // height: 10px;
                padding: 0px;
                // border-bottom: 2px double #000;

                // background-color: aquamarine;
                .breadcrumb {
                    font-size: 1rem;
                    padding: 0px;
                    margin: 0px;
                    padding-left: 1rem;
                    transform: skew(20deg);

                    .active {
                        background: #000;
                        color: white !important;
                        font-weight: 700;
                        font-size: large;
                    }

                    .breadcrumbItem {
                        color: rgba(255, 255, 255, 0.65);
                        background-color: rgba(0, 0, 0, 0.904);
                        padding: .5rem 1rem;
                        // clip-path: polygon(0 0, 90% 0, 100% 100%, 10% 100%);
                    }

                }
            }

        }

    }

    .data-box-container {
        padding: 1rem;
        background-color: rgba(238, 255, 174, 0.29);
        padding: 1rem;
        gap: 2rem;
        width: 100%;
        transition-duration: 500ms;
    }

    .compressButton {
        position: absolute;
        top: 10px;
        left: 10px;

    }

    .compress {
        transition: all;
        transition-duration: 2s;
        height: 50px !important;
        overflow: hidden;
    }
}

.react-transform-wrapper {
    background-color: #000 !important;
    width: auto !important;
}