@import '../../../../../Styles/index';


.file-upload-container {
	width: 100%;
	height: 300px;
	margin-top: 2rem;
	background-color: #f1f1f1;
	border-radius: 20px;
	padding: 2rem;
	@include flex;
	box-shadow: 0 1px 10px #f1f1f1;
	position: relative;

	.file-uploader {
		border: 2px dashed #073b4c;
		width: 100%;
		height: 100%;
		border-radius: 20px;
		@include flex(column);
		gap: 2rem;

		div {
			font-weight: 500;
			font-size: 1.5rem;
			color: #073b4c;
			transition: .25s;

			&.file-name {
				position: relative;
				&:hover {
					background-color: #ffffff;
					padding: 0.75rem 1rem;
					border-radius: 25px;
					// color: #000000;
					cursor: pointer;
				}
				&:hover::before {
					content: 'Remove Selected file';
					width: 100%;
					position: absolute;
					top: -1rem;right: 50%;
					transform: translate(50%, 0);
					font-size: 0.75rem;
					color: #edae49;
				}
			}
		}

		.file-input {
			position: relative;
			width: 200px;
			cursor: pointer;

			input {
				width: 200px;
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				opacity: 0;
				cursor: pointer;
			}
			button {
				width: 100%;
				padding: 0.5rem 0;
				border-radius: 10px;
				font-size: 1.25rem;
				border: 2px solid #073b4c;
				color: #073b4c;
				background: transparent;
				cursor: pointer;
				text-transform: uppercase;
			}
		}
	}

	.message-container {
		width: 100%;
		height: 100%;
		border-radius: 20px;
		@include flex(column);
		gap: 2rem;

		p {
			font-size: 1.5rem;
			font-weight: 500;
			text-transform: uppercase;
		}

		&.error {
			background-color: rgba(236, 74, 74, 0.719);
			color: #ffffff;
		}

		&.success {
			background-color: rgba(65, 206, 65, 0.678);
		}
	}

	.upload-file-btn {
		position: absolute;
		// display: none;
		bottom: 0;
		transform: translate(0, 50%);
		width: 250px;

		&.animation {
			animation: topToBottom 1s ease-in-out;
		}

		button {
			width: 100%;
			padding: 0.75rem 0;
			border-radius: 10px;
			font-size: 1.25rem;
			font-weight: 500;
			// letter-spacing: 2px;
			border: none;
			text-transform: uppercase;
			background: $primary;
			color: #fff;
			cursor: pointer;
		}
	}
}


@keyframes topToBottom  {
   0% {
    //   left:0;
      bottom: 100%;
      width: 0;
    // display: block;
   }
   
   100% {
      bottom: 0;
      width: 250px;
    // transform: translate(0, 50%);
   }

}