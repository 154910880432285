.front {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	// border-bottom: 2px solid black;
	height: 90px;
	// position: sticky;
}
.text {
	font-weight: 500;
	font-size: 26px;
	margin-left: 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	.text-heading {
		height: 100%;
	}
	.sub-heading {
		font-size: 16px;
		font-weight: 400;
		// display: flex;
		// justify-content: space-between;
		// align-items: center;
		padding-bottom: 0px;
		.sub-heading-post-production {
			background-color: bisque;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-bottom: 15px solid white;
			&:hover {
				cursor: pointer;
			}
			&.selected {
				border-bottom: none;
			}
		}
		.sub-heading-pre-production {
			background-color: #e0efde;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			border-bottom: 15px solid white;
			&:hover {
				cursor: pointer;
			}
			&.selected {
				border-bottom: none;
			}
		}
	}
	p {
		margin: 0;
	}
}
.start {
	width: 100%;
	height: calc(100vh - 49.7px);
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.selectEvent {
	width: 85%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	background: #f1f1f1;
	border-radius: 8px;
	margin-bottom: 5px;
	cursor: pointer;
}
.bt {
	height: 25px;
	width: 49%;
	display: flex;
	border-radius: 6px;
	justify-content: center;
	align-items: center;
	padding: 5px;
	&.selected {
		background-color: #ffb703;
	}
}
.btn-circle {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: #ffb703;
	color: black;
	border: none;
	cursor: pointer;
	font-size: 16px;
	outline: none;
	margin-left: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.start2 {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	// position: sticky;
	// z-index: -1;
	height: calc(100vh - 113.7px);
	overflow: hidden;
	position: relative;
	// border-bottom: 1px solid black;
	&.selectedScm {
		background-color: bisque;
	}
	&.selectedFms {
		background-color: #e0efde;
	}
}
.smallText {
	font-size: 12px;
}
.frontHeading {
	display: flex;
	flex-direction: column;
}
.blank-container {
	display: flex;
	.left-container {
		display: flex;
		flex: 1;
	}
	.right-container {
		display: flex;
		align-items: center;
		flex: 1;
		padding: 0.5rem;
		p {
			font-size: 3rem;
			color: #333333;
		}
	}
}
.left {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-right: 20px;
	// padding: 12px;
	// padding-top: 10px;
	.select-box {
		display: flex;
		border-radius: 8px;
		align-items: center;
		border: 1px solid black;
		width: 100%;
		height: 60%;
		.input-box {
			height: 90%;
			padding-left: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			.select-box-input {
				height: 90%;
				padding: 0.3rem;
				border: none;
				border-radius: 7px;
				outline: none;
			}
		}
		.process-selected {
			height: 90%;
			border-radius: 6px;
			display: flex;
			align-items: center;
			.process-selected-box {
				height: 90%;
				border-radius: 6px;
				border: none;
				outline: none;
			}
		}
	}
}
