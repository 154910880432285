@import '../../../Styles/index';

.accordion-details{
    @include flex(column, flex-start, flex-start);
    .batch-list{
        width: 100%;
        @include flex(row, flex-start, flex-start);
        flex-wrap: wrap;
        gap: 1rem;
    }
}

.prod-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    
}