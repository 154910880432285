@import '../../../../Styles/';

.root {
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: .5rem;
    border: 1px solid #dddddd;
    padding: .5rem .25rem;
    border-radius: 8px;

    .isPaidDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        border: 1px solid rgba(200, 0, 0, 0.13);
        padding: .3rem .5rem;
        border-radius: 8px;
        margin-bottom: 1rem;

        .label {
            color: rgb(200, 0, 0);
            // font-weight: 500;

        }
    }
}