.root {
  box-shadow: none !important;
  outline: none !important;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid rgb(255, 255, 255);
  padding: 0.3rem;
  margin: 5px 0px !important;
}
.root.completed {
  background-color: rgba(0, 63, 0, 0.897) !important;
  font-weight: 700;
  color: white;
}
.root .accordion-heading {
  width: 100%;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  min-height: 40px !important;
  height: 40px !important;
  max-height: 40px !important;
}
.root .accordion-heading .heading {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.root .accordion-heading .heading .overriden {
  display: flex;
  justify-content: center;
  align-items: center;
}
.root .accordion-details {
  padding: 0;
}
.root .accordion-details .expanded-div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.root .accordion-details .expanded-div .details {
  flex: 1;
}
.root .accordion-details .expanded-div .measure {
  transform-origin: bottom;
  opacity: 0.8;
  position: absolute;
  bottom: 5px;
  width: 100%;
}
.root .accordion-details .expanded-div .ingredientImage {
  position: relative;
  width: 100%;
}
.root .accordion-details .expanded-div .ingredientImage .image {
  width: 100%;
}
.root .accordion-details .expanded-div .ingredientImage .imagePassFail {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
.root .accordion-details .expanded-div .ingredientImage .imagePassFail .info {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: larger;
  font-weight: 700;
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
}/*# sourceMappingURL=IngredientAccordion.module.css.map */