@import '../../../../Styles/';
.root {
	.settlement-page-search {
		margin: 5px;
		@include flex(row, space-between);
		.settlement-id-container{
			display: flex;
			@include flex(row, space-between);
			.settlement-id-label{
				font-weight: 600;
				font-size: 1.35rem;
			}
			.settlement-id{
				font-weight: 500;
				font-size: 1.35rem;
				padding-left: .25rem;
			}
		}
	}
	
}
