@import '../../../../Styles/index';

.root {
	@include flex(column);
	position: relative;
	gap: 1rem;
	width: 100%;
	height: 100%;
	background-color: #ECF2FF;
	border-radius: 12px;

	.imageContainer {
		@include flex(column);
		gap: .25rem;
		height: 100%;
		width: 100%;

		.image {
			@include boxShadow;
			background: $white;

			@include lg {
				min-width: 350px;
			}

			height: 23.5rem;
			max-width: 100%;
			width: 100%;
			object-fit: cover;
			border-radius: 12px;
		}

		.vendor_name {
			@include flex;
			padding: 0.5rem;
			background: $white;
			position: absolute;
			top: 0.5rem;
			border-radius: 4px;
			width: auto;
			max-width: 400px;
		}

		.footer {
			@include flex;
			padding: 0.5rem;
			border-radius: 4px;
			width: 100%;

			.brand {
				margin-right: 0.5rem;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				object-fit: cover;
			}

			.name-container {
				@include flex(column, flex-start, flex-start);

				.name {
					font-size: 1.5rem;
					font-weight: 600;
				}
			}
		}

		.reason {
			padding: .25rem;
			// border: 1px solid blue;
			text-align: left;
			font-weight: 500;
			font-size: 1.1rem;
			background: rgba(255, 0, 0, 0.5);
			color: white;
			border-radius: .25rem
		}

		.reasonContainer {
			.reason {
				padding: .1rem;
				// border: 1px solid blue;
				text-align: left;
				font-weight: 500;
				font-size: 0.8rem;
				background: rgba(255, 0, 0, 0.5);
				color: white;
				border-radius: .15rem
			}

		}
		.rtv{
			color: red;
			font-weight: 500;
		}
		.extraData {
			display: flex;
			flex-direction: column;
			width: 100%;
			font-size: small;
			align-items: center;
			background-color: rgb(207, 207, 207);
			border-radius: .5rem;

			div {
				padding: .25rem;
				font-size: small;

				span {
					font-size: small;
					font-weight: 500
				}
			}
		}

		.measurement {
			@include flex(column);
		}
	}

}