@import '.././../../../Styles/';
.root {
	.tag-wrapper {
		@include flex(row, flex-col);
		flex-wrap: wrap;
		gap: 0.5rem;
		.tag {
			height: 30px;
			border-radius: 4px;
			border: 1px solid $mid-grey;
			padding: 0.5rem 1rem;
			cursor: pointer;
			transition: all 0.4s;
			&.selected {
				color: $white;
				background-color: $success;
			}
			&:hover {
				color: $white;
				background-color: $success;
			}
		}
		.add {
			height: 30px;
			border-radius: 4px;
			border: 1px solid $mid-grey;
			padding: 0.3rem 1rem;
			cursor: pointer;
			transition: all 0.4s;
		}
	}

	&.disabled {
		.tag {
			cursor: default;
			&:hover {
				background-color: transparent;
				color: $black;
			}
		}
	}
}