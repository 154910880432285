@import '../../../Styles/';

.root{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    .header{
        width: 100%;
        @include flex(row, space-between);
        padding: 0.5rem;
        border-bottom: 1px solid #a1a1a1;
        .page-back{
            @include flex;
            gap: 0.5rem;
            .page-name{
                font-size: 1.25rem;
            
            }
        }
    }
}