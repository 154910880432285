@import '../../Styles/';

.root {
    @include flex(row, flex-end, flex-start);
    width: 100%;

    .total-container {
        @include flex(column);
        background: #f8df2238;
        gap: 1rem;
        border-radius: 12px;
        padding: 1rem;
        width: 50%;
        .additional_charge_container{
            width: 100%;
            @include flex(column);
            gap: 0.5rem;
            .additional_charge{
                width: 100%;
                @include flex(row, space-between);
                gap: 1rem;
                .tax_price{
                    @include flex;
                    gap: 0.5rem;
                    // .tax_container{}
                    .tax_percentage{
                        width: 50px;
                        padding: 0.5rem 0.5rem .7rem .5rem;
                        border-radius: 8px;
                        border: 1px solid #e2e2e2;
                        outline: none;
                    }

                    .charge_price{
                        position: relative;
                        .tax_amount{
                            position: absolute;
                            bottom: .09rem;
                            color: #00000073;
                            right: .25rem;
                            z-index: 1;
                            font-size: 0.9rem
                        }
                        .remove_charge{
                            position: absolute;
                            right: -3rem;
                            top: .5rem;
                            cursor: pointer;
                            .icon{
                                color: red;
                            }
                        }
                    }
                }
            }
            .add_additional{
                width: 100%;
                @include flex(row, flex-end);
                .btn{
                    @include flex;
                    gap: 0.25rem;
                    background: none;
                    padding: 0.25rem;
                    border-radius: 8px;
                    transition: all .25s;
                    outline: none;
                    &:hover{
                        background: $uy-bg;
                    }
                    // color: $uy-bg;
                }
            }
        }
        .charges {
            padding: 0.5rem 0.5rem .7rem .5rem;
            border-radius: 8px;
            border: 1px solid #e2e2e2;
            outline: none;
            max-width: 10rem;
        }
        .tile {
            width: 100%;
            @include flex(row, space-between);

            p {
                padding: 0;
                margin: 0;
            }

            .title {
                // font-size: 1.25rem;
            }

            .value {
                // font-size: 1.25rem;
            }
            .discount{
                color: green;
                font-weight: 300;
            }

            .container {
                display: flex;
                align-items: center;
                gap: .5rem;
                position: relative;
                .tax {
                    color: #00000098;
                    font-size: 0.9rem;
                }
                

                .appliedTax {
                    position: absolute;
                    bottom: .09rem;
                    color: #00000073;
                    right: .25rem;
                    z-index: 1;
                    font-size: 0.9rem
                }
            }
        }
        .finalAmount{
            border-top: 2px dashed black;
            padding-top: .5rem;
            font-weight: 600;
        }

        .milti-gst {
            @include flex(column, flex-start);
            gap: .5rem;
        }
    }
}