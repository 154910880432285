@import '../../../Styles/index';

.root {
	@include flex;
	svg {
		font-size: 1.25rem;
	}

	.stat {
		@include flex(column);

		.label {
			font-weight: 500;
		}

		.label,
		.value {
			text-align: center;
		}
	}
}
