.root {
  display: inline-block;
  width: 10rem;
  background-color: rgb(255, 255, 255);
}
.root .accordion {
  background-color: rgba(0, 0, 0, 0);
}
.root .accordion .accordionSummary {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(46, 46, 46);
}

.childSelected {
  border: 2px solid rgb(255, 255, 255);
  border: 2px dashed rgb(0, 69, 147);
  animation: scaleUpDown2 2s alternate infinite;
}
.childSelected > * {
  color: rgb(0, 0, 0) !important;
  font-weight: 400;
}

.selected {
  border: 4px solid rgb(0, 69, 147);
  z-index: 1;
  animation: scaleUpDown2 2s alternate infinite;
}
.selected > * {
  color: rgb(0, 0, 0) !important;
  font-weight: 700;
}

@keyframes scaleUpDown {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes scaleUpDown2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}/*# sourceMappingURL=TreeNodeTile.module.css.map */