@import '../../Styles/index';

.root {
	// background-color: red;
	width: 100%;
	height: 100%;
	.dates {
        display: grid;
        grid-template-columns: 30px 30px 30px 30px 30px 30px 30px;
        gap: 0.5rem;
        grid-column-start: 1;
        grid-column-end: 7;
        .date{
            @include flex(row);
            width: 30px;
            height: 30px;
            border: 1px solid $primary;
            border-radius: 50%;
            cursor: pointer;
        }

        .disabled{
            border: 1px solid $mid-grey;
            color: $mid-grey;
        }
	}
}
