@import '../../Styles';

.root {
	@include flex(row, flex-start);
	height: 30px;
	margin: 5px;
	min-width: 120px;
	border-radius: 4px;
	border: 1px solid $mid-grey;
	padding: 0.5rem 1rem;
	cursor: pointer;
	transition: all 0.4s;
    gap: 0.5rem;
    .clear {
        font-size: 1rem;
    }
	&.selected {
		color: $white;
		background-color: $success;
	}
	&:hover {
		.text {
			text-decoration: line-through;
		}
	}
	&.disabled {
		&:hover {
			.text {
				text-decoration: none;
			}
		}
		.clear {
			display: none;
		}
	}
}