@import '../../../../Styles';

.root {
	position: relative;
	background: $white;
	border-radius: 4px;

	.icon-wrapper {
		@include flex;
		position: absolute;
		min-width: 65px;
		right: 0;
		top: 0;
		height: 100%;
		// background: rgba($dark-grey, 0.4);
		border-radius: 4px 0px 0px 0px;
		.select {
			width: 100%;
			height: 100%;
			border-radius: 0px 4px 4px 0px;
			border: 1px solid $mid-grey;
			outline: none;
			background: $white;
			text-align: center;
		}
	}

	.input {
		@include removeNumInputArrows;
		font-size: inherit;
		// text-align: center;
		width: 100% !important;
		height: 100%;
		border-radius: 4px;
		border: 1px solid $mid-grey;
		outline: none;
	}

	&.small {
		width: 50px;
		.icon-wrapper {
			width: 20px;
		}
		.input {
			padding-left: 20px !important;
			padding-top: 0.25rem !important;
			padding-bottom: 0.25rem !important;
		}
	}

	&.medium {
		width: 70px;
		height: 27.5px;
		.icon-wrapper {
			width: 30px;
		}

		.input {
			padding-left: 30px !important;
			padding-top: 0.5rem !important;
			padding-bottom: 0.5rem !important;
		}
	}
	&.large {
		width: 120px;
		height: 35px;
		.icon-wrapper {
			width: 40px;
		}

		.input {
			padding-right: 65px !important;
			padding-top: 0.5rem !important;
			padding-bottom: 0.5rem !important;
		}
	}
}
