@import '../../../../Styles/';

.root {
	padding: 1rem;
	.search-btn {
		padding: 0.25rem;
		border-radius: 50%;
		background: #068fff;
		color: #fff;
		margin-right: 0.1rem;
	}
}
