@import '../../../Styles/index';

$navbar-height: 80px;
$header-height: 60px;
$footer-height: 60px;
$measurementBox-height: 10rem;
$body-height: calc(
	100vh - #{$header-height} - #{$navbar-height} - #{$footer-height}
);

.root {
	@include flex(column);
	width: 100%;
	.header {
		width: 100%;
	}
	.body {
		@include flex(row, flex-start, flex-start);
		width: 100%;
		height: 100%;
		@include landscape {
			max-height: fit-content;
		}

		.videoFeed {
			flex-basis: 65%;
			height: 100%;
			min-height: calc($body-height - 5rem);
			position: relative;
			background: rgba(0, 0, 0, 0.9);
			box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
			border-radius: 5px;
			padding: 5px;
			@include landscape {
				height: auto;
			}

			padding: 1rem;
		}
		.reference-measurement {
			@include flex(column, space-between);
			gap: 0.5rem;
			flex-basis: 35%;
			padding: 1rem;
			height: 45rem;

			.referenceBox {
				height: 100%;
				width: 100%;
			}
		}
	}
}
