@import '../../Styles';

.check-list {
	@include flex(column, flex-start, stretch);
	width: 100%;
	min-height: 0;
	height: 100%;
}

.check-list-item-container {
	min-height: 0;
	overflow-y: auto;
}

.search-bar {
	margin-bottom: 1rem;
	width: calc(100% - 10px) !important;
}
