@import '../../../Styles/index';

.root{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    padding: 1rem;
    .tile{
        @include flex(row, flex-start);
        .mandatory {
            color: rgb(200, 0, 0);
        }
        label{
            width: 150px;
            text-align: left;
        }
    }
    .multi{
        @include flex(row, flex-start);
        gap: 1rem;
    }
    .note{
        color: red;
        font-weight: 500;
    }

    .selector{
        border: 1px solid #d2d2d2;
        border-radius: 12px;
    }

}

.btns-container{
    width: 100%;
    @include flex;
    gap: 1rem;
}