@import '../../Styles/';

.root{
    width: 100%;
    padding: .5rem;
    .list {
		overflow: auto;
	}
    .rejected{
        color: red;
        font-weight: 500;
        @include flex(column, flex-start, flex-start);
        // gap: .25rem;
        min-width: 150px;
        p{
            margin: 0
        }

    }
    .reference{
        @include flex;
        cursor: pointer;
        .icon{
            &:hover{
                color: blue;
            }
            font-size: 1.25rem;
        }
    }

    .btns{
        width: 150px;
        @include flex(column);
        gap: 0.5rem;
    }

    .UNPAID{
        background: white;
    }
    .PAID{
        background: rgba(0, 128, 0, 0.5);
    }
    .APPROVAL_PENDING_MANAGER{
        background: rgba(255, 187, 0, 0.25);
    }
    .APPROVAL_PENDING_FINANCE{
        background: rgba(255, 187, 0, 0.5);
    }

    .REJECTED{
        background: rgba(255, 0, 0, 0.25);
    }
    &.PENDING{
        background: white;
    }
    &.CANCELLED{
        background: rgba(255, 0, 0, 0.5);
    }
}