@import '../../../Styles/';

.root{
    .type{
        padding: 0.1rem .5rem;
        border-radius: 8px;
        font-size: 0.75rem;
        font-weight: 500;
        color: white;
        @include bill_type_colors;
    }
    .icon{
        cursor: pointer;
    }
}