@import '../../../../Styles/';

.root {
	width: 100%;
	padding: 5px;
	display: flex;
	align-items: center;
	border: 1px solid $mid-grey;
	border-radius: 4px;
	margin-bottom: 10px;
	cursor: pointer;
	transition: all 0.5s;

	&.selected {
		background-color: rgb(100, 240, 40, 0.5);

        .img-container {
            border: none;
        }
	}

	&:hover {
		background-color: rgb(100, 240, 40, 0.5);
	}

	.img-container {
		width: 23px;
		height: 23px;
		border: 1px solid $mid-grey;
		border-radius: 50px;
		cursor: pointer;
	}
	.brand-img-container{
		width: 60px;
		height: 60px;
		margin: 0 1rem;
	}
	.check-img {
		clip-path: circle(15px at center);
		margin: 0 auto;
		width: 100%;
		height: 100%;
        border-radius: 50px;
	}

	.title {
		margin-left: 10px;
	}
}
