@import '../../../../Styles/';
.root {
	@include boxShadow;
	width: 100%;
	border-radius: 12px;
	max-width: 200px;
	padding: 0.2rem;
	.amount-container {
		@include flex(column);
		height: 100px;
		position: relative;
		border-radius: 8px;
		overflow: hidden;
		.amount {
			font-weight: 500;
			font-size: 2rem;
			width: 100%;
			text-align: center;
			text-overflow: ellipsis;
			max-width: 100%;
			overflow: hidden;
			white-space: nowrap;
			padding: 0.25rem;
			padding-bottom: 0;
		}
		.percentage {
			position: absolute;
			bottom:0rem;
			right: 0rem;
			padding-right: 0 .1rem .1rem .1rem;
			font-weight: 500;
			font-size: .9rem;
			text-align: center;
			text-overflow: ellipsis;
			overflow: hidden;
			background-color: rgb(230, 255, 105);
			color:rgba(0, 0, 0, 0.903);
			padding: 0 .1rem;
			white-space: nowrap;
			border-radius: .5rem 0 0 .5rem;
			
			margin-top: 0;
		}
		.icon-container {
			position: absolute;
			top: -0.5rem;
			left: -0.5rem;
		}
	}
	.caption {
		font-weight: 500;
		width: 100%;
		text-align: center;
		margin-top: 0.5rem;
	}
}
