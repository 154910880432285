@import '../../../../Styles';

.root {
	width: 100%;
	height: auto;
	min-height: 350px;
	background: rgba($mid-grey, 0.4);
	border: 1px solid $mid-grey;
	border-radius: 4px;
	padding: 1rem;
	margin-bottom: 1.25rem;
	position: relative;

	.switch-wrapper {
		position: absolute;
		right: 0.5rem;
		top: 0.5rem;
	}

	.delete-icon {
		position: absolute;
		right: 0.5rem;
		bottom: 0.5rem;
		cursor: pointer;

		&:hover {
			color: $pastel-red;
		}
	}

    .help-icon {
        font-size: 1rem;
        margin-left: -1rem;
    }
}
