.root {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background: rgba(74, 112, 251, 0.2274509804);
  border-radius: 10px;
  border: 2px solid #4a70fb;
  width: 100%;
  padding: 1rem;
  position: relative;
  transition-duration: 2s;
}
.root .storeDiv {
  display: flex;
  flex-direction: column;
}
.root .store-select {
  min-width: 300px;
  max-width: 350px;
  background: white;
}
.root .status-select {
  min-width: 150px;
}
.root .status-select .select {
  width: 100%;
}
.root .filter {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.root .filter .filter-heading {
  font-size: 1rem;
  text-transform: uppercase;
}
.root .filter .filter-body {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  padding: 1rem;
}
.root .filter .filter-body .tags {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.root .filter .filter-body .add-button {
  background: #ffffff;
  border: 1px solid #4a70fb;
}
.root .filter .filter-body .add-button:hover {
  background: #ffffff;
}
.root .seperator {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: #171a29;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
}
.root .seperator svg {
  font-size: 2rem;
}
.root p {
  font-size: 1.2rem;
  font-weight: 500;
}
.root p .selectedVendor {
  font-size: smaller;
  display: flex;
  max-width: 200px;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  row-gap: 0.2rem;
  flex-wrap: wrap;
}
.root p .selectedVendor div {
  background-color: rgba(4, 0, 255, 0.164);
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
}
.root p span {
  font-size: 0.75rem;
  border-radius: 10px;
  padding: 0.25rem 0.35rem;
  background-color: #4a70fb;
  color: #fff;
  margin-right: 0.25rem;
}
.root .search-icon {
  width: 50px;
  height: 50px;
  background: #4a70fb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.root .search-icon .icon {
  color: #fff;
}
.root .hide-icon-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 5px 20px #c2d2c2;
}
.root .hide-icon-container .icon {
  font-size: 1.25rem;
}/*# sourceMappingURL=WorkOrderListHeader.module.css.map */