@import '../../../Styles';
.root {
	@include flex(column, flex-start, stretch);
	gap: 0.5rem;
	padding: 1rem;
	.QRCode {
		cursor: pointer;
	}
	.asset_details {
		display: inline-block;
	}
	.image-results {
		@include flex(column, flex-start, flex-start);
		gap: 1.5rem;
	}

	.fallback {
		width: 100%;
		padding: 1rem;
	}

	.numbers {
		display: flex;
		width: 100%;
		padding: 0.2rem 0.1rem;
		// background: #b3ffbb;
		align-items: center;
		justify-content: center;
		gap: 1rem;
		.element {
			border-radius: 0.5rem;
			padding: 0.1rem 1rem;
			background: #0f3d3e;
			color: white;
			display: flex;
			align-items: center;
			span {
				width: 30px;
				text-align: center;
				font-weight: 800;
			}
		}
	}
}
