@import '../../../../Styles';

div {
	div {
		.table-btn-container {
			display: block;
			.add-btn {
				@include flex(row, flex-end);
				right: 0;
				top: 0;
			}
		}
	}
	div {
		.list-table-root {
			border-collapse: collapse;
			thead {
				tr {
					background: $mid-grey;
					border: 1px solid $mid-grey;
				}
				th {
					text-transform: uppercase;
					font-weight: 400;
					text-align: center;
				}
			}

			tbody {
				width: 100%;
				border: 1px solid $mid-grey;
				width: 100%;
				tr {
					border-bottom: 1px solid $mid-grey;
				}
			}

			th {
				padding: 0.75rem 1.5rem;
			}

			td {
				padding: 1rem 1.5rem;
				text-align: center;
				div {
					input {
						max-width: 60px;
						text-align: center;
						border-radius: 4px;
						padding: 0.5rem 0.5rem;
						font-size: 0.85rem;
						outline: none;
					}
					label {
						cursor: pointer;
					}
					img {
						width: 50%;
					}
				}
			}

			.action-item {
				width: 10px;
				text-align: center;
				.action-btn {
					font-size: 1.25rem;
					cursor: pointer;
					transition: color 0.375s;
					&:hover {
						&.delete {
							color: $pastel-red;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
