.root {
  height: 165px;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #eff5f5;
  box-shadow: 0 1px 5px 0 rgba(23, 26, 41, 0.2), 0 3px 10px 0 rgba(23, 26, 41, 0.2);
  border: 1px solid rgba(23, 26, 41, 0.2);
}
.root .header {
  font-weight: 500;
  font-size: 1.1rem;
  padding: 0px 0px 5px 0px;
}
.root .content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}
.root .content .icon {
  justify-self: end;
  font-size: 4.5rem;
  font-weight: 500;
}
.root .content p {
  word-break: break-all;
  font-weight: 500;
  font-size: 2rem;
}
.root .content .pieChart {
  width: 75px !important;
  height: 75px !important;
  transform: scale(1);
}
.root .content .smallPieChart {
  width: 23px !important;
  height: 23px !important;
  transform: scale(1);
}
.root .content .generic {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 4px 8px;
  border-radius: 5px;
  background: rgba(59, 61, 63, 0.0470588235);
  opacity: 0.8;
  flex-direction: column;
  justify-content: space-around;
}
.root .content .generic .item {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}
.root .content .generic .item .tooltipIcon {
  font-size: 3rem;
}/*# sourceMappingURL=DataTile.module.css.map */