@import '../../../Styles/index';

.root{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    .tile{
        @include flex(row, flex-start);
        .mandatory {
            color: rgb(200, 0, 0);
        }
        label{
            width: 150px;
            text-align: left;
        }
    }
    .multi{
        @include flex(row, flex-start);
        gap: 1rem;
    }
    .address-container{
        width: 100%;
        @include flex(row, space-between);
        padding: 1rem;
        border-radius: 8px;
        border: 1px solid #aaaaaa;
        .billing, .shipping {
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
            .header-container{
                @include flex(row, flex-start);
                gap: 0.5rem;
                .copy-add{
                    @include flex(row, flex-start);
                    gap: 0.25rem;
                    color: rgb(60, 60, 255);
                    font-size: .75rem;
                    border: 1px solid rgb(60, 60, 255);
                    border-radius: 25px;
                    padding: 0.25rem;
                    cursor: pointer;
                }
            }
            .header{
                font-size: 1.25rem;
                font-weight: 500;
            }
            .input-container{
                @include flex(column, flex-start, flex-start);
                gap: 0.5rem;
            }
        }
    }
}

.btns-container{
    width: 100%;
    @include flex;
    gap: 1rem;
}