.arrow {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translate(0%, 0);
  color: white;
  font-size: larger;
  padding: 0px 0px;
  background-color: rgba(255, 255, 255, 0.282);
  border-radius: 50%;
  z-index: 5
}

.root {
  width: 100%;
  // overflow-x: auto;
  height: 100%;
  overflow-x: hidden;
  position: relative;


  // z-index: 10000;
  .list {
    overflow-x: auto;

    // transform: rotate(180deg);
    padding: 2px;
    // overflow-y:visible;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 5px;

    // width: 100%;
    // background: rgb(2, 0, 36);
    // background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 4%, rgba(4, 121, 195, 0) 96%, rgb(255, 255, 255) 100%);
    border-right: none;
    border-left: none;

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0 !important;
    }

    height: 100%;



    .item {
      transform-origin: right;
      // opacity: .7;
      padding: 5px;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 70px;
      position: relative;
      display: flex;
      height: 90px;
      // border: 1px solid rgba(128, 128, 128, 0.66);
      border: 1px solid white;
      // border-right: 2px solid white;
      flex-direction: column;
      border-radius: .2rem;
      color: white;
      background-color: rgba(42, 42, 42, 0.296);

      .img {
        flex: 1;
        position: relative;
        // height: 55px;
        height: 20px;
        width: 65px;
        overflow-y: hidden;

        .completedText {
          font-size: xx-small;
          font-weight: 700;
          // width: 100%;
          padding: 2px 5px;
          background: rgba(0, 0, 0, 0.654);
          border-radius: 20%;
          text-align: center;
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }

      .name {
        font-weight: 500;
        font-size: .6rem;
        flex: .1;
        width: 100%;
      }

      .Number {
        position: absolute;
        padding: 2px;
        font-size: x-large;
        background: #0009;
        color: white;
        border-radius: 2px;
        top: 5px;
        left: 5px;
      }

    }
  }
}