@import '../../../Styles/';

.filter {
	width: calc(100% / 4);
	min-width: calc(100% / 3);
	max-width: 250px;
	// width: 300px;
	height: 250px;
	@include flex(column, flex-start, flex-start);
	// background: red;
	border: 1px solid rgba($uy-bg, 0.4);
	border-radius: 4px;
	gap: 0.25rem;
	background: #f5f5f52d;

	&-heading {
		width: 100%;
		font-weight: 500;
		background: #408e913b;
		text-align: center;
		margin-bottom: 0.25rem;
	}

	&-body {
		width: 100%;
		height: 100%;
		overflow-y: auto;

		.tags {
			width: 100%;
			height: 80%;
			overflow-y: auto;
			@include flex(column, flex-start, flex-start);
			gap: 0.2rem;

			.tag {
				width: 100% !important;
			}
		}
	}
}
