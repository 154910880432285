@import '../../../../Styles/index';

.root {
	@include flex(column, flex-start, space-between);
	padding: 1.5rem;
	gap: 0.1rem;
	position: absolute;
	inset: 0;
	top: 0;

	.ingredients-images {
		position: relative;
		width: 100%;
		z-index: 999;
		// top: 0;
	}

	.itemsBar {
		position: absolute;
		display: flex;
		flex-direction: column;
		right: 10px;
		width: 7rem;
		height: 50%;
		border: 2px solid white;
		border-left: none;
		border-right: none;
		padding: 2px 10px;
		// background: #0003;
		top: 10rem;
	}

	.wastageReasons {
		width: 100%;
		gap: 0.4rem;
		border-radius: 0.25rem;
		padding: 0.25rem 0.25rem;
		background-color: #00000077;
		display: grid;
		border: 1px solid white;
		grid-template-columns: repeat(4, 1fr);

		.text {
			padding: 0.1rem 1rem;
			font-weight: 500;
			color: white;
			width: 100%;
			text-align: center;
		}

		.reason {
			position: relative;

			div {
				position: absolute;
				right: 2px;
				top: 2px;
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				background-color: #ffb703;
			}

			width: 100%;
			padding: 0.1rem;
			// border: 1px solid blue;
			text-align: left;
			text-align: center;
			font-weight: 500;
			font-size: 0.8rem;
			background: rgba(255, 0, 0, 0.5);
			color: white;
			border-radius: 0.25rem;
		}
	}

	.qcDone {
		width: 100%;
		padding: 1.25rem 1rem;
		color: #fff;
		@include flex(row, space-between);
		border-radius: 4px;
		position: relative;
		font-size: 1.25rem;
		font-weight: 500;
		z-index: 99;

		&.PASS {
			background: #008142e5;
		}

		&.OVERRIDDEN {
			background: #ffb703;
			color: #000;
		}

		&.FAIL {
			background: #ff1e00e5;
		}
	}

	.mark_unmark_wrapper {
		.unmark_btn {
			@include boxShadow;
			@include flex;
			// gap: .25rem;
			width: 50px;
			height: 50px;
			padding: 0.5rem;
			border-radius: 50%;
			background: $primary;
			position: relative;
			z-index: 999;
			.icon {
				width: 100%;
			}
			// .icon{
			// 	width: 30px;
			// 	height: 30px;
			// }
		}
	}

	.duplicate {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 99;
		border-radius: 10px;
		background-color: rgb(0 0 0/0.4);

		.wrapper {
			width: 350px;
			height: 400px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			.text-wrapper {
				color: red;
				font-size: 2rem;
				font-weight: 800;
				letter-spacing: 1px;
				text-transform: uppercase;
				border-radius: 10px;
				text-align: center;
			}
		}

		img {
			height: 50%;
		}
	}

	.marked_by {
		position: absolute;
		bottom: 1rem;
		left: 50%;
		transform: translate(-50%);
		font-size: 1rem;
		padding: 0.5rem 0.75rem;
		border-radius: 12px;
		background: #0000009e;
		color: white;
	}
	
	.action-wrapper {
		@include flex(row, flex-start);
		gap: 0.5rem;
		color: $white;
		max-width: 10rem;

		.link {
			text-decoration: none;
			// width: 100%;
			// max-width: 10rem;
		}

		.action {
			// width: 100%;
			// max-width: 5rem;
			padding: 0;
			width: 50px;
			height: 50px;
			min-width: 40px;
			border-radius: 50%;
		}

		&.features {
			margin-top: 1rem;
		}

		&.retag {
			.status {
				background: $success;
				color: $white;
				align-items: center;
				display: flex;
				padding-left: 2px;
				padding-right: 2px;
				border-radius: 0.5rem;
			}

			.statusAi {
				padding: 0.2rem;
				border-radius: 0.2rem;
				color: $white;
				animation: glowAi 3s ease-in-out infinite alternate;
			}

			@keyframes glowAi {
				from {
					color: rgb(255, 255, 255);
				}

				50% {
					color: rgb(255, 255, 255);
				}

				to {
					color: rgb(0, 0, 0);
				}
			}

			.statusMn {
				padding: 0.2rem;
				border-radius: 0.2rem;
				color: rgb(135, 88, 0);
				animation: glowAi 3s ease-in-out infinite alternate;
			}

			@keyframes glowAi {
				from {
					color: rgb(135, 88, 0);
				}

				50% {
					color: rgb(114, 78, 0);
				}

				to {
					color: rgb(0, 0, 0);
				}
			}
		}
	}

	.first-time-validate {
		@include flex(column);
		gap: 1rem;
		max-width: 15rem;
		z-index: 999;

		.validate-label {
			@include dropShadow;
			font-size: 1.2rem;
			font-weight: 600;
			// background-color: aqua;
			// width: 200px;
			color: $white;
		}

		.validate-actions {
			align-self: flex-start;
			@include flex;
			gap: 1rem;

			.validate-action {
				@include boxShadow;
				color: $white;

				&.valid {
					background: $success;
				}

				&.not-valid {
					background: $red;
				}
			}
		}
	}

	.footer {
		@include flex(row, space-between);
		margin-top: auto;

		.identification {
			cursor: default;
			margin-top: auto;
			margin-bottom: 12.5%;
			max-width: 50%;
			font-size: 1.25rem;
			font-weight: 600;
			color: $white;
		}

		.container {
			width: 120px;
			height: 120px;
			position: relative;
			border-radius: 5px;
			cursor: pointer;
			border: 5px solid rgb(0, 170, 43);

			&:hover {
				transform: scale(1.5);
				transform-origin: bottom right;
				transition-duration: 1s;
			}

			.image {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 5px;
				object-position: center;
			}

			.name-weight {
				width: 100%;
				position: absolute;
				border-radius: 5px;
				bottom: 0;
				background: rgba(0, 0, 0, 0.75);
				color: #fff;
				font-weight: 500;
				font-size: 0.9rem;
				padding: 0.25rem;
			}
		}
	}

	.stat {
		@include stat-box;
		@include flex(column);
		max-width: 10rem;
	}

	&.PASS {
		.stat {
			background: $success;
		}
	}

	&.SKIPPED {
		.stat {
			background: $dark-grey;
		}
	}

	&.OVERRIDDEN {
		.stat {
			background: $dark-yellow;
		}
	}

	&.FAIL {
		.stat {
			background: $red;
		}
	}
}

@keyframes jump {
	0% {
		transform: scale(0.95);
	}
	50% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.95);
	}
}
