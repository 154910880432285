@import '../../../Styles';

.root {
	@include flex(row, flex-start);
	background-color: #fff;
	border-radius: 14px;
	height: 50px;
	padding: 1rem;
	margin-bottom: 1.275rem;
	overflow-x: auto;

	@include customScrollBar;
}
