@import '../../../../Styles';

.root {
	.header {
		@include flex(row, flex-end);
		width: 100%;
	}
	.view {
		font-size: 1.25rem;
		cursor: pointer;
		transition: color 0.4s;

		&:hover {
			color: $primary;
		}
	}
	.danger{
		background-color: red;
	}
}

.items-container{
	@include flex(column);
}

.summary-text{
	font-size: 0.9rem;
	color: red;
	text-align: center
}

.multi-items{
	@include flex(row, space-between);
	min-width: 150px;
	border: 1px solid #dddddd;
	padding: .25rem;
	&.less{
		background: #F15A59;
	}
	p{
		margin: 0;
	}
}