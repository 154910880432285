@import '../../Styles/index';

.root{
	width: 100%;
	height: 100%;
	position: relative;
	@include flex;
	gap: 1rem;

	.generate-btn{
		position: absolute;
		top: 1.25rem;
		right: 6rem;
		z-index: 99;

	}

	.orders_count{
		position: absolute;
		bottom: 0;
		right: 0;
		background: #f1f1f1;
		@include flex;
		gap: .5rem;
		padding: 1rem;
		border-radius: 12px;
		z-index: 99;
		&.shifted{
			right: 400px;
		}
		.count{
			@include flex;
			gap: .5rem;
			&.pending{
				.container{
					background: $uy-bg;
				}
			}
			&.completed{
				.container{
				background: rgb(0, 157, 0);
					
				}
			}
			.container{
				width: 25px;
				height: 25px;
				border-radius: 50%;
				font-size: .9rem;
			}
		}
	}

	.filters-container{
		position: absolute;
		top: 1rem;
		left: 1rem;
		z-index: 99;
		@include flex;
		gap: 1rem;

		.custom-datepicker{
			width: 200px !important;
			outline: none !important;
			padding: 10px 0 !important;
			border: 2px solid #dddddd;
			border-radius: 8px;
		}
		.store-select{
		}
		.date_type{
			@include flex;
			gap: 1rem;
		}
	}
}