@import '../../../Styles/index';

.root {
	width: 100%;
	padding: 1rem 1.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    @include flex(row, space-between);
	
	.header-text{
		@include flex(row, flex-start);
		gap: 1.5rem;
		width: 60%;
		h1 {
			font-size: 1.5rem;
			font-weight: 500;
		}

		.select{
			width: 25%;
		}
	}
}
