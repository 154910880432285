@import '../../Styles';

.block-scroll {
	overflow: hidden;
	position: fixed;

	@include lg {
		position: absolute;
	}
}

.drawer {
	&.temporary {
		display: block;
	}

	&.permanent {
		display: none;
	}

	.MuiDrawer-paper {
		width: 17rem;
	}

	@include xl {
		&.temporary {
			display: none;
		}
		&.permanent {
			display: block;
		}
	}
	.sidebar-root {
		@include flex(column, flex-start);
		width: 16rem;
		height: 100vh;
		top: 0;
		bottom: 0;
		&.open {
			.logo-container {
				.hamburger {
					visibility: visible;
				}
			}
		}
		background-color: #f1f1f1;
		// border-right: 1px solid $mid-grey;

		.logo-container {
			@include flex(row, space-between);
			width: 100%;
			height: 50px;
			// border-bottom: 1px solid #ffb803;
			position: relative;
			background: #ffffff;
			@include md{
				padding: 0.75rem;
			}
			padding: 0.25rem;
			.sidebar-logo {
				max-width: 100%;
				width: 100px;
			}

			.search_tab{
				@include md {
					@include flex(row);
					font-size: 0.9rem;
				}
				font-size: 0.75rem;
				// cursor: pointer;
				border-radius: 20px !important;
			}
		}

		.sidebar-nav {
			@include removeScollbar;
			width: 100%;
			height: calc(100% - 60px);
			overflow-y: auto;

			.nav-list {
				width: 100%;
				margin: 0px;
				padding: 0px;
				.nav-item {
					list-style: none;
					margin: 0px;
					cursor: pointer;
					border-radius: 8px;

					&.expanded {
						background: #ffb80341;
						.nav-list {
							max-height: 2000px;
							opacity: 1;
							transition: max-height 1s ease-in-out, opacity 1s;
						}
					}
					.nav-list {
						max-height: 0;
						overflow: hidden;
						opacity: 0;
						transition: max-height 0.5s cubic-bezier(0, 1, 0, 1),
							opacity 0.35s;
					}
				}
			}
		}

		.sidebar-footer {
			@include flex(column);
			width: 100%;
			border-top: 1px solid $mid-grey;
			height: 60px;
			gap: 4px;
			.powered-by {
				color: $dark-grey;
				font-size: 0.75rem;
				font-style: italic;
			}
			.footer-logo {
				width: 60px;
			}
		}
	}
}
