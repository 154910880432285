.header-navigator {
    padding: 1rem 5rem;
    display: flex;
    justify-content: right;

    .button {
        background-color: #4a70fb;
        color: white;
    }
}

.table {
    .image {
        width: 40px;
        height: 40px;
    }
}