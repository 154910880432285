.root{
    z-index: 1;
    // position: absolute;
    // top: -5px;
    // right: 50px;
    transform: scale(.6);
    // overflow: hidden;
    // background-color: aquamarine;
    .downloadButton{
        &:hover{
            background-color: #1d6f42;
            transform: scale(1.2);
            ;
        }
        transition: all;
        transition-duration: .2s;
        background-color: #1d6f42a1;
        p{
            display: none;
        }
    }
    .animation{
        position: absolute;
        position: bottom;
        font-size: larger;
        left: -30px;

        animation-name: example;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: .8s;
        color: #1d6f42e0;
        .icon{
        }
    }
    @keyframes example {
        from {
            bottom: 0px;
            transform: scale(.8);
        }
        to {
            transform: scale(1);
            bottom: -5px;
        }
      }
    
}
