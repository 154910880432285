@import '../../../Styles/';
.root {
	width: 100%;
	position: relative;
	height: 100%;
	.general_details {
		width: 100%;
		@include flex(column, flex-start);
		padding: 0.5rem;
		background: $uy-ex-light-bg;
		border-radius: 12px;
		margin-bottom: 1rem;
		.labelled_value {
			width: 100%;
			@include flex(column, flex-start, flex-start);
			gap: 0.25rem;
			.values {
				width: 100%;
				// border: 1px solid #d1d1d1;
				// padding: 0.25rem;
				// border-radius: 8px;
				@include flex(row, flex-start);
				gap: 0.25rem;
				.value {
					padding: 0.5rem 1rem;
					background: #ffffff;
					border-radius: 8px;
					font-weight: 500;
				}
			}
		}
	}
	.filters {
		@include flex(row, flex-start, flex-end);
		gap: 1rem;
		width: 100%;
		.search {
			width: 150px;
		}
	}
	.list {
		overflow: auto;
		.deltaHigh {
			background: #ffab7acb;
		}
		.updated {
			background: #6499e9cb;
		}
		.skipped {
			background: #fe000078;
			// color: #fff;
		}
	}
	.desc-input {
		width: 50% !important;
		position: absolute;
		bottom: 0;
		right: 0;
	}
}

.btns {
	@include flex;
	gap: 1rem;
	width: 100%;
}

.order-qty {
	@include flex;
	gap: 0.5rem;
	.actual {
		padding: 0.25rem 0.5rem;
		border-radius: 25px;
		background: rgb(223, 223, 223);
		opacity: 0.9;
	}
}

.no-recipe {
	@include flex;
	gap: 0.25rem;
	.add-recipe {
		@include flex;
		padding: 0.25rem;
		border: 1px solid rgb(39, 39, 255);
		border-radius: 25px;
		p {
			font-size: 0.75rem;
		}
		.icon {
			color: rgb(39, 39, 255);
		}
	}
}
.positive{
	background-color: yellow;
}
.negative{
	background-color: pink;
}
