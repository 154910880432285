@import '../../../Styles';

.sidebar-item-root {
	@include flex(row, flex-start);
	width: 100%;
	min-height: 34px;
	padding: 0.75rem 1.5rem 0.75rem 2.75rem;
	text-decoration: none;
	position: relative;
	border-radius: 8px;

	&:hover {
		.add-icon {
			@include flex;
		}
	}
	.add-icon {
		display: none;
		position: absolute;
		right: 1.5rem;
		font-size: 1rem;
		width: 1.5rem;
		height: 1.5rem;
		padding: 0.25rem;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 50%;
		color: white;
		z-index: 9;
	}

	&.expandable {
		@include flex(row, space-between);
		.expand-icon {
			transform: rotate(-90deg);
		}
	}

	&.expanded {
		.expand-icon {
			transform: none;
		}
	}

	&.active {
		background: #ffb703;
		color: rgb(0, 0, 0);
		font-weight: 500;

		.sidebar-item-title {
			font-size: 0.9rem;
			color: rgb(0, 0, 0);
		}
	}
	&:not(.active):hover {
		background: #ffb80386;
	}
	&.non-link {
		@include flex;
		cursor: default;
		padding: 0.75rem;
		letter-spacing: 1.25px;
		font-weight: 100;
		&:hover {
			background-color: transparent;
		}
		.sidebar-item-title {
			font-size: 0.9rem;
			color: $black;
			text-transform: uppercase;
		}
	}

	.sidebar-item-icon {
		@include flex;
		position: absolute;
		// to place in between the gap by calc half of (padding and width)
		left: calc((2.75rem - 15px) / 2);

		svg {
			width: 15px;
		}
	}
	.sidebar-item-title {
		color: $black;
		font-size: 0.9rem;
	}

	.title {
		@include flex(column, flex-start, flex-start);
		.requisites {
			.requisite-value {
				@include flex;
				gap: 0.25rem;
				font-size: 0.7rem;
				p {
					margin-top: 0;
				}
				.edit-btn {
					background: none;
					color: rgb(33, 33, 201);
					border: none;
					outline: none;
					.icon {
						font-size: 1.25rem;
					}
				}
			}
		}
	}

	.expand-icon {
		transition: 0.35s all ease;
	}
}
