@import '../../../Styles/';

.root{
    @include flex(row, space-between);
    padding: 1rem;
    border-bottom: 1px solid #a2a2a27c;

    .header{
        font-size: 1.5rem;
        font-weight: 500;
    }
}