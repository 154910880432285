@import '../../../Styles/index';

.root {
    @include flex(column, flex-start);
    border-radius: 8px;
    background: #eff5f5;
    @include boxShadow;
    padding: 0.5rem;
    // height: 275px;
    position: relative;
    height: 100%;

    .header{
        @include flex;
        gap: 0.25rem;
        flex-wrap: wrap;
    }

    .table-container{
        width: 100%;
        // flex: 1;
        font-size: 11px;
        thead{
            tr{
                width: 100%;
                background: $uy-bg;
                th{
                    padding: 0.25rem 0;
                    font-size: 13px;
                    font-weight: 500;
                }
                .img-col{
                    width: 100px;
                }
            }
        }
        tbody{
            padding: 0 0.25rem;
            td{
                padding: 0.25rem 0;
                img{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                }
                min-width: 75px;
            }
            .center{
                @include flex;
            }
        }
    }

    // @include rwd(1400) {
    //     height: 325px;
    //     // width: 325px;
    // }

    .grid {
        padding: .5rem;
        flex-grow: 1;
        overflow-y: auto;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;

        

        &::-webkit-scrollbar {
            width: .3rem;
            // height: .1rem;
          }
           
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }
           
          &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            // outline: 1px solid slategrey;
          }



        // grid-template-columns: .4fr 2fr .8fr .2fr;
        .images {
            // background: #000;
            width: 15%;
            // height: 100%;
            // background: #6f4747;
            // background: #000;
            .image{
                align-items: center;
                gap: .55rem;
                display: flex;
                flex-direction: column;
                
                .icon {
                    // width: 60px;
                    // height: 43px;
                    
                    display: flex;
                    align-content: center;
                    
                    img {
                        // min-height: 43px;
                        // width: 30px;
                        // min-height: 46px;
                        // background: #000;
                        // height: 30px;
                    }
                }
            }

        }

        .name {
            
            display: flex;
            // background: #e1bcbc;

            font-weight: 500;
            width: 30%;
            line-height: 85%;
            // border: 1px solid black;
            min-height: 43px;
            max-width: 230px;
            align-items: center;
        }

        .arrow {
            width: 15%;
            // background: #9c2b2b;
            display: flex;

            align-items: center;
            align-content: flex-end;
            justify-items: end;
            // justify-content: end;
            flex-direction: column;
            // background: #000;
            font-weight: 500;
        }
    }

}