@import '../../Styles/';

.root {
    width: 100%;
    height: calc(100vh - 10rem);
	@include flex(row, flex-start, flex-start);
	.lhs {
		width: 25%;
        height: 100%;
		border-right: 1px solid #d2d2d2;
		// background: red;
        overflow: auto;
        padding-bottom: .5rem;
        .header{
            font-size: 1.25rem;
            background: #f1f1f1;
            width: 100%;
            padding: 0.5rem;
        }
        .selector{
            padding: 0.5rem 0.75rem;
            border-radius: 4px 0 0 4px;
            cursor: pointer;
            &.active{
                border: 1px solid $uy-bg;
                background: $uy-bg;
                font-weight: 500;
            }
        }
	}
    .rhs{
        width: 75%;
        height: 100%;
        overflow: auto;

    }
}
