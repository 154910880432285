@import '../../../Styles';

.root {
	@include flex(row, space-between);
	padding: 1rem;
	position: sticky;
	top: 49px;
	background: $white;
	z-index: 11;
	border-bottom: 1px solid $mid-grey;

	.btn-group {
		@include flex(row, flex-end);
		width: 150px;
		gap: 1rem;
	}
}
