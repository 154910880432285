@import '../../../../Styles/';

.root {
	.header {
		@include flex(row, space-between);
		padding-left: 1rem;
		width: 100%;
		height: 70px;
		border-bottom: 1px solid $mid-grey;
	}

	.body {
		@include grid(2);
		overflow-y: auto;
		height: calc(100vh - 60px - 70px);
		padding: 1.5rem;
		gap: 2rem;

		.chart-container {
			height: 100%;
		}
	}
}
