@import '../../../Styles/';

.btns{
	width: 100%;
	@include flex;
	gap: .5rem;	
	.proceed {
		flex: 1;
		background-color: $success;
		color: $white;
	}
	.cancel {
		flex: 1;

		background-color: $pastel-red;
		color: $white;
	}

}
.label-container{
	margin: 1rem;
	@include flex(column, flex-start, flex-start);
	gap: .5rem;
	.mandatory{
		color: rgb(200, 0, 0);
	}
	label{
		width: 100%;
		text-align: left;
		// font-weight: 500;
		color: rgba(95, 95, 95, 0.75);
	}
}
