@import '../../../Styles/';

.root {
	height: 100%;
	// background: red;
	display: grid;
	grid-template-columns: 3fr 1.25fr;
	gap: 0.5rem;
	grid-template-rows: 1;
    overflow: hidden;
	.left {
		width: 100%;
		height: 100%;
		@include flex(column, flex-start);
		gap: 0.85rem;
        // margin-top: .5rem;
        overflow: auto;
		table{
			th{
				padding: .2rem .5rem;
				font-size: .8rem;
				// td{
				// }
			}
			tr{
				td{
					font-size: .85em;
				}
			}
		}
		.bills {
			border: 1px solid $uy-bg;
			flex: 4;
            min-height: 400px;
            margin-top: .75rem;
			overflow-x: auto;
            .selected_bill{
                background: rgba(0, 128, 0, 0.4);
            }
            // overflow: auto;
		}
		.adv_disabled{
			background: #f1f1f1;
			color: #6b6b6b;
		}
		.available {
            flex: 2;
            min-height: 200px;
			overflow-x: auto;
            // overflow: auto;
		}
	}
	.payment_details {
		width: 100%;
		@include flex(column, flex-start);
		gap: 0.5rem;
		padding: 0.5rem;
		border: 1px solid #d1d1d1;
		border-radius: 4px;
		font-size: 0.95rem;
		.label_value {
			width: 100%;
			@include flex(row, space-between);
			label {
				color: #000000ba;
				&.mandatory{
					color: rgba(255, 0, 0, 0.75);
				}
			}
			p {
				font-weight: 500;
			}
			.file{
				@include flex(row, space-between);
				gap: .5rem;
				border: 1px solid #d1d1d1;
				max-width: 200px;
				border-radius: 12px;
				padding: 0 .2rem;
				.fileName{
					font-size: .7rem;
					p{
						text-align: left;
					}
					.icon{
						cursor: pointer;	
					}
				}
			}
		}
		.payment_mode {
			.rs-picker-default .rs-picker-toggle {
				border-radius: 10px !important;
			}
		}
	}
    .TYPE{
        padding: 0.1rem .5rem;
        border-radius: 8px;
        font-size: 0.75rem;
        font-weight: 500;
        color: white;
		@include bill_type_colors;
    }
	.use_amount{
		button{
			background: none;

		}
	}
}

.btn_container {
	width: 100%;
	@include flex;
	gap: 1rem;
	button {
		flex: 1;
		padding: 0.5rem 1rem;
		border-radius: 8px;
		&.next {
			background: $uy-bg;
		}
		&.back {
			background: #f1f1f1;
			border: 1px solid #cccccc;
		}
	}
}

.box {
	width: 100%;
	@include flex(column, flex-start);
	gap: 0.25rem;
	border: 1px solid #d1d1d1;
	border-radius: 4px;
	// padding: 0.5rem 0;
    position: relative;
    margin: 5px 0;
	.header {
		width: 100%;
        // position: absolute;
        // top: -20px;
        // left: -5px;
        // z-index: 999;
		@include flex(row, flex-start);
        p{
            font-weight: 500;
            background: #f1f1f1;
            padding: 0 0.75rem;
        }
	}
	.container {
		width: 100%;
		height: 100%;
	}
	.due_date {
		padding: .25rem;
		border-radius: 2px;
		min-width: 90px;
		border: 1px solid #505050;
		&.due_out_range{
			animation: jump .5s ease-in-out infinite;
			border: 2px solid rgba(255, 0, 0, 0.6);
		}
	}
}

@keyframes jump {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
