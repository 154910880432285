@import '../../../Styles';

.emails-modal{
    .root{
        border-radius: 0 !important;
    }
}

.heading{
    font-size: 1.15rem;
    font-weight: 500;
    height: 75px;
}

.btns{
    width: 100%;
    @include flex;

    .btn{
        flex: 1;
        border-radius: 0;
    }
}
.root {
    width: 100%;
    height: 100%;
    @include flex(column, flex-start);
    gap: 1rem;
    padding: 1rem;

    .enable-text{
        width: 100%;
        text-align: center;
        font-size: 1.25rem;
        font-weight: 500;
        color: red;
    }

    .add-btn{
        padding: 0.25rem;
        border-radius: 50%;
        background: #068FFF;
        color: #fff;
        margin-right: 0.25rem;
    }
    .mails-container{
        width: 100%;
        height: 70%;
        overflow-y: auto;
        @include flex(column, flex-start);
        gap: 0.5rem;
        .email{
            @include flex(row, space-between);
            width: 100%;
            padding: 0.5rem;
            border-radius: 50px;
            background: #ECF8F9;
            border: 1px solid #59a8af;
            font-weight: 500;
    
            .remove-button{
                color: #FF0060;
                padding: 0;
                .icon{
                    font-size: 1.25rem;
                }
            }
        }
    }
}
