@import '../../../Styles/';

.root{
    @include flex(column, center, flex-start);
    gap: 1rem;
    width: 80%;
    margin: 2rem;
    // background: red;
    .multi{
        width: 100%;
        @include flex(row, space-between);
        gap: 7.5rem;
    }

    .rejected{
            width: 100%;
            @include flex(row, flex-start);
            gap: 0.5rem;
            padding: 0.5rem 1rem;
            background: rgba(238, 190, 33, 0.25);
            border-radius: 4px;
            font-weight: 200;
            p{
                margin: 0;
            }
            .reason{
                font-weight: 500;
                color: red;
            }
        }

    .label-container{
        @include flex(row, flex-start);
        position: relative;
        .mandatory{
            color: rgb(200, 0, 0);
        }
        label{
            width: 200px;
            font-weight: 500;
            color: rgba(95, 95, 95, 0.75);
        }
        .store-selector{
            border: 1px solid #a2a2a2;
            border-radius: 4px;
            input{
                padding: 0.5rem;
                border-radius: 4px;
            }
        }

        .deliver-to{
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
        }
        
        .anchor-decor {
            padding-left: 1rem;
            text-decoration: underline;
            text-decoration-color: blue;
            cursor: pointer;
        }
    }
    .store-details{
        @include flex(column, flex-start, flex-start);
        // margin: 0.5rem 0;
        padding: .5rem;
        border-radius: 4px;
        gap: 0.5rem;
        background: rgb(240,240, 240);
        font-size: 0.9rem;
        .address-container{
            @include flex(row, flex-start, flex-start);
            .address{
                width: 250px;
                .header{
                    font-weight: 500;
                }
            }
        }
    }
}

.editable-input {
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
}

.custom-input {
    width: 100%;
    height: 100%;
    margin: 0 .25rem;
    border-radius: 8px;
    outline: none;
    padding: 0.25rem 0.5rem;
    border: 2px solid $uy-bg;
    min-width: 100px;
    box-sizing: content-box;
}


.checkbox {
	background-color: #fff;
	border: solid 1px #d7d7d7;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	height: 20px;
	margin-right: 5px;
	text-align: center;
	width: 20px;
	vertical-align: middle;

	svg {
		stroke: #fff;
		margin-top: 1.5px;
	}
}

.includeIcon {
	color: #4aca65;
	width:40px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	justify-content: right;
	display: flex;	
	align-items: center;
	margin-right: 2px;
}
.excludeIcon {
	color: #c74545;
	width:40px;
	background-color: rgb(215, 215, 215);
	border-radius: 25px;
	justify-content: left;
	display: flex;	
	align-items: center;
	margin-right: 2px;
}

.negative {
	background-color: #dc4e4e;
	border-color: #c74545;
}

.positive {
	background-color: #4aca65;
	border-color: #43b45b;
}

.item-center {
    display: flex;
    width: 100%;
    align-items: center;
}

.yellow {
    display: flex;
    background-color: $uy-bg;
    height: 100%;
    // width: 2%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: .25rem .5rem; 
    border: 2px solid $uy-bg ;
    margin: .25rem .5rem;
    border-radius: 10px;
    width: 220px;
}

.disabled {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.picker {
    flex: 1;
    width: 150px;
    padding: 1px;
}

.list {
    background-color: white;
    border: 1px solid #f9f96b;
    padding: 5px;
    font-weight: 500;
    margin: 0px 1px;
    width: 200px;
}

.list:hover {
	color: black;
	background-color: rgba(255, 183, 3, 0.5);
}

.error {
    border: 2px solid red;
    text-align: center;
    color: #c74545;
    width: 300px;
}

.errorAbs {
    border: 2px solid red;
    position: absolute;
    top: -2rem;
    bottom: 0;
    width: 100%;
    color: #c74545;
    font-size: 1.5rem;
    z-index: 6;
    background-color: rgba(155, 151, 151, 0.5);
    font-weight: 600;
}

.text {
    background: white;
}

.abs {
    position: relative;
    margin-top: 2rem;
}

.table {
    position: relative;
}
