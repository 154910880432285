@import '../../Styles/index';

.root {
	@include flex(row, flex-start, flex-start);
	position: absolute;
	top: 20%;
	right: 0;
		.meal-image {
			@include flex;
			height: 275px;

			@include rwd(425) {
				height: 325px;
			}

			@include rwd(500) {
				height: 375px;
			}

			@include sm {
				height: 425px;
			}

			@include md {
				height: 100%;
			}

			position: relative;

			.defects {
				position: absolute;
				left: 1rem;
				top: 1rem;
			}

			.image {
				width: 100%;
				max-height: 100%;
				object-fit: contain;
			}

			.meal-results {
				position: absolute;
				height: 100%;

				.result {
					@include flex(column);
					color: $white;
					font-weight: 500;
					padding: 1rem;
					// @include rwd(425) {
					// 	padding: 1rem;
					// }
					.result-header {
						font-size: 0.8em;
					}
					.result-stat {
						font-size: 1.25em;
						svg {
							color: $pastel-red;
							&.result-detected {
								color: $success;
							}
						}
					}
				}

				&.vertical {
					@include flex(column, flex-start, stretch);

					top: 0;
					bottom: 0;
					right: 0;
				}

				&.horizontal {
					@include flex(row, center, stretch);
					display: none;
					max-height: 4.5rem;
					bottom: 0;
					left: 0;
					right: 0;
					.result {
						flex: 1;
						max-width: 100px;
					}
				}
			}
		}
	.meal-details {
		display: none;
		@include md {
			@include flex(column);
			flex: 2 1 66.6%;
			gap: 1rem;
			width: auto;
		}
		flex: 1 2 33.3%;
		width: 100%;
		padding: 0px 1rem;
		.row {
			@include flex(column);
			@include md {
				@include flex;
			}
			width: 100%;
		}
		.info-col {
			@include flex(column);
			gap: 1rem;
			width: 50%;
			padding: 0.5rem 0px;
			.info-label {
				font-weight: 500;
				text-align: center;
			}
			.info-value {
				font-size: 1.5rem;
				svg {
					color: $pastel-red;
					&.detected {
						color: $success;
					}
				}
			}
		}
	}
}
