.root {
	box-shadow: none !important;
	outline: none !important;

	border-bottom: 2px solid rgb(255, 255, 255);
	// padding: .5rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
    background-color: rgba(0,0,0,.2) !important;

    &.selected{
        background: #d9d9d9 !important;
        .name{
            font-weight: 500 !important;
        }
    }

    &.completed{
        background: #027a0089 !important;
        .accordion-heading{
            .name{
                color: #fff !important;
                font-weight: 600 !important;
            }
        }
    }

    &.progress{
        background: #fff6b8 !important;
    }
    
    .accordion-heading{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .indicator{
            padding: 0 0 0 1rem;
        }
    }
    .accordion-details{
        padding: 0;
        .expanded-div{
            padding: .5rem;
            width: 100%;
            height: 100%;            
        }
    }
}
