@import '../../../Styles/';
.root {
	.table-btn-container {
		position: relative;
		.add-btn {
			@include flex(row, flex-end);
			position: absolute;
			right: 0;
			top: -4.25rem;
		}
	}
}
