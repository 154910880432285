@import '../../../Styles/index';

.root {
	@include flex(row, space-between);
	@include boxShadow;
	width: 100%;
	gap: 0.5rem;
	padding: 0.5rem 1rem;

	.machine-status {
		@include flex;
		gap: 0.5rem;
		cursor: default;
		padding: 0.5rem 1rem;
		color: $white;
		border-radius: 4px;

		&.ONLINE {
			background: $success;
		}

		&.OFFLINE {
			background: $red;
		}
	}

	.live {
        cursor: pointer;
        text-transform: uppercase;
        border: none;
		padding: 0.5rem 1rem;
		color: $white;
		border-radius: 4px;
		background: $neon-red;
	}

	.stat {
		@include flex(column);

		.label {
			font-weight: 500;
		}

		.label,
		.value {
			text-align: center;

            &.select {
                max-width: 120px;
            }
		}
	}

    .navigation {
        @include flex;

        svg {
            font-size: 1.25rem;
        }
    }
}
