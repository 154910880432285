@import '../../../../Styles';

.root {
	@include flex(row, flex-start);
	gap: 0.5rem;
	position: relative;

	.error-message {
		position: absolute;
		top:-1.5rem;
		left: 0;
		font-size: 10px;
		color: #ff0000;

	}



	.checkbox-root {
		border-radius: 4px;
		&.selected {
			.checkbox-label {
				background: $success;
				color: $white;
				&:hover {
					background: $success;
				}
			}
		}
		.checkbox-label {
			padding: 0.5rem 1rem;
			border: 1px solid $mid-grey;
			border-radius: 4px;
			cursor: pointer;
			transition: all 0.4s;

			&:hover {
				background: $mid-grey;
				color: $white;
			}

			.selected {
				background: $success;
				color: $white;
			}
		}
		.checkbox-input {
			visibility: hidden;
			width: 1px;
			height: 1px;
		}
	}

	&.disabled {
		.checkbox-root {
			.checkbox-label {
				cursor: default;
				&:hover {
					background: transparent;
					color: $black;
				}
			}
			&.selected {
				.checkbox-label:hover {
					background: $success;
					color: $white;
				}
			}
		}
	}
}
