@import '../../../Styles/';

.root{
    width: 100%;
    @include flex(column);
    gap: 2rem;
    form{
        @include flex(column);
        width: 80%;
        gap: 1rem;
    }
}