@import '../../../Styles';

.root {
	width: 100%;
	@include flex(column);
	.img-container{
		width: 30rem;
		height: 20rem;
		padding: 0.5rem;
		@include flex(column);
		background: #DDE6ED;
		border-radius: 8px;
		img {
			width: 100%;
			height: 18rem;
			object-fit: contain;
		}
	}

	.edit-temp{
		@include flex;
		gap: 1rem;
		margin-top: 1rem;
	}

}

.btns{
	width: 100%;
}
