@import '../../../Styles';

.input {
	width: 200px;
	max-width: 100%;
	border-radius: 4px;
	border: 1px solid $mid-grey;
	padding: 0.5rem 0.75rem;
	outline: none;
	background: $white;
	font-family: inherit;
	font-size: inherit;
	margin-top: 1.25rem;
}
