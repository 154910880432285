@import "../../../Styles/index";

.containers {
  width: 100%;
  height: 100%;
  @include flex(row, flex-start, flex-start);
  gap: 1rem;
  flex-wrap: wrap;
  overflow-y: auto;
  .container {
    width: 150px;
    height: 150px;
    position: relative;
    border-radius: 5px;
    cursor: pointer;

    &.selected{
      .image{
        border: 5px solid green;
      }
      .name-weight{
        background: rgba(0, 128, 0, 0.75);
      }
    }

    .image {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 5px;
      object-position: center;
    }
    .name-weight {
      width: 100%;
      position: absolute;
      border-radius: 5px;
      bottom: 0;
      background: rgba(0, 0, 0, 0.75);
      color: #fff;
      font-weight: 500;
      font-size: 0.9rem;
      padding: 0.25rem;
    }
  }
}
.footer {
  @include flex(row, space-evenly, flex-end);
  margin-top: 1.25rem;
  width: 100%;
  // height: 100%;
  // background-color: red;
}
