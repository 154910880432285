@import './colors';

$header-height: 50px;

@mixin flex($flexDirection: row, $justifyContent: center, $alignItems: center) {
	display: flex;
	align-items: $alignItems;
	flex-direction: $flexDirection;
	justify-content: $justifyContent;
}

@mixin absolute($top: 50%, $left: 50%, $transform: translate(-50%, -50%)) {
	position: absolute;
	top: $top;
	left: $left;
	transform: $transform;
}

@mixin grid($columns: 3) {
	display: grid;
	grid-template-columns: repeat($columns, 1fr);
}

@mixin boxShadow() {
	box-shadow: 0 1px 5px 0 rgba($black, 0.2), 0 3px 10px 0 rgba($black, 0.2);
	border: 1px solid rgba($black, 0.2);
}

@mixin dropShadow() {
	filter: drop-shadow(1px 3px 5px rgba($black, 0.2));
}

@mixin customScrollBar($theme: 'dark') {
	-ms-overflow-style: none;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 100%;
		height: 5px;
	}
	@if $theme == 'dark' {
		&::-webkit-scrollbar {
			background: $very-dark-grey;
		}

		&::-webkit-scrollbar-thumb {
			background: $mid-grey;
		}
	} @else {
		&::-webkit-scrollbar {
			background: $mid-grey;
		}

		&::-webkit-scrollbar-thumb {
			background: $very-dark-grey;
		}
	}
}

@mixin removeScollbar() {
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}

@mixin removeNumInputArrows() {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
	}
	-moz-appearance: textfield !important;
	appearance: textfield !important;
}

@mixin applyEllipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin stat-box {
	width: 100%;
	max-width: 8rem;
	font-size: 1rem;
	background: $black;
	padding: 0.5rem 1rem;
	color: $white;
	border-radius: 4px;
	text-align: center;
	&.disconnected {
		// @include pulseGlow($red);
		visibility: visible !important;
		background: $red;
	}
}

@mixin bill_type_colors {
	&.ADVANCE {
		background: rgb(80, 80, 255);
	}
	&.GRN {
		background: rgb(14, 92, 14);
	}
	&.EXPENSE{
		background: rgb(211, 137, 0);
	}
	&.INCOME{
		background: rgb(0, 175, 79);
	}
	&.UTILITIES{
		background: #00d4f9;
	}
	&.CAPEX{
		background: #7620a4;
	}
}
