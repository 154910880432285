.container {
    width: 80%;
    min-width: 700px;

    .gridDiv {
        width: 80%;
        min-width: 600px;
        display: grid;
        row-gap:.5rem ;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: 1fr 1fr 1fr;
        align-items: center;

        .textLabel {
            color:rgba(95, 95, 95, 0.75);
            // font-weight: 500;
            // font-size: 1.1rem;
            font-size: 1rem ;
        }
    }
    .vairentTagsDiv{
        padding-top: 2rem;
        &>div{
            padding: 0px !important;
        }
    }

    .errorShow {
        color: rgb(238, 0, 0);
        font-weight: 500;
        padding-top: 2rem;
        font-size: 2rem;
        text-align: center;
    }

    .blinkError {
        transform-origin: center;
        transform: scale(1);
        animation: scaleAnimation .2s alternate 2;
    }

    @keyframes scaleAnimation {
        from {
            transform: scale(1);
        }

        50% {
            transform: scale(.8);
        }

        100% {
            transform: scale(1);
        }
    }

}