@import '../../Styles/';

.root{
    @include flex(column);
    gap: 1rem;
    .input-container{
        @include flex(column);
        gap: 1rem;
        input{
            border-radius: 4px;
            border: none;
            background-color: #F1F1F1;
            width: 250px;
            padding: 10px 5px;
            font-weight: 500;
        }
    }
}