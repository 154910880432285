@import '../../Styles/';
.root {
	@include boxShadow;
	padding: 1rem;
	
	.graph {

	}
	.heading {
		font-weight: 600;
		text-align: center;
		padding: 1rem;
		font-size: medium;
	}
}
