@import '../../../Styles';

.root {
    @include flex;
    gap: 4px;
    width: auto;
	height: 30px;
	border-radius: 4px;
	border: 1px solid $mid-grey;
	padding: 0.25rem 1rem;
	cursor: pointer;
	transition: all 0.4s;
	.icon {
        font-size: 1.5rem;
	}
	.text {
        white-space: nowrap;
		text-transform: uppercase;
	}
}
