@import '../../../Styles/index';

.root {
	width: auto;
	height: 100%;
	@include flex(row);
	gap: 1rem;

	.time-selector {
		height: 100%;

		.slot-label{
			text-align: left;
			margin-bottom: .25rem;
		}

		.time-wrapper {
			margin: 3rem 0 1rem 0;
			.time-picker {
				margin-top: 0.5rem;
			}

			.add-btn{
				width: 100%;
				background-color: $primary;
				color: white;
				margin-top: 0.5rem;
				padding: 8px 0;
				border-radius: 8px;
				border: none;
				cursor: pointer;

				&:disabled{
					background-color: $mid-grey;
					color: black;
				}
			}
		}

		.time-container {
			@include flex(row, flex-start);
			background: $dark-yellow;
			border-radius: 5px;
			height: 35px;
			margin-bottom: 0.5rem;
			width: 100%;
			border-color: $grey;
			position: relative;
			.heading {
				width: 100%;
				text-align: center;
			}
			.delete-icon {
				position: absolute;
				top: -0.5rem;
				right: -0.5rem;
				font-size: 1rem;
				cursor: pointer;
				&:hover {
					color: $pastel-red;
				}
			}
		}
	}

	.date-selector {
		height: 100%;
		width: calc(280px + (0.6rem * 7) + 1rem);
		@include flex(column, space-between, center);
				
		.dates-wrapper {
			margin-top: 3rem;
		}

		.no-filter {
			@include flex(row);
			align-self: flex-end;
			gap: .5rem;
		}
	}

	.days-selector {
		height: 100%;
		width: calc(100% / 3);
		@include flex(column, space-between, center);
		
		.days-container{
			width: 100%;
			.days-wrapper {
				width: 100%;
				margin: 3rem 0 0 0;
			}
		}


		.no-filter {
			@include flex(row);
			align-self: flex-end;
			gap: .5rem;
		}
	}

	.divider {
		width: 1px;
		height: 100%;
		// background-color: rgb(0 0 0 / 0.1);
	}
}
