
.checkbox {
	background-color: #fff;
	border: solid 1px #d7d7d7;
	border-radius: 4px;
	cursor: pointer;
	display: inline-block;
	height: 20px;
	margin-right: 5px;
	text-align: center;
	min-width: 20px;
	vertical-align: middle;

	svg {
		stroke: #fff;
		margin-top: 1.5px;
	}
}

.positive {
	background-color: #4aca65;
	border-color: #43b45b;
}

.negative {
	background-color: #dc4e4e;
	border-color: #c74545;
}

.suggestions {
	padding: 8px;
	cursor: pointer;
	border-bottom: 1px solid #eee;
}