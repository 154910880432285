@import '../../../Styles/index';

.root {
	width: 100%;
	@include flex(column, flex-start, flex-start);
	gap: 1rem;
	.tile {
		@include flex(row, flex-start);
		.mandatory {
			color: rgb(200, 0, 0);
		}
		label {
			width: 150px;
			text-align: left;
		}
	}
	.multi {
		@include flex(row, flex-start);
		gap: 1rem;
	}

    .location-details{
        width: 100%;
        padding: 1rem 0.5rem;
        @include flex(column, flex-start, flex-start);
        gap: 0.5rem;
        border-radius: 0 0 8px 8px;
		border: 1px solid #aaaaaa;
        border-top: none;
    }

	.address-container {
		width: 100%;
		@include flex(row, space-between);
		padding: 1rem;
		.billing,
		.shipping {
			@include flex(column, flex-start, flex-start);
			gap: 0.5rem;
			.header-container {
				@include flex(row, flex-start);
				gap: 0.5rem;
				.copy-add {
					@include flex(row, flex-start);
					gap: 0.25rem;
					color: black;
					background: $uy-bg;
					font-size: 0.75rem;
					// border: 1px solid black;
					border-radius: 25px;
					padding: 0.25rem;
					cursor: pointer;
				}
			}
			.header {
				font-size: 1.25rem;
				font-weight: 500;
			}
			.input-container {
				@include flex(column, flex-start, flex-start);
				gap: 0.5rem;
			}
		}
	}
	.add-btn {
		// @include flex;
		gap: 0.5rem;
		border: 1px solid $uy-bg;
		padding: 0.25rem;
        margin: 0.2rem 0;
		border-radius: 25px;
		cursor: pointer;
	}
	.locations {
		width: 100%;
		@include flex(row, flex-start, flex-start);
		gap: 0.5rem;
		flex-wrap: wrap;
		position: relative;
		border: 1px solid #d2d2d2;
		padding: 2rem 0.5rem 0.5rem;
		border-radius: 12px;
		.header {
			position: absolute;
			top: 0.25rem;
			left: 0.5rem;
			// font-weight: 500;
			color: red;
		}

		&-input {
			@include flex;
			gap: 0.5rem;
			border: 1px solid #d2d2d2;
			padding: 0.25rem;
			border-radius: 12px;
			.delete {
				@include flex;
				cursor: pointer;
				color: red;
				&:hover {
					color: black;
					background: $uy-bg;
					border-radius: 50%;
				}
				// .icon{
				// }
			}
		}
	}
	.label-container{
        @include flex(row, flex-start);
        .mandatory{
            color: rgb(200, 0, 0);
        }
		.deliver-to{
			@include flex(column);
			gap: .25rem;
			.is_expense{
				@include flex;
				gap: .25rem;
				cursor: pointer;
				label{
					width: 100%;
				}
			}
		}
    }
}

.btns-container {
	width: 100%;
	@include flex;
	gap: 1rem;
}
