@import '../../../Styles/';

.root {
    .emails-container{
        @include flex;
        gap: 1rem;
        // width: 250px;
        .emails{
            width: 175px;
            border-radius: 8px;
            background: #ECF8F9;
            padding: 0.25rem;
            p{
                width: 100%;
                word-wrap: break-word;
            }
        }
        .edit-btn{
            padding: 0.5rem;
            border-radius: 50%;
            background: #068FFF;
            color: #fff;
            cursor: pointer;
        }
    }
}