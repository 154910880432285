.rootHolder {
  position: relative;
  transition: all;
  transition-duration: 2s;
  overflow: auto;
  height: calc(100vh - 60px);
  width: 100%;
}
.rootHolder .root {
  display: inline-block;
  background-color: rgba(53, 66, 4, 0.19);
  transition: all;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  transition-duration: 2s;
  z-index: 100;
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0rem;
  width: 100%;
}
.rootHolder .root .expandIcon {
  position: absolute;
  bottom: -20px;
  background-color: rgba(237, 255, 174, 0.445);
  z-index: 2;
  cursor: pointer;
  border: 1px solid black;
  border-top: none;
  right: 50%;
  transform: translate(50%, 0%);
}
.rootHolder .root .rotateExpandIcon > path {
  transform-origin: center;
  transform: rotate(180deg);
}
.rootHolder .root .accordion {
  border: none;
  border-radius: 0%;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.396);
  padding: 0px;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid black;
}
.rootHolder .root .accordion .accordionDetailsScroller {
  max-height: calc(100vh - 200px);
  height: calc(100vh - 300px);
  border: 1px dashed black;
  width: calc(100vw - 19rem);
  position: relative;
}
.rootHolder .root .accordion .accordionDetailsScroller > div:first-child {
  width: auto;
  position: relative;
  height: 100%;
}
.rootHolder .root .accordion .accordionDetailsScroller .searchDiv {
  position: absolute;
  top: 10px;
  display: inline-block;
  left: 10px;
  z-index: 10;
}
.rootHolder .root .accordion .accordionDetailsScroller .tools {
  background-color: black;
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
}
.rootHolder .root .accordion .accordionDetailsScroller .tools > * {
  padding: 0.2rem;
  color: white !important;
}
.rootHolder .root .accordion .accordionDetailsScroller .treeHolder {
  transition: all;
  transition-duration: 0.1s;
}
.rootHolder .root .accordion .accordionDetailsScroller .treeScale {
  transform: scale(0.6);
}
.rootHolder .root .accordion .accordionTitle {
  background-color: rgba(237, 255, 174, 0.445);
  min-height: 0px !important;
  padding: 0px;
}
.rootHolder .root .accordion .accordionTitle .breadcrumb {
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
  padding-left: 1rem;
  transform: skew(20deg);
}
.rootHolder .root .accordion .accordionTitle .breadcrumb .active {
  background: #000;
  color: white !important;
  font-weight: 700;
  font-size: large;
}
.rootHolder .root .accordion .accordionTitle .breadcrumb .breadcrumbItem {
  color: rgba(255, 255, 255, 0.65);
  background-color: rgba(0, 0, 0, 0.904);
  padding: 0.5rem 1rem;
}
.rootHolder .data-box-container {
  padding: 1rem;
  background-color: rgba(238, 255, 174, 0.29);
  padding: 1rem;
  gap: 2rem;
  width: 100%;
  transition-duration: 500ms;
}
.rootHolder .compressButton {
  position: absolute;
  top: 10px;
  left: 10px;
}
.rootHolder .compress {
  transition: all;
  transition-duration: 2s;
  height: 50px !important;
  overflow: hidden;
}

.react-transform-wrapper {
  background-color: #000 !important;
  width: auto !important;
}/*# sourceMappingURL=AssetDashboardPage.module.css.map */