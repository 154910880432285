@import '../../../../Styles';

.root {
	width: 100%;
	height: 100%;

	.choices-wrapper {
		@include flex(row, flex-start, flex-start);
		@include customScrollBar('light');
        // flex-wrap: wrap;
		overflow-x: auto;
		height: 260px;
		margin-bottom: 2rem;
	}
}
