@import '../../../../Styles';

.root {
	tbody {
		tr {
			td {
				.action-wrapper {
					@include flex(column);
					.action-sub-text {
						font-size: 0.75rem;
					}
				}
			}
		}
	}

	&.sticky {
		height: auto;
		max-height: 100%;

		thead {
			th {
				z-index: 3;
				position: sticky;
				top: $header-height;
				background: #f1f1f1;
			}
		}
	}
}
.downloadData {
	width: 125px;
	text-align: center;
	// margin-left: auto;
	cursor: pointer;
	@include flex;
	gap: 0.25rem;
	z-index: 10;
	background-color: #1d6f42;
	color: white;
	border-radius: 1rem;
	padding: 0.25rem 0.5rem;
	img {
		width: 25px;
	}
}
