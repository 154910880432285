@import '../../../Styles';

.root{

    height: auto;
    min-height: calc(100vh - 80px);

	.list-view-header {
		padding: 1.25rem;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding: 1.25rem;

        .name{
            margin-left: auto;
            font-size: 1.1rem;
        }
        
	}
    .search-bar-container{
        width: 15rem;
        min-width: 15rem;
        margin: 1rem;
    }
    .back{
        margin-right: 30px;
        padding: 8px;
        width: 50px;
        border-radius: 0.5em;
        text-align: center;
        color:black;
        background-color: $mid-grey;
    }
    .back:hover{
        background-color: rgb(219, 218, 218) ;
        cursor: pointer;
    }
}