@import '../../../../Styles/';

.root {
	width: 100%;
	padding: 1rem;
	background: rgba($primary, 0.2);
	border: 1px solid $primary;
	border-radius: 4px;

	.header-container {
		width: 100%;
		@include flex(row, space-between);

		.header-selector-wrapper {
			@include flex(row);
			gap: 1.5rem;

			font-size: .9rem;
			.header {
			}
		}

		.range-container {
			@include flex(row);
			gap: 1.5rem;
			border: 1px solid rgb(85, 85, 85);
			padding: 0.5rem;
			border-radius: 4px;
			opacity: 0.5;
		}

		.applied {
			opacity: 1;
		}

		.defect-select,
		.time-select {
			background: #fff;
		}
	}

	.headerBrandSelector {

		// background: #000;
		// width: 10rem;
		.brand-select {
			width: 10rem;
			background: white;
		}
	}
	.headerStoreSelector {

		// background: #000;
		// width: 10rem;
		.store-select {
			width: 10rem;
			background: white;
		}
	}


	.form {
		@include flex;

		.filters {
			@include flex(row, center, stretch);
			flex-basis: 90%;
			margin-top: 1rem;
			padding: 1rem;



			.defect,
			.product,
			.vendor,
			.productCategory {
				flex-basis: 40%;
			}

			.filter {
				@include flex(column, flex-start);
				gap: 1rem;

				.filter-heading {
					// width: 100%;
					// text-align: center;
					// padding: .5rem;
					// border-top: 1px solid black;
					// border-bottom: 1px solid black;
					font-size: 1rem;
					text-transform: uppercase;
				}

				.filter-body {
					@include flex;
					width: 100%;
					gap: 0.5rem;
					padding: 1rem;

					.tags {
						@include flex(row, center);
						gap: 0.5rem;
						flex-wrap: wrap;
					}

					.add-button {
						background: $white;
						border: 1px solid $primary;

						&:hover {
							background: $white;
						}
					}
				}
			}

			.seperator {
				@include flex(column);
				color: $black;
				padding: 1rem;
				font-size: 1.5rem;
				font-weight: 500;

				svg {
					font-size: 2rem;
				}
			}
		}

		.submit {
			@include flex(column);
			flex-basis: 10%;
			border-left: 1px solid $primary;
			padding: 1rem;
			font-size: 1.25rem;
			text-transform: uppercase;

			.btn {
				&:disabled {
					svg {
						color: rgba($primary, 0.4);
					}
				}

				svg {
					font-size: 2.5rem;
					color: $primary;
				}
			}
		}
	}
}