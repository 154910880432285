@import '../../Styles/';
.root {
	@include flex(column, flex-start, stretch);
	.filter-container {
		@include flex(row, flex-start);
		padding-right: 1rem;
		.button-container {
			margin: 1rem;
			.filter-button {
				@include flex(row, space-between);
				.filter-icon {
					height: 2.5rem;
					width: 1.5rem;
				}
			}
		}
		.filter-data-container {
			@include flex(row, flex-start);
			@include customScrollBar('light');
			width: 100%;
			overflow-x: scroll;
			gap: 0.5rem;
			height: 45px;
			.filter-data {
				padding: 1rem;
				background-color: $mid-grey;
				margin: 0.5rem;
				border-radius: 4px;
				position: relative;
				.cross-button {
					position: absolute;
					top: -15px;
					right: -20px;
				}
			}
		}
	}
	.graph-container {
		@include flex(row, space-between);
		padding: 1rem;
		div {
			flex-basis: 33%;
		}
	}
}
