@import '../../../Styles';

.root {
	@include flex(row, space-between);
	padding: 0.5rem 1rem;
	position: sticky;
	top: 0;
	background: $white;
	z-index: 11;
	border-bottom: 1px solid $uy-bg;
	width: 100%;

	.header-container{
		@include flex(row, flex-start);
		gap: 0.5rem;
		.heading {
			font-weight: 500;
			font-size: 1.2rem
		}
	}

	.btn-group {
		@include flex(row, flex-end);
		gap: 1rem;
		width: 150px;
	}
}
