@import '../../../Styles/';

.root {
	width: 100%;
	@include flex(column, flex-start, flex-start);
	gap: 0.5rem;
	border: 1px solid #f1f1f1;
	.vendor_name_container {
		width: 100%;
		padding: 0.5rem;
		background: #f1f1f1;
	}
	.field {
		@include flex(column);
		gap: 0.5rem;
		.vendorDetails{
			display: inline-flex;
			flex-direction: column;
			gap: .1rem;
			.heading{
				font-size: x-small;
			}
			.item{
				@include flex(row, space-between);
				gap: .5rem;
				font-size: x-small;
				// border: 1px solid rgba(0, 0, 0, 0.247);
				padding: .25rem;
				background-color: #eaeaea;
				border-radius: 1rem;
				width: 125px;
				cursor: pointer;
				transition: .5s;
				&:hover{
					transform: scale(1.1);
					border: 2px solid $uy-bg;
				}
				span{
					@include flex;
					font-size: larger;
					width: 1rem;
					height: 1rem;
					background-color: white;
					border-radius: 50%;
				}
			}	
				
		}
		// min-width: 150px;
		// height: 100px;
		// background: red;
		padding: 0;
		.button{
			font-size: .75rem;
			padding: 0px;
			box-shadow: 1px 1px 1px rgb(126, 126, 126);
			width: 100px;
			transition: .5s;
			&:hover{
				transform: scale(1.05);
			}
			&.payment{
				font-size: .85rem;
				padding: .25rem 5rem;
				width: 100%;
				background: rgba($uy-bg, .5);
				font-weight: 500;
			}
			&.credit{
				font-size: .85rem;
				padding: .25rem 5rem;
				width: 100%;
				background: rgba(100,250,100,.3);
				font-weight: 500;
			}
		}
		.value {
			font-size: 1.75rem;
		}
		button {
			width: 150px;
			padding: 0.25rem 0;
			border-radius: 8px;
            @include flex;
            gap: .5rem;
		}
	}
}
