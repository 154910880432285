@import '../../../Styles/';

.advance {
	width: 100%;
	padding: 0.5rem;
	background: $uy-ex-light-bg;
	margin-bottom: 1rem;
	font-size: 1.5rem;
	border-radius: 0.5rem;
}

.root {
	@include flex(row, flex-start, flex-start);
	gap: 0.25rem;
	width: 100%;
	height: 100%;

	.lhs {
		background: $uy-ex-light-bg;
		border-radius: 12px;
		padding: 0.5rem;
		height: 100%;
		// flex: 1;
		min-width: 40%;

		.iframe {
			background: $uy-ex-light-bg;
			border-radius: 12px;
			min-height: 65vh;
		}

		.po-details {
			@include flex(column);
			gap: 0.5rem;
		}
	}
	.rhs {
		width: 100%;
		height: 100%;
		overflow: auto;
		// flex: 2;
		@include flex(column, flex-start, flex-start);
		gap: 1rem;
		.txn_header {
			width: 100%;
		}
		.items {
			width: 100%;
			.list {
				width: 100%;
				min-height: 300px;
				overflow: auto;
			}
		}
		.total-container {
			width: 60%;
			place-self: end;
			@include flex(column, flex-start);
			padding: 0.75rem;
			border-radius: 0.75rem;
			background: $uy-ex-light-bg;
		}
	}
	.footer {
		background: green;
	}
	.multi {
		width: 100%;
		@include flex(row, space-between);
	}
	.tile {
		width: 100%;
		@include flex(row, flex-start, flex-start);
		&.orderId {
			padding: 0.5rem;
			background: $uy-bg;
			font-size: 1.5rem;
			border-radius: 12px;
		}
		p {
			margin: 0;
			text-align: start;
			font-weight: 500;
			width: 40%;
		}
		label {
			text-align: start;
			width: 175px;
		}
		.status {
			width: fit-content;
			padding: 4px 8px;
			border-radius: 25px;

			&.PENDING {
				background: #ffe601;
			}
			&.FULFILLED {
				background: #01ff01;
			}
			&.CLOSED {
				background: #01ff01;
			}
			&.CANCELLED {
				background: red;
				color: white;
			}
			&.ABANDONED {
				background: rgb(194, 0, 0);
				color: white;
			}
		}
	}

	.total_tile {
		width: 100%;
		@include flex(row, space-between, flex-start);
		p {
			font-weight: 500;
		}
		label {
			text-align: left;
			width: 200px;
		}
	}

	.multi-tax {
		@include flex(column, flex-start);
		gap: 0.5rem;
		// background: red;
		label {
			width: 150px;
		}
	}
}

.btn-container {
	width: 100%;
	@include flex(column);
	gap: 0.5rem;
	.terms-conditions {
		@include flex;
		gap: 0.5rem;
		.checkbox {
			width: 1.5rem;
			height: 1.5rem;
		}
		label {
			font-size: 1.25rem;
		}
	}
	.btns {
		width: 100%;
		@include flex;
		gap: 0.5rem;
	}
}
