@import '../../Styles/index';

.result {
	@include flex(column, flex-start, flex-start);
	width: 100%;
	background: #DEF5E5;
	border-radius: 12px;
	box-shadow: -1px 0px 15px 5px rgba(241,241,241,1);

	.feature-count{
		background-color: #f28f3b;
		padding: .2rem;
		margin-right: .5rem;
		border-radius: 4px;
	}

	.result-header {
		background: #0F3D3E;
		color: $white;
		padding: 1rem;
		font-size: 1.25rem;
		width: 100%;
		border-radius: 12px 12px 0 0;

		.count {
			font-size: 0.8em;
		}
	}

	.image-grid {
		@include grid(7);
		padding: 1rem;
		justify-items: center;
		gap: 1rem;
		width: 100%;

		.load-more {
			@include flex;
			font-size: 1.25rem;
			width: 10rem;
			height: 10rem;
			border: none;
			border-radius: 4px;
			background: rgba(15, 61, 62, 0.3);
			color: #fff;
			cursor: pointer;
			transition: all 0.4s;

			&:hover {
				@include boxShadow;
				background: $dark-grey;
				color: $white;
			}

			&:disabled {
				cursor: not-allowed;
				opacity: 0.5;
			}
		}
	}
}
