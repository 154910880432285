@import '../../Styles/';

.root {
	width: 100%;
	.downloadData {
		width: 125px;
		text-align: center;
		// margin-left: auto;
		cursor: pointer;
		@include flex;
		gap: 0.25rem;
		z-index: 10;
		background-color: #1d6f42;
		color: white;
		border-radius: 1rem;
		padding: 0.25rem 0.5rem;
		img {
			width: 25px;
		}
	}
	.header {
		@include flex(row, space-between);
		background: rgba($uy-ex-light-bg, 0.1);
		border: 2px solid $uy-bg;
		border-radius: 4px;
		padding: 0.5rem;
		margin: 0.5rem;
		.header_search{
			@include flex(column, flex-start, flex-start);
			gap: .5rem;
			.header-title {
				font-weight: 500;
			}
			.filters{
				@include flex(row);
				gap: .25rem;
			}
		}
		.add-btn {
			@include flex;
			gap: 0.25rem;
			padding: 0.25rem 0.5rem;
			background: $uy-bg;
			font-weight: 500;
			border-radius: 20px;
			cursor: pointer;
			&:hover {
				background: rgba($uy-bg, 0.4);
			}
		}
	}

	.vendor_header {
		@include flex(row, space-between);
		margin-right: 1rem;
		.vendor_details{
			@include flex(row, flex-start, flex-start);
			border: 1px solid #d1d1d1;
			// border-bottom: ;
			padding: .25rem;
			border-radius: 4px;
			.name_company{
				width: 250px;
				@include flex(column, flex-start, flex-start);
				border-right: 1px solid #d1d1d1;
				.vendor_name {
					font-weight: 500;
					font-size: 1.25rem;
				}
				.expense_allowed{
					font-size: 0.8rem;
					font-weight: 400;
					padding: .25rem .5rem;
					border-radius: 25px;
					border: 1px solid #f0cc72;
				}
			}
			.details{
				width: 250px;
				padding-left: .5rem;
				.field{
					font-size: .95rem;
					label{
						font-weight: 400;
						color: #050505ac;
					}
				}
			}
		}
		.btns_container{
			@include flex;
			gap: .5rem;
		}
	}
	.accordion_body {
		width: 100%;
		@include flex(column, flex-start, flex-start);
		gap: 0.5rem;
	}
	.field {
		@include flex(row, flex-start, center);
		gap: 0.15rem;
		label {
			min-width: 125px;
		}
		p {
			font-weight: 500;
		}
	}
	.locations {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		width: 100%;
		gap: 0.5rem;
		.location {
			width: 100%;
			min-height: 100px;
			background: white;
			border: 1px solid rgba(0, 0, 0, 0.25);
			border-radius: 8px;
			padding: 0.5rem;
			@include flex(column, flex-start, flex-start);
			gap: 0.5rem;
			.location_header {
				width: 100%;
				@include flex(row, space-between);
				p {
					margin: 0;
				}
				.location_name {
					font-weight: 500;
				}
			}
			.address {
				// margin-top: 0.25rem;
				border-top: 1px solid #f1f1f1;
				@include flex(column, flex-start, flex-start);
				gap: 0.25rem;
				font: 0.9rem;
				.address_type {
					font-weight: 500;
				}
			}
		}
	}
}
