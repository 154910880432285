.root {

    .table {
        height: 70vh;
        overflow: auto;
    }


    .header {
        width: 100%;
        height: 20vh;
        display: flex;
        justify-content: space-between;

        .typeFilters {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            .heading {
                font-size: xx-large;
                font-weight: 600;
            }

            .buttonFilters {
                padding: 1rem 5rem;
                display: flex;
                width: 100%;
                gap: .3rem;
                flex-wrap: wrap;

                div {
                    border-radius: .5rem;
                    cursor: pointer;
                    padding: .1rem .3rem;
                    background-color: rgb(213, 213, 213);
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.474);
                    &:hover {
                        box-shadow: 0 0 1px rgba(0, 0, 0, 0.7);
                    }

                }

                .selected {
                    background-color: rgb(112, 181, 193);

                }
            }
        }

        .filter {
            width: 50%;
            height: 10rem;
            display: flex;
            align-items: center;

        }
    }

    .clickableVersions {

        .version {
            display: flex;
            width: 40%;
            justify-items: center;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            span {
                border-radius: .5rem;
                font-weight: 600;
                background-color: rgba(223, 223, 223, 0.437);
                padding: .1rem 1rem;
            }
        }
    }


}