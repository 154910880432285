@import '../../Styles/';

.dot-container {
	@include flex;
	background: $white;
	width: 30px;
	height: 30px;
	border: 1px solid $mid-grey;
	border-radius: 4px;
	position: relative;

	&.small {
		cursor: default;
		width: 20px;
		height: 20px;

		.dot {
			width: 10px;
			height: 10px;
		}
	}

	.dot {
		width: 15px;
		height: 15px;
		border-radius: 50px;
	}

	&.veg {
		border: 1px solid $success;
		.dot {
			background: $success;
		}
	}

	&.non-veg {
		border: 1px solid $pastel-red;
		.dot {
			background: $pastel-red;
		}
	}
}
