@import '../../../Styles/index';

.root {
	@include flex(column, flex-start);
	padding: 1rem;
	position: relative;

	@include md {
		@include flex(row, space-between);
		height: calc(100vh - 60px - 80px - 2rem);
	}
	@include landscape {
		@include flex(row, space-between);
		height: calc(100vh - 60px - 80px - 2rem);
	}

	.overlay {
		display: none;
		&.show {
			@include flex;
		}
		z-index: 11;
		position: absolute;
		inset: 0;
		background: rgba($black, 0.8);
		font-size: 2rem;
		color: $white;
	}

	.image-container {
		@include flex;
		position: relative;
		height: 100%;
		border: 1px solid $mid-grey;
		border-radius: 4px;
	}

	.labels-wrapper {
		@include flex(column, flex-start, stretch);
		gap: 1rem;
		height: 100%;
		padding: 1rem;

		.heading,
		.sub-heading {
			width: 100%;
			text-align: center;
		}

		.heading {
			font-size: 1.25rem;
		}

		.fallback {
			text-align: center;
			margin-bottom: 20rem;
			color: $mid-grey;
		}

		.black {
			color: $black;
		}

		.footer {
			@include flex;
		}

		.labels {
			@include flex(column, flex-start, center);
			@include customScrollBar;
			overflow: auto;
			height: 100%;
			.label-container {
				@include flex(row, space-around);
				gap: 1rem;
				max-width: 360px;
				border-radius: 4px;
				width: 100%;
				padding: 0.5rem 1rem;

				.key-color {
					width: 2rem;
					height: 2rem;
					border-radius: 4px;
				}

				.label-name {
					@include flex(row, flex-start, stretch);
					width: 70%;
					max-width: 120px;
					gap: 1rem;

					.defect {
						font-size: 0.8em;
						color: $red;

						&:hover {
							cursor: pointer;
							text-decoration: underline;
						}
					}

					.identification-type{
						padding: 0.2rem 0.6rem;
						border-radius: 8px;
						font-weight: 500;
						text-transform: uppercase;
						font-size: 0.8rem;
						height: 100%;
					}

					.AI{
						background-color: #1C6758;
					}
					
					.MN{
						background-color: #f28f3b;
					}
				}

				.label-actions {
					@include flex;
				}
			}
		}
	}

	.image-container,
	.labels-wrapper {
		width: 100%;
	}

	.image-container {
		min-height: 400px;
		@include md {
			min-height: auto;
			width: 65%;
		}
		@include landscape {
			min-height: auto;
			width: 65%;
		}
	}

	.labels-wrapper {
		max-height: 400px;
		@include md {
			max-height: auto;
			width: 45%;
		}
		@include landscape {
			max-height: auto;
			width: 45%;
		}
	}
}
