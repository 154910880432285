@import '../.././../Styles/';

.added-item {
    width: 100%;
    @include flex(row, flex-start, flex-start);
    padding: 0.5rem;
    gap: 0.5rem;

    img {
        width: 50px;
        height: 50px;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }

    .head-variants {
        width: 100%;
        @include flex(column, flex-start, flex-start);
        gap: .25rem;
        .item_desc{
            width: 100%;
            border-radius: 8px;
            outline: none;
            border: 1px solid #f1f1f1;
            padding: .25rem .5rem;
            resize: none;
        }
    }
}

.custom-input {
    width: 100%;
    border-radius: 8px;
    outline: none;
    padding: 0.25rem 0.5rem;
    border: none;
    &:disabled{
        background: #d1d1d1;
    }

    &:hover,
    &:focus {
        border: 2px solid #ffe62c;
    }
}

.table-tr {
    position: relative;

    td {
        // border: 1px solid #f1f1f1;
        .disabled-value{
            background: #b8b8b8;
            padding: 0.25rem;
            border-radius: 12px;
        }
        .editable-input {
            margin-bottom: 0.25rem;

        }
        .input_unit{
            width: 100%;
            @include flex(column);
            gap: 0.25rem;
            .unit_options{
                width: 100%;
                @include flex();
                gap: 0.5rem;
                .unit{
                    flex: 1;
                    padding: 0.25rem 0.5rem;
                    border-radius: 6px;
                    background: #f1f1f1;
                    text-align: center;
                    cursor: pointer;
                    font-size: .8rem;
                    &.selected{
                        background: $uy-bg;
                    }
                }
            }
        }

        .quantitiesContainer {
            display: flex;
            flex-direction: column;
            gap: .1rem;
            align-self: start;


            .quantities {

                padding: .1rem .2rem;
                gap: .1rem;
                width: 100%;
                text-align: center;
                bottom: -0.8rem;
                border-radius: 2rem;
                left: 0px;
                white-space: nowrap;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title {
                    font-size: small;
                    font-size: .8rem;
                }

                .value {
                    font-size: .9rem;
                    font-weight: 600;
                }
            }
        }
    }

    .remove-container {
        position: absolute;
        right: -2rem;
        top: 0;
        bottom: 0;
        cursor: pointer;
        margin: auto 0;

        .icon {
            color: red;
        }
    }
}