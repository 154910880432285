@import '../../Styles/';
.root {

		// height: calc(100vh - 4rem);
		height: 100%;
		// overflow-y: auto;
		cursor: pointer;
		position: relative;
		flex: 1;
		z-index: 12;


	// height: calc(100vh - 4rem);
	// overflow-y: auto;
	.critical {
		background: rgba($pastel-red, 0.5);
		&.text {
			color: rgba($bright-red, 1);
		}
	}

	.moderate {
		background: rgba($dark-yellow, 0.5);
		&.text {
			color: rgba($dark-yellow, 1);
		}
	}

	.normal {}

	.skipped {
		background: rgba($grey, 0.5);
		&.text {
			color: rgba($grey, 1);
		}
	}

	.text {
		@include flex(row, flex-start);
		background: none;
		width: 100%;
		text-align: left;
		gap: 0.5rem;
	}

	.brand-column {
		@include flex(column, flex-start, left);
		background: none;
		width: 100%;
		text-align: left;
		gap: 0.5rem;
	}

	.no-wrap {
		white-space: nowrap;
		display: flex;
		align-items: center;
	}
	.logo {
		max-width: 3rem;
		max-height: 3rem;
	}
}
