@import '../../../Styles/index';

.no-grn{
    @include flex;
    width: 100%;
    height: 100%;
}

.accordion-details{
    @include flex(column, flex-start, flex-start);
    .batch-list{
        width: 100%;
        @include flex(row, flex-start, flex-start);
        flex-wrap: wrap;
        gap: 1rem;
    }
}

.prod-img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    
}

.header-container{
    width: 70%;
    @include flex(column, flex-start, flex-start);
    .download-btns{
        @include flex;
        gap: .5rem;
    }

    .header-bill-btn{
        @include flex;
        gap: 0.5rem;
        margin-bottom: .5rem;
    }
}